import React, { useContext, useState } from "react";
import ButtonComponent from "../../Common/ButtonComponent";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Tag,
  Tooltip,
  Skeleton,
  notification,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  DownloadOutlined,
  DeleteTwoTone,
  RetweetOutlined,
  EditOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import noteContext from "../../Context/StyleContext";
import SalesService, {
  DBRecordStatus,
  EstimateStatus,
} from "../../Services/SalesService";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import ViewEstimateModel from "./ViewEstimateModel";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import { FiDownload } from "react-icons/fi";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import VoucherServices, { Voucher_Type } from "../../Services/VoucherServices";
import QuotationServices from "../../Services/QuotationServices";
export interface QuotationDataType {
  key: React.Key;
  id: string;
  tPartner: any;
  number: any;
  name: string;
  date: string;
  duedate: any;
  suppliedQty: number;
  totalQty: number;
  totalInvoiceAmount: number;
  dueBalance: number;
  deleteType: number;
}

interface IQuotationProps {
  estimateList: any;
  setEstimateList: (value: any) => void;
  partyId?: string;
}

const QuotationTable = (props: IQuotationProps) => {
  const { RangePicker } = DatePicker;
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const navigate = useNavigate();
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>();

  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [estimateStatus, setEstimateStatus] = React.useState<number>(4);
  const [deleteEstimate, setDeleteEstimate] = React.useState<any>({
    status: DBRecordStatus.Active,
    open: false,
    name: "",
  });
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React?.useState(false);

  const [estimateId, setEstimateId] = React.useState<string>("");
  const [confirmationModel, setConfirmationModel] =
    React.useState<boolean>(false);
  const [messageShow, setMessageShow] = React.useState<boolean>(true);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const [invoiceListData, setInvoiceListData] = React.useState<any[]>([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteData = async (id: string) => {
    let res = await QuotationServices?.deleteData(currentClient?.id, id);
    if (res?.result) {
      setRefresh((x: boolean) => !x);
      notification.success({
        message: "",
        description: "Deleted successfully.",
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "",
        description: res?.message || "An error occurred while Delete.",
        placement: "topRight",
      });
    }
  };

  //-------- Estimate Delivery Challan --------
  // React.useEffect(() => {
  //   if (deleteEstimate.open) {
  //     SalesService.deleteEstimate(
  //       currentClient?.id!,
  //       estimateId,
  //       deleteEstimate.status
  //     )
  //       .then((res: any) => {
  //         if (res.result) {
  //           setTrigger((x) => !x);
  //           console.log(res.result);
  //           openNotification(
  //             "success",
  //             `${
  //               deleteEstimate.status === 1 ? "Delete" : "Active"
  //             } Delivery Challan`
  //           );
  //           setDeleteEstimate({});
  //         }
  //       })
  //       .catch((ex: any) => {
  //         setDeleteEstimate({});
  //         console.error("Delete Api Error", ex);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deleteEstimate.open]);

  // --------- Table Columns  ---------
  const columns: ColumnsType<QuotationDataType> = [
    {
      title: "Quotation No.",
      dataIndex: "number",
      width: width <= 1440 ? "10%" : "9%",
      render: (_, record: QuotationDataType) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              setIsOpen(true);
              setInvoiceId(record?.id);
            }}
          >
            {record?.number}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: true,
      key: "Date",
      ellipsis: true,
      width: "8%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Total Qty",
      dataIndex: "totalQty",
      width: "10%",
      key: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.totalQty}
        </Skeleton>
      ),
    },
    {
      title: "Supplied Qty",
      dataIndex: "suppliedQty",
      width: "10%",
      key: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.suppliedQty}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "totalEstimateAmount",
      sorter: true,
      align: "right",
      key: "totalEstimateAmount",
      ellipsis: {
        showTitle: false,
      },
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          ₹ {Utils.getFormattedNumber(record?.invAmount)}
        </Skeleton>
      ),
    },
    {
      title: "Status",
      dataIndex: "address",
      // width: "5%",
      align: "center",
      render: (_, record) => (
        <div>
          {record?.id ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="success"
                style={{
                  textAlign: "center",
                  width: 65,
                }}
              >
                Accepted
              </Tag>
            </Skeleton>
          ) : record?.date >= record?.duedate ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="error"
                style={{
                  textAlign: "center",
                  width: 67,
                }}
              >
                Rejected
              </Tag>
            </Skeleton>
          ) : (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="error"
                style={{
                  textAlign: "center",
                  width: 67,
                }}
              >
                Pending
              </Tag>
            </Skeleton>
          )}
        </div>
      ),
    },
    {
      title: "   ",
      dataIndex: "icon",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div className="cursor">
            <FiDownload style={{ color: "#395cd2" }} />
          </div>
        </Skeleton>
      ),
    },
    {
      title: "  ",
      dataIndex: "icon3",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <div className="cursor">
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            {/* {record?.id || record?.deleteType === 1 ? (
              <Tooltip title="Create Sales Order" placement="top" color="gray">
                <FileTextOutlined style={{ color: "rgb(216 207 207)" }} />
              </Tooltip>
            ) : ( */}
            <Tooltip title="Sales Order" placement="top" color="#64CA31">
              <Link
                to={`/accounts/clients/${currentClient?.id}/sales/addSalesOrder/quotation/${record?.id}`}
              >
                <ShoppingCartOutlined
                  // className="ca-invoice-btn"
                  onClick={() => {
                    navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addSalesOrder/quotation/${record?.id}`
                    );
                  }}
                  style={{ color: "##7B97A8" }}
                />
              </Link>
            </Tooltip>
            {/* )} */}
          </Skeleton>
        </div>
      ),
    },
    {
      title: "   ",
      dataIndex: "icon2",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {/* {record?.invoices?.id || record?.deleteType === 1 ? (
            <Tooltip title="Not Editable" placement="top" color="gray">
              <EditOutlined
                // className="ca-edit-btn"
                style={{ color: "rgb(216 207 207)" }}
              />
            </Tooltip>
          ) : ( */}
          <Tooltip title="Edit" placement="top" color="#3D4998">
            <Link
              to={`/accounts/clients/${currentClient?.id}/sales/editQuotation/${record?.id}`}
            >
              <EditOutlined
                className="ca-edit-btn"
                onClick={() =>
                  navigate(
                    `/accounts/clients/${currentClient?.id}/sales/editQuotation/${record?.id}`
                  )
                }
                style={{ color: "#444653" }}
              />
            </Link>
          </Tooltip>
          {/* )} */}
        </Skeleton>
      ),
    },
    // {
    //   title: "  ",
    //   dataIndex: "icon3",
    //   width: "3%",
    //   align: "center",
    //   render: (_, record) => (
    //     <div className="cursor">
    //       <Skeleton
    //         active
    //         loading={loading}
    //         paragraph={{ rows: 1 }}
    //         title={false}
    //       >
    //         <Tooltip title="Create Invoice" placement="top" color="#64CA31">
    //           <Link
    //             to={`/accounts/clients/${currentClient?.id}/sales/addInvoice/quotation/${record?.id}`}
    //           >
    //             <FileTextOutlined
    //               className="ca-invoice-btn"
    //               onClick={() => {
    //                 navigate(
    //                   `/accounts/clients/${currentClient?.id}/sales/addInvoice/quotation/${record?.id}`
    //                 );
    //               }}
    //               style={{ color: "#64CA31" }}
    //             />
    //           </Link>
    //         </Tooltip>
    //       </Skeleton>
    //     </div>
    //   ),
    // },
    {
      title: "  ",
      dataIndex: "icon3",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {/* {!record?.invoices?.id && record?.deleteType === 0 && ( */}
          <Tooltip title="Delete" placement="top" color="red">
            <DeleteTwoTone
              className="ca-delete-btn"
              twoToneColor="#ff879d"
              onClick={() => {
                setConfirmationModel(true);
                setEstimateId(record?.id);
                setDeleteEstimate({
                  ...deleteEstimate,
                  status: DBRecordStatus.Deleted,
                  name: record?.number,
                });
              }}
            />
          </Tooltip>
          {/* )} */}
          {!record?.id && record?.deleteType === 1 && (
            <Tooltip
              title="Revive Delivery Challan"
              placement="top"
              color="green"
            >
              <RetweetOutlined
                className="ca-invoice-btn"
                style={{ color: "#64CA31" }}
                onClick={() => {
                  setConfirmationModel(true);
                  setEstimateId(record?.id);
                  setDeleteEstimate({
                    ...deleteEstimate,
                    status: DBRecordStatus.Active,
                    name: record?.number?.no,
                  });
                }}
              />
            </Tooltip>
          )}
        </Skeleton>
      ),
    },
  ];

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Party Name",
      dataIndex: "customerName",
      width: "25%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.customerName?.name}
        </Skeleton>
      ),
    });
  }

  // --------- Get TableData API ---------
  React.useEffect(() => {
    if (currentClient?.id) {
      setLoading(true);
      getEstimateList(
        currentClient?.id,
        Voucher_Type.Quotation,
        (page - 1) * pageSize,
        pageSize,
        searchCustomer,
        filterDate.from,
        filterDate.to,
        filter.sortCol,
        filter.sortDir
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    filterDate.from,
    filterDate.to,
    searchCustomer,
    refresh,
  ]);

  const getEstimateList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    start: number,
    length: number,
    search?: string,
    fromDate: string = "",
    toDate: string = "",
    status?: string,
    sortCol?: string,
    sortDir?: string
  ) => {
    setLoading(true);
    await QuotationServices.getQuotationList(
      clientId,
      Voucher_Type,
      start,
      length,
      search,
      fromDate,
      toDate,
      status,
      sortCol,
      sortDir
    )
      .then((res: any) => {
        if (res?.result?.voucherItems?.length > 0) {
          console.log("res.items = ", res?.result?.voucherItems);
          // table list data
          setInvoiceListData(
            res?.result?.voucherItems?.map((v: any, index: number) => {
              // console.log("This is my data", v);
              return {
                key: index,
                id: v?.vId,
                date: dayjs(v?.date).format("DD/MM/YYYY"),
                number: v?.number,
                customerName: {
                  id: v?.pLedger[0].id,
                  name: v?.pLedger[0]?.ledgerName,
                },
                totalQty: v?.totalQty,
                suppliedQty: v?.suppliedQty,
                invAmount: v?.invAmount,
                refundAmount: v?.refundAmount,
                receivedAmount: v?.receivedAmount,
                dueBalance: v?.dueAmount,
              };
            })
          );
          //-----For Quotation/Estimate Graphs-----//
          props.setEstimateList({
            totalRejected: res?.totalRejected,
            totalAccepted: res?.total?.totalAccepted,
            totalPendingAmount: res?.result?.totalPendingAmount,
            totalEstimate: res?.total?.totalEstimate,
            //----For TableSubTotal----///

            totalAmount: res?.result?.totalAmount,
          });
          setTotalRecords(res?.totalRecords);
        } else if (res?.result?.voucherItems?.length === 0) {
          props.setEstimateList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        props.setEstimateList([]);
      });
  };

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", filter, filter.column);
  };

  //----- Short-Cut for Quotation -----
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    navigate(`/accounts/clients/${currentClient?.id}/sales/addQuotation`);
  });

  //----- Short-Cut for Open Quotation TimeCondition -----
  setTimeout(() => {
    setMessageShow(false);
    // console.log("timeout");
  }, 6000);

  // Download/CSV
  const exportCsv = () => {
    QuotationServices.exportCSVQuotation(
      currentClient?.id,
      Voucher_Type.Quotation,
      (page - 1) * pageSize,
      pageSize,
      filterDate.from,
      filterDate.to,
      filter.sortCol,
      filter.sortDir,
      undefined,
      undefined,
      searchCustomer
      // invoiceStatus
    )
      .then((res: any) => {
        if (res) {
          console.log("Export sucess");
        }
      })
      .catch((ex: any) => {
        openNotification("error", ex.message);
      });
  };

  return (
    <div>
      <Row
        // justify={windowWidth <= 768 ? "center" : "space-between"}
        justify={width <= 768 ? "space-evenly" : "space-between"}
        gutter={windowWidth <= 1024 ? 65 : 0}
        style={{
          // paddingTop: props?.partyId ? "10px" : "0px",
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col xl={6} lg={4}>
          <Space size={5}>
            <Button
              type="primary"
              id="gfg"
              icon={<PlusOutlined />}
              onClick={() =>
                props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addQuotation/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addQuotation`
                    )
              }
            >
              Quotation
            </Button>
            {messageShow && (
              <>
                <Col style={{ paddingLeft: "10px" }}>
                  <Tag
                    style={{
                      // color: "#ced4d9",
                      fontWeight: 500,
                      borderColor: "#64646433",
                      backgroundColor: "#9696960f",
                    }}
                  >
                    Shift+N
                  </Tag>
                </Col>
              </>
            )}
            <Button
              type="default"
              icon={<DownloadOutlined />}
              onClick={() => exportCsv()}
            >
              Export
            </Button>
          </Space>
        </Col>

        <Col
          xl={18}
          lg={20}
          style={{ marginTop: width <= 768 ? "6px" : "0px" }}
        >
          <Row justify="end" gutter={8}>
            <Col>
              <Input
                // style={{ width: "220px" }}
                style={{ border: "1px solid #f1f3ff", width: "200px" }}
                placeholder="Search Ledger & No."
                allowClear
                onChange={(e: any) => {
                  setLoading(true);
                  if (!e.cancelable) {
                    const data = setTimeout(() => {
                      // console.log("customer search.......................");
                      setPage(1);
                      setSearchCustomer(e.target.value);
                    }, 1000);
                    return () => clearTimeout(data);
                  } else {
                    setSearchCustomer(e.target.value);
                  }
                }}
                suffix={
                  loading || searchCustomer ? (
                    ""
                  ) : (
                    <img alt="Search.." src={search} />
                  )
                }
              />
            </Col>
            <Col>
              <RangePicker
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                format="DD/MM/YYYY"
                // style={{ width: "220px" }}
                style={{ border: "1px solid #f1f3ff", width: "220px" }}
                onChange={(e: any) => {
                  console.log(e);
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            {/* <Col>
              <Select
                style={{
                  width: width === 1024 ? 80 : 120,
                }}
                value={estimateStatus}
                onSelect={(e) => {
                  setPage(1);
                  setEstimateStatus(e);
                }}
                options={[
                  { value: EstimateStatus.All, label: "All" },
                  { value: EstimateStatus.Active, label: "Active" },
                  { value: EstimateStatus.Pending, label: "Pending" },
                  { value: EstimateStatus.Accpected, label: "Accpected" },
                  { value: EstimateStatus.Deleted, label: "Deleted" },
                ]}
              />
            </Col> */}
            <Col>
              <ButtonComponent />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        summary={() => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row
                style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
              >
                <Table.Summary.Cell
                  index={1}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="fw600">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={4}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} className="fw600" align="right">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="fw600" align="right">
                  ₹ {Utils.getFormattedNumber(props.estimateList?.totalAmount)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} className="fw600" align="right">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        rowSelection={rowSelection}
        columns={columns}
        scroll={pageSize > 10 ? { x: 700, y: 370 } : { x: 600 }}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as any)
            : invoiceListData
        }
        onChange={tableSort}
        // style={{ overflowX: "auto" }}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />
      {open && (
        <ViewEstimateModel
          open={open}
          onCancel={() => setIsOpen(false)}
          estimateId={invoiceId}
          voucharType={Voucher_Type.Quotation}
        />
      )}
      {confirmationModel && (
        <ConfirmationModal
          open={confirmationModel}
          text={`Do you really want to ${
            deleteEstimate.status === 1 ? "Delete" : "Active"
          }  this Estimate "${deleteEstimate.name}" ?`}
          onNo={() => setConfirmationModel(false)}
          onYes={() => {
            setConfirmationModel(false);
            deleteData(estimateId);
          }}
        />
      )}
    </div>
  );
};
export default QuotationTable;
