import React from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Card,
  Skeleton,
  Tooltip,
  Tag,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  DeleteTwoTone,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import { dayjs } from "../../../Utilities/dayjs";
import ViewPaymentIn from "./ViewPaymentIn";
import { Utils } from "../../../Utilities/Utils";
import ViewSalesInvoice from "./ViewSalesInvoice";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import VoucherServices, {
  VoucherFilterStatus,
  Voucher_Type,
} from "../../Services/VoucherServices";
import { useLocation } from "react-router-dom";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
interface DataType {
  key: string;
  id: string;
  // number: { name: string; id: string };
  number: string;
  name: number;
  date: string;
  account: string;
  receivedAmount: number;
  partyNewAmount: number;
  invoices: any[];
  supplierName: string;
  voucherStatus: number;
}

const PaymentIn: React.FC<{ partyId?: string }> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const { currentClient, companySettings } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [paymentId, setPaymentId] = React.useState<string>("");
  const [salesInvoiceId, setSalesInvoiceId] = React.useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [openPopArray, setOpenPopArray] = React.useState<boolean[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [receiptList, setReceiptList] = React.useState([]);
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [totalRecAmount, setTotalRecAmount] = React.useState<number>(0);
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [page, setPage] = React.useState(1);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [paymentInStatus, setPaymentInStatus] =
    React.useState<VoucherFilterStatus>(VoucherFilterStatus.Active);
  const [messageShow, setMessageShow] = React.useState<boolean>(true);
  const [openSalesInvoice, setOpenSalesInvoice] =
    React.useState<boolean>(false);
  const [openInvoiceNoPopArray, setOpenInvoiceNoPopArray] = React.useState<
    boolean[]
  >([]);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [deletePaymentIn, setDeletePaymentIn] = React.useState<{
    id: string;
    isRev: boolean;
    number: string;
  }>({
    id: "",
    isRev: false,
    number: "",
  });

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // console.log("location", location.pathname.split("/"));

  let splitPathInVchType: Voucher_Type;
  if (location.pathname.split("/")[5] === "receipts") {
    splitPathInVchType = Voucher_Type.Receipt;
  } else {
    splitPathInVchType = Voucher_Type.Payment;
  }

  // --------- Table Columns  ---------
  const columns: ColumnsType<DataType> = [
    {
      title: "Voucher No.",
      dataIndex: "number",
      key: "Number",
      sorter: true,
      width: width <= 1024 ? "13%" : "9%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>
              <span
                style={{ color: "#1677FF", cursor: "pointer" }}
                onClick={() => {
                  setIsOpen(true);
                  setPaymentId(record?.id);
                }}
              >
                {record?.number}
              </span>
            </>
          ) : (
            <>
              <span style={{ color: "red" }}>{record?.number}</span>
            </>
          )}
        </Skeleton>
      ),
    },
    // {
    //   title: "Invoices",
    //   dataIndex: "invoices",
    //   width: width <= 1440 ? "15%" : "10%",
    //   render: (_: any, record: any, index: number) => (
    //     <>
    //       <Row justify={"start"} align={"middle"} className="p-0">
    //         <Skeleton
    //           active
    //           loading={loading}
    //           paragraph={{ rows: 1 }}
    //           title={false}
    //         >
    //           <span
    //             style={{ color: "#1677FF", cursor: "pointer" }}
    //             onClick={() => {
    //               setOpenSalesInvoice(true);
    //               setSalesInvoiceId(record?.invoices[0]?.invoice?.id);
    //             }}
    //           >
    //             {record?.invoices?.length > 0 &&
    //               record?.invoices[0]?.invoice?.name}
    //           </span>
    //         </Skeleton>
    //         {record?.invoices?.length > 1 && (
    //           <Popover
    //             placement="rightTop"
    //             open={openInvoiceNoPopArray[index]}
    //             // arrow={{ pointAtCenter: true }}
    //             onOpenChange={(newOpen) =>
    //               invoicePopoverOnchange(index, newOpen)
    //             }
    //             // onOpenChange={(x: boolean) => console.log(x)}
    //             trigger="click"
    //             content={() => (
    //               <>
    //                 <h3 className="my-1">Invoices</h3>
    //                 <div
    //                   style={{
    //                     maxHeight: 300,
    //                     maxWidth: 200,
    //                     overflow: "auto",
    //                   }}
    //                 >
    //                   {record?.invoices?.map((v: any, index: any) => (
    //                     <>
    //                       {index > 0 && (
    //                         <div key={index}>
    //                           <Divider className="m-0" key={index} />
    //                           <div
    //                             style={{ cursor: "pointer", color: "#1677FF" }}
    //                             className="p-1"
    //                             key={index + 1}
    //                             onClick={() => {
    //                               setOpenSalesInvoice(true);
    //                               setSalesInvoiceId(v?.invoice?.id);
    //                               invoicePopoverOnchange(index, false);
    //                             }}
    //                           >
    //                             {v?.invoice?.name}
    //                           </div>
    //                         </div>
    //                       )}
    //                     </>
    //                   ))}
    //                 </div>
    //               </>
    //             )}
    //           >
    //             <div
    //               style={{
    //                 // color: "#1677FF",
    //                 cursor: "pointer",
    //                 paddingLeft: 10,
    //               }}
    //             >{`${record?.invoices?.length - 1} more`}</div>
    //           </Popover>
    //         )}
    //       </Row>
    //     </>
    //   ),
    // },
    {
      title: "Date",
      dataIndex: "date",
      sorter: true,
      key: "date",
      width: "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Bank / Cash Account",
      dataIndex: "account",
      width: `${props.partyId ? "23%" : "16%"}`,
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.account}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "partyNewAmount",
      key: "invAmount",
      sorter: true,
      width: "10%",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>₹ {Utils.getFormattedNumber(record?.partyNewAmount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.partyNewAmount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Adjusted Amt.",
      dataIndex: "receivedAmount",
      width: "10%",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <> ₹ {Utils.getFormattedNumber(record?.receivedAmount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.receivedAmount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Pending Amt.",
      dataIndex: "",
      width: "10%",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          ₹{" "}
          {record?.voucherStatus !== 1 ? (
            <>
              {Utils.getFormattedNumber(
                record?.partyNewAmount - record?.receivedAmount
              )}
            </>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {Utils.getFormattedNumber(
                  record?.partyNewAmount - record?.receivedAmount
                )}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      // width: "5%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div>
            {record?.voucherStatus !== 1 ? (
              <>
                <Row gutter={10} justify={"center"}>
                  <Col>
                    <Tooltip title="Edit" placement="top" color="#444653">
                      <EditOutlined
                        // className="ca-edit-btn"
                        style={{
                          color: "#444653",
                        }}
                        onClick={() => {
                          // console.log(record.id);
                          splitPathInVchType === Voucher_Type.Receipt
                            ? navigate(
                                `/accounts/clients/${currentClient?.id}/sales/editReceipt/${record?.id}`
                              )
                            : navigate(
                                `/accounts/clients/${currentClient?.id}/purchase/editPaymentOut/${record?.id}`
                              );
                          // setOpenModel({
                          //   open: true,
                          //   id: record?.id,
                          // });
                        }}
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip title="Delete" color="red">
                      {record?.voucherStatus !== 1 && (
                        <>
                          <DeleteTwoTone
                            className="ca-delete-btn"
                            twoToneColor="#ff879d"
                            onClick={() => {
                              setConfirmation(true);
                              setPaymentId(record?.id);
                              setDeletePaymentIn({
                                ...deletePaymentIn,
                                number: record?.number,
                              });
                              // handleOpenChange(index, false);
                            }}
                          />
                        </>
                      )}
                    </Tooltip>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Tooltip title="Revive Voucher">
                  <SyncOutlined
                    style={{ color: "green" }}
                    onClick={() => {
                      // console.log(record.id);
                      splitPathInVchType === Voucher_Type.Receipt
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/sales/editReceipt/${record?.id}`
                          )
                        : navigate(
                            `/accounts/clients/${currentClient?.id}/purchase/editPaymentOut/${record?.id}`
                          );
                      // setOpenModel({
                      //   open: true,
                      //   id: record?.id,
                      // });
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </Skeleton>
      ),
    },
  ];

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Account Name",
      dataIndex: "name",
      width: width <= 1024 ? "15%" : "25%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    });
  }

  const handleOpenChange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenPopArray(updatedOpenPopArray);
  };

  const invoicePopoverOnchange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openInvoiceNoPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenInvoiceNoPopArray(updatedOpenPopArray);
  };

  // --------- Get TableData API ---------

  React.useEffect(() => {
    // setLoading(true);
    if (currentClient?.id) {
      setLoading(true);
      getList(
        currentClient?.id,
        splitPathInVchType,
        (page - 1) * pageSize,
        pageSize,
        filterDate.from,
        filterDate.to,
        filter.sortCol,
        filter.sortDir,
        props?.partyId,
        undefined,
        searchCustomer,
        paymentInStatus
      );
    } else if (currentClient?.id === null) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    pageSize,
    filter,
    // filter,
    // filterDate,
    filterDate,
    paymentInStatus,
    currentClient?.id,
    searchCustomer,
    refreshAtDelete,
    // props?.partyId,
  ]);

  const getList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    start: number,
    length: number,
    fromDate?: string,
    toDate?: string,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    npReconciled?: any,
    search?: string,
    status?: VoucherFilterStatus
  ) => {
    await VoucherServices.getVoucharList(
      clientId,
      Voucher_Type,
      start,
      length,
      fromDate,
      toDate,
      sortCol,
      sortDir,
      partyId,
      npReconciled,
      search,
      status
    )
      .then((res: any) => {
        console.log("result", res);
        if (res.result?.voucherItems?.length > 0) {
          setReceiptList(
            res?.result?.voucherItems?.map((v: any) => {
              return {
                key: v?.sno,
                id: v?.vId,
                number: v?.number,
                name: v?.pLedger[0]?.ledgerName,
                date: dayjs(v?.date).format("DD/MM/YYYY"),
                account: v?.eLedger[0]?.ledgerName,
                receivedAmount: v?.receivedAmount,
                partyNewAmount: v?.invAmount,
                invoices: v?.item,
                voucherStatus: v?.voucherStatus,
              };
            })
          );
          setTotalRecords(res?.totalRecords);
          setTotalAmount(res?.result?.totalAmount);
          setTotalRecAmount(res?.result?.totalReceivedAmount);
        } else if (res.result?.voucherItems?.length === 0) {
          setReceiptList([]);
          setTotalRecords(res?.totalRecords);
        } else if (res?.message) {
          openNotification("error", res?.message);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error("error..", ex.message);
        openNotification("error", ex.message);
        setLoading(false);
        setReceiptList([]);
      });
  };

  console.log("receiptList", receiptList);

  // delete payment api
  React.useEffect(() => {
    console.log("deleteCreditNote.id", deletePaymentIn?.id);
    if (deletePaymentIn?.id) {
      const DeleteCreditNote = async () => {
        setLoading(true);
        await VoucherServices.deleteVoucher(
          currentClient?.id!,
          deletePaymentIn.id
        ).then((res) => {
          setLoading(false);
          if (res?.result) {
            setRefreshAtDelete(!refreshAtDelete);
            openNotification(
              "success",
              `Entry deleted successfully ${deletePaymentIn.number}`
            );
          }
        });
      };
      DeleteCreditNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePaymentIn?.id]);

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  //----- Short-Cut for Payment In -----
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    navigate(
      `/accounts/clients/${currentClient?.id}/${
        splitPathInVchType === Voucher_Type.Receipt
          ? "sales/addReceipt"
          : "purchase/addPaymentOut"
      }`
    );
  });
  // document.addEventListener("keydown", (e) => {
  //   e.preventDefault();
  //   if (e.altKey && e.key.toLowerCase() === "o") {
  //     navigate(
  //       `/accounts/clients/${currentClient?.id}/${
  //         splitPathInVchType === Voucher_Type.Receipt
  //           ? "sales/addPaymentIn"
  //           : "purchase/addPaymentOut"
  //       }`
  //     );
  //     // console.log("event", e);
  //   }
  // });
  //----- Short-Cut for Payment In  -----
  setTimeout(() => {
    setMessageShow(false);
    //console.log("timeout");
  }, 6000);

  const exportCsv = () => {
    VoucherServices.exportCsv(
      currentClient?.id,
      splitPathInVchType === Voucher_Type.Receipt
        ? Voucher_Type.Receipt
        : Voucher_Type.Payment,
      (page - 1) * pageSize,
      pageSize,
      filterDate.from,
      filterDate.to,
      filter.sortCol,
      filter.sortDir,
      undefined,
      undefined,
      searchCustomer
      // invoiceStatus
    )
      .then((res) => {
        if (res) {
          console.log("download sucess");
        }
      })
      .catch((ex: any) => {
        openNotification("error", ex.message);
      });
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <Row
        justify="space-between"
        style={{
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col>
          <Space size={width <= 768 ? 440 : 5}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/paymentIn/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/${
                        splitPathInVchType === Voucher_Type.Receipt
                          ? "sales/addReceipt"
                          : "purchase/addPaymentOut"
                      }`
                    );
              }}
            >
              {splitPathInVchType === Voucher_Type.Receipt
                ? "Receipt"
                : "Payment"}
            </Button>
            {messageShow && (
              <>
                <Col style={{ paddingLeft: "10px" }}>
                  <Tag
                    style={{
                      // color: "#ced4d9",
                      fontWeight: 500,
                      borderColor: "#64646433",
                      backgroundColor: "#9696960f",
                    }}
                  >
                    Shift+N
                  </Tag>
                </Col>
              </>
            )}
            <Button
              type="default"
              icon={<DownloadOutlined />}
              onClick={() => exportCsv()}
            >
              Export
            </Button>
          </Space>
        </Col>
        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          {/* gutter={width <= 768 ? 62 : 8} */}
          <Row gutter={width <= 768 ? 62 : 8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search Ledger"
                allowClear
                onChange={(e: any) => {
                  setPage(1);
                  setSearchCustomer(e.target.value);
                }}
                suffix={searchCustomer ? "" : <img alt="search" src={search} />}
              />
            </Col>
            <Col>
              <RangePicker
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  console.log(e);
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                clearIcon={true}
                defaultValue={VoucherFilterStatus.Active}
                style={{ width: 120 }}
                allowClear
                options={[
                  { value: VoucherFilterStatus.All, label: "All" },
                  { value: VoucherFilterStatus.Active, label: "Active" },
                  { value: VoucherFilterStatus.Deleted, label: "Deleted" },
                ]}
                onSelect={(val: VoucherFilterStatus) => {
                  setPage(1);
                  setPaymentInStatus(val);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        loading={false}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        scroll={{ x: 1000, y: window.innerHeight - 315 }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as any)
            : receiptList
        }
        onChange={tableSort}
        summary={() => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row
                style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
              >
                <Table.Summary.Cell
                  index={1}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="fw600">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={4}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} className="fw600" align="right">
                  ₹ {Utils.getFormattedNumber(totalAmount)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="fw600" align="right">
                  ₹ {Utils.getFormattedNumber(totalRecAmount)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="fw600" align="right">
                  ₹ {Utils.getFormattedNumber(totalAmount - totalRecAmount)}
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={9}
                  className="fw600"
                ></Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        // onRow={(record, rowIndex) => ({
        //   onMouseEnter: () => {
        //     // Set the visibility of the popover for the current row to true
        //     const updatedStates = [...visibleStates];
        //     updatedStates[rowIndex] = true;
        //     setVisibleStates(updatedStates);
        //   },
        //   onMouseLeave: () => {
        //     // Set the visibility of the popover for the current row to false
        //     const updatedStates = [...visibleStates];
        //     updatedStates[rowIndex] = false;
        //     setVisibleStates(updatedStates);
        //   },
        // })}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />
      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeletePaymentIn({
              ...deletePaymentIn,
              id: paymentId,
              isRev:
                paymentInStatus === VoucherFilterStatus.Deleted ? true : false,
            });
            setConfirmation(false);
          }}
          text={`Are you sure you want to delete "${deletePaymentIn.number}" ?`}
        />
      )}
      {open && (
        <ViewPaymentIn
          open={open}
          onCancel={() => setIsOpen(false)}
          paymentId={paymentId}
        />
      )}
      {openSalesInvoice && (
        <ViewSalesInvoice
          open={openSalesInvoice}
          onCancel={() => setOpenSalesInvoice(false)}
          invoiceId={salesInvoiceId}
        />
      )}
    </Card>
  );
};
export default PaymentIn;
