import React from "react";
import {
  DownloadOutlined,
  ReloadOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Row,
  Skeleton,
  Table,
  Tabs,
} from "antd";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import { ReportSettings } from "../../Common/CAPComponents";
import { ITrialBalanceSettings } from "./Ageing";
import type { TableColumnsType } from "antd";
import { useTheme } from "../../Context/ThemeContext";
import ReportsServiceNew from "../../Services/ReportsServiceNew";
import VoucherDetailsByLedger from "./VoucherDetailsByLedger";
import { Utils } from "../../../Utilities/Utils";
import ImportLedger from "../Settings/ImportLedger";
type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface TrialBalanceData {
  id: string;
  key: React.Key;
  ledgerName: string;
  debit: number;
  credit: number;
  totalDebit: number;
}
const TrialBalance_New: React.FC = () => {
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [shouldRefresh, setShouldRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [trialBalanceList, setTrialBalanceList] = React.useState<any[]>();
  const [debitTotal, setDebitTotal] = React.useState<number>(0);
  const [creditTotal, setCreditTotal] = React.useState<number>(0);
  const [settings, setSettings] = React.useState<
    ITrialBalanceSettings | undefined
  >();
  const [activeKey, setActiveKey] = React.useState("");
  const [moreTabs, setMoreTabs] = React.useState<any[]>([]);
  const [ledgerId, setLedgerId] = React.useState<string>("");
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [importModal, setImportModal] = React.useState<any>({
    type: "",
    param: {},
  });
  const [trigger, setTrigger] = React.useState<boolean>(false);

  const newTabIndex = React.useRef(0);
  const [importedLedgers, setImportedLedgers] = React.useState<any>(null);

  //const findLedgerName = trialBalanceList?.map((el) => el.ledgerName);

  // RemoveTabs//
  const remove = (targetKey: TargetKey) => {
    const newPanes = moreTabs?.filter((pane) => pane.key !== targetKey);
    setMoreTabs(newPanes);
    if (newPanes.length && targetKey === activeKey) {
      setActiveKey("Trial Balance");
    }
    // setMoreTabs(newPanes);
  };

  // console.log("testCaseKey",activeKey );

  //   {------setWindowWidth-----------}
  React.useEffect(() => {
    // console.log(width);
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  //   {------shouldRefresh-----------}
  React.useEffect(() => {
    setSettings(companySettings?.setting?.tbSettings);
  }, [companySettings, shouldRefresh]);

  //  {------ Get trialBalanceList ---------}
  React.useEffect(() => {
    getTrialBalanceList(
      currentClient?.id!,
      true,
      filterDate.from,
      filterDate.to
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, filterDate.from, filterDate.to, shouldRefresh]);

  const getTrialBalanceList = async (
    clientId: string,
    includeOpening: true,
    fromDate: string,
    toDate: string
  ) => {
    setLoading(true);
    await ReportsServiceNew.getTrialBalanceReport(
      clientId,
      includeOpening,
      fromDate,
      toDate
    )
      .then((res: any) => {
        console.log("TrialBalance", res);
        setTrialBalanceList(
          res?.ledgers?.map((el: any, i: any) => {
            return {
              key: i,
              id: el?.id,
              ledgerName: el?.ledgerName,
              debit: el?.balance > 0 ? el?.balance : "-",
              credit: el?.balance < 0 ? Math.abs(el?.balance) : "-",
            };
          })
        );
        setDebitTotal(res?.totalDebit);
        setCreditTotal(res?.totalCredit);
        setLoading(false);
        console.log("TrailBalance", trialBalanceList);
      })
      .catch((err: any) => {
        //console.log("error", err);
        setLoading(false);
      });
  };

  const openNewTab = (record: any) => {
    const newActiveKey = record?.id;
    if (moreTabs.find((x) => x.key === newActiveKey)) {
      setActiveKey(newActiveKey);
    } else {
      setMoreTabs([
        ...moreTabs,
        {
          label: `${record?.ledgerName}`,
          children: "New Tab Pane",
          key: newActiveKey,
        },
      ]);
      setActiveKey(newActiveKey);
      setLedgerId(record?.id);
    }
  };

  const columns: TableColumnsType<TrialBalanceData> = [
    {
      title: "Ledger Name",
      dataIndex: "ledgerName",
      width: "70%",
      align: "left",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div
            className="hoverLinkColor cursorPointer"
            onClick={() => {
              openNewTab(record);
              console.log("LedgerId", record?.id);
            }}
          >
            {record?.ledgerName}
          </div>
        </Skeleton>
      ),
    },
    {
      title: "Debit",
      dataIndex: "debit",
      ellipsis: true,
      width: width <= 1440 ? "11%" : "10%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <div
            className="hoverLinkColor cursorPointer"
            onClick={() => openNewTab(record)}
          >
            {Utils.getFormattedNumber(record?.debit)}
          </div>
        </Skeleton>
      ),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      ellipsis: true,
      width: width <= 1440 ? "11%" : "10%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <div
            className="hoverLinkColor cursorPointer"
            onClick={() => openNewTab(record)}
          >
            {Utils.getFormattedNumber(record?.credit)}
          </div>
        </Skeleton>
      ),
    },
  ];

  console.log("balanca", trialBalanceList);

  const commonBar = () => (
    <>
      <Row>
        <Col lg={12}>
          {activeKey === "Trial Balance" || activeKey === "" ? (
            <>
              <div className="themeColor fs-20 fw-600">
                {currentClient?.businessName}
              </div>
              <p style={{ color: "rgb(120, 120, 120)" }}>
                Trial Balance as on {filterDate.to}
              </p>
            </>
          ) : (
            ""
          )}
        </Col>
        <Col lg={12}>
          <Row justify={"end"} gutter={[8, 0]}>
            <Col>
              <RangePicker
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                allowClear={false}
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate!,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                  // console.log(
                  //   "CheckFilterDate",
                  //   filterDate.from,
                  //   filterDate.to
                  // );
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Button icon={<DownloadOutlined />}></Button>
            </Col>
            <Col>
              <Button
                icon={<ReloadOutlined />}
                disabled={loading}
                onClick={() => setShouldRefresh(!shouldRefresh)}
              ></Button>
            </Col>
            {/* <Col>
              <Button
                onClick={() =>
                  setImportModal({ type: "importModal", param: {} })
                }
              >
                Import Trial Balance
              </Button>
            </Col> */}

            {Array.isArray(trialBalanceList) &&
              trialBalanceList.length === 0 && (
                <Col>
                  <Button
                    onClick={() =>
                      setImportModal({ type: "importModal", param: {} })
                    }
                  >
                    Import Trial Balance
                  </Button>
                </Col>
              )}
          </Row>
        </Col>
        <Divider style={{ margin: "0px" }} />
      </Row>
    </>
  );

  const initialItems = [
    {
      label: "Trial Balance",
      key: "Trial Balance",
      closable: false,
      children: (
        <div style={{ width: "100%" }} className="m-auto">
          {commonBar()}
          <Table
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            size="small"
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: 15 }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as TrialBalanceData[])
                : trialBalanceList
            }
            pagination={false}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row
                    style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
                  >
                    <Table.Summary.Cell index={1} className="fw600">
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={2}
                      className="fw600"
                      align="right"
                    >
                      ₹ {Utils.getFormattedNumber(Math.abs(creditTotal))}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={3}
                      className="fw600"
                      align="right"
                    >
                      ₹ {Utils.getFormattedNumber(Math.abs(debitTotal))}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
            // scroll={trialBalanceList.length > 10 ? { x: 700, y: 620 } : { x: 600 }}
          />
        </div>
      ),
    },
  ];

  // {commonBar()}
  const updatedItems = initialItems?.concat(
    moreTabs?.map((tab: any) => ({
      label: tab.label,
      key: tab.key,
      closable: true,
      children: (
        <div style={{ width: "100%" }} className="m-auto">
          <VoucherDetailsByLedger
            ledgerId={tab.key}
            filterFromDate={filterDate.from}
            filterToDate={filterDate.to}
          />
        </div>
      ),
    }))
  );

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        margin: "6px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      {moreTabs?.length > 0 ? (
        <Tabs
          hideAdd
          type="editable-card"
          className="tabFlexOverride"
          onEdit={remove}
          activeKey={activeKey}
          items={updatedItems}
          onChange={(key) => setActiveKey(key)}
          tabBarExtraContent={
            <Button
              className="ms-10"
              onClick={() => {
                setMoreTabs([]);
                //setTabData(undefined);
                setActiveKey("");
              }}
            >
              Close All
            </Button>
          }
        />
      ) : (
        <>
          <div style={{ width: "80%" }} className="m-auto">
            {commonBar()}
            <Table
              className={`Tabel-style table-${
                themeData?.componentSize ?? "middle"
              }`}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => console.log("event", event, record?.id),
                };
              }}
              size="small"
              columns={columns}
              dataSource={
                loading
                  ? (Array.from({ length: 15 }, (_, index) => ({
                      key: `loading-${index}`,
                    })) as TrialBalanceData[])
                  : trialBalanceList
              }
              pagination={false}
              summary={() => {
                return (
                  <Table.Summary>
                    <Table.Summary.Row
                      style={{
                        backgroundColor: "#f5f8ff",
                        fontWeight: "bold",
                      }}
                    >
                      <Table.Summary.Cell index={1} className="fw600">
                        Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        className="fw600"
                        align="right"
                      >
                        ₹ {Utils.getFormattedNumber(Math.abs(debitTotal))}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        className="fw600"
                        align="right"
                      >
                        ₹ {Utils.getFormattedNumber(Math.abs(creditTotal))}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
              // scroll={trialBalanceList.length > 10 ? { x: 700, y: 620 } : { x: 600 }}
            />
          </div>
        </>
      )}
      {/* customize setting modal */}
      {isModalOpen && (
        <ReportSettings
          isOpen={isModalOpen}
          setOpen={setIsModalOpen}
          type="TB"
          value={settings}
          refresh={shouldRefresh}
          setRefresh={setShouldRefresh}
        />
      )}

      {importModal?.type === "importModal" && (
        <ImportLedger
          onDismiss={(confirmed: boolean) => {
            setImportModal({ type: "", param: {} });
          }}
          setImportedLedgers={setImportedLedgers}
          setTrigger={setTrigger}
        />
      )}
    </Card>
  );
};

export default TrialBalance_New;
