import { CloseOutlined, FormOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useContext, useEffect } from "react";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import { BusinessTypes } from "../../../Types/CommonConstants";
import { useIndianState } from "../../Context/StateOptionsContext";
import {
  ICompanyInfo,
  ICompanySettings,
  SettingsSections,
} from "../../Services/SettingService";
import { notificationContext } from "../../Common/PageRoute";
import { BusinessTypeOptions } from "../Clients/Clients";

const SettingsCompanyInfo = () => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  // const [form3] = Form.useForm();
  const { stateOptions } = useIndianState();
  const { openNotification } = useContext(notificationContext);
  const { companySettings, updateCompanySettings } = useClient();
  // const [showData, setShowData] = React.useState<boolean>(false);
  // const [gstDisable, setGstDisable] = React.useState<boolean>(true);
  const [infoDisable, setInfoDisable] = React.useState<boolean>(true);
  const [fydate, SetFYDate] = React.useState<string | undefined>("");
  const [detailsDisable, setDetailsDisable] = React.useState<boolean>(true);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);

  useEffect(() => {
    const set = companySettings?.setting;
    SetFYDate(set?.companyInfo?.fydate);
    const formData = {
      ...set,
      companyInfo: {
        ...set?.companyInfo,
        financialYearBegin: dayjs(set?.companyInfo?.financialYearBegin),
      },
      regAddress: {
        ...set?.companyInfo?.regAddress,
        // startDate: dayjs(set?.companyContactDetails?.),
      },

      // gstDetails: {
      //   ...set?.gstDetails,
      //   gst: {
      //     title: set?.gstDetails?.gst?.rate,
      //     label: set?.gstDetails?.gst?.name,
      //     value: set?.gstDetails?.gst?.id,
      //     key: set?.gstDetails?.gst?.name,
      //   },
    };
    form.setFieldsValue(formData);
    form2.setFieldsValue(formData);
    // },
    // form3.setFieldsValue(formData);
    // formData?.gstDetails?.isGSTEnabled &&
    //   setShowData(formData?.gstDetails?.isGSTEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log("set", set);
  }, [companySettings]);

  return (
    <>
      <Row style={{ width: "90%", padding: "0px 0px 3px 0px" }}>
        <Col lg={11}>
          <Row justify={"space-between"}>
            <Col className="fs-15 fw-600">Company Information</Col>
            <Col>
              {infoDisable && (
                <Tooltip title="Edit">
                  <FormOutlined
                    className="ca-edit-btn"
                    style={{ color: "rgb(106 119 227)" }}
                    onClick={() => {
                      setInfoDisable(false);
                      setDetailsDisable(true);
                      // setGstDisable(true);
                    }}
                  />
                </Tooltip>
              )}
            </Col>
          </Row>
          <Divider className="my-12" />
          <Form
            form={form}
            disabled={infoDisable}
            autoComplete="off"
            name="CompanyInfo"
            onFinish={(val) => {
              console.log(val.companyInfo);

              setSaveButtonLoading(true);
              // financialYearBegin: val.date.toISOString(),
              // const data: ICompanyInfo = {
              //   ...val?.companyInfo,
              //   financialYearBegin: val?.companyInfo.financialYearBegin.toISOString(),
              // };

              updateCompanySettings!(
                SettingsSections.CompanyInfo,
                {
                  ...companySettings,
                  setting: {
                    ...companySettings?.setting!,
                    companyInfo: val?.companyInfo,
                  },
                },
                (val = {
                  ...val,
                  companyInfo: {
                    ...val?.companyInfo,
                    fydate: fydate,
                  },
                })
              ).then((result: any) => {
                if (result?.status) {
                  setInfoDisable(true);
                  openNotification("success", "Setting Updated successfully");
                  setSaveButtonLoading(false);
                } else {
                  setSaveButtonLoading(false);
                  openNotification("error", "there is some error");
                }
              });
            }}
          >
            <Row>
              <Col lg={5}>Name</Col>
              <Col lg={19}>
                <Form.Item
                  className="mb-10"
                  name={["companyInfo", "name"]}
                  rules={[{ required: true, message: "required!!!" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={5}>Financial Year start</Col>
              <Col lg={8}>
                <Form.Item
                  className="mb-10"
                  name={["companyInfo", "financialYearBegin"]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(date: any, dateString: string) => {
                      SetFYDate(dateString);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={4}>Business Type</Col>
              <Col lg={7}>
                <Form.Item
                  className="mb-10"
                  name={["companyInfo", "businessType"]}
                >
                  <Select
                    style={{ width: "100%" }}
                    options={BusinessTypeOptions}
                  />
                </Form.Item>
              </Col>
              <Col lg={5}>PAN</Col>
              <Col lg={8}>
                <Form.Item className="mb-10" name={["companyInfo", "panNo"]}>
                  <Input style={{ width: "154px" }} />
                </Form.Item>
              </Col>
              <Col lg={4}>GST Number</Col>
              <Col lg={7}>
                <Form.Item
                  className="mb-10"
                  name={["companyInfo", "gstNumber"]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={5}>Reg. Office</Col>
              <Col lg={19}>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "building"]}
                      className="m-0"
                    >
                      <Input placeholder="Building" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "district"]}
                      className="m-0"
                    >
                      <Input placeholder="District" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "city"]}
                      className="m-0"
                    >
                      <Input placeholder="City" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "pincode"]}
                      className="m-0"
                    >
                      <Input placeholder="Pincode" />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "state"]}
                      className="m-0"
                    >
                      <Select
                        showSearch
                        placeholder="State"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={stateOptions?.map((x: any) => ({
                          value: x.id,
                          label: x.name,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "landmark"]}
                      className="mb-10"
                    >
                      <Input placeholder="Landmark" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "street"]}
                      className="mb-10"
                    >
                      <Input placeholder="Street" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={["companyInfo", "regAddress", "country"]}
                      className="mb-10"
                    >
                      <Input placeholder="Country" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="end" style={{ height: 32 }}>
              {!infoDisable && (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ backgroundColor: "aliceblue" }}
                    onClick={() => setInfoDisable(true)}
                  >
                    Cancel
                  </Button>
                  <Form.Item className="m-0">
                    <Button
                      icon={<SaveOutlined />}
                      type="primary"
                      htmlType="submit"
                      loading={saveButtonLoading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Space>
              )}
            </Row>
          </Form>
        </Col>
        <Col
          lg={1}
          style={{ borderRight: "1px solid rgb(226 226 226 / 88%)" }}
        ></Col>
        <Col lg={1}></Col>
        <Col lg={11}>
          <Row justify={"space-between"}>
            <Col className="fs-15 fw-600">Contact Details</Col>
            <Col>
              {detailsDisable && (
                <Tooltip title="Edit">
                  <FormOutlined
                    className="ca-edit-btn"
                    style={{ color: "rgb(106 119 227)" }}
                    onClick={() => {
                      setDetailsDisable(false);
                      setInfoDisable(true);
                      // setGstDisable(true);
                    }}
                  />
                </Tooltip>
              )}
            </Col>
          </Row>
          <Divider className="my-12" />
          <Form
            form={form2}
            disabled={detailsDisable}
            autoComplete="off"
            name="ConatactDetails"
            onFinish={(val) => {
              setSaveButtonLoading(true);
              updateCompanySettings!(
                SettingsSections.ContactDetails,
                {
                  ...companySettings,
                  setting: {
                    ...companySettings?.setting!,
                    companyContactDetails: val?.companyContactDetails,
                  },
                },
                val
              )
                .then((result: any) => {
                  if (result?.status) {
                    setDetailsDisable(true);
                    openNotification("success", "Setting Updated successfully");
                    setSaveButtonLoading(false);
                  } else {
                    setSaveButtonLoading(false);
                    openNotification("error", "there is some error");
                  }
                })
                .catch((ex: any) => {
                  setSaveButtonLoading(false);
                  openNotification("error", ex.message);
                });
            }}
          >
            <Row>
              <Col lg={5}>Phone</Col>
              <Col lg={19}>
                <Form.Item
                  className="mb-10"
                  name={["companyContactDetails", "phone"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input phone number!",
                    },
                    { min: 10, message: "Phone no. must be 10 digits" },
                  ]}
                >
                  <Input
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col lg={5}>Mobile</Col>
              <Col lg={19}>
                <Form.Item
                  className="mb-10"
                  name={["companyContactDetails", "mobile"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input Mobile!",
                    },
                    { min: 10, message: "Mobile No. must be of 10 digits" },
                  ]}
                >
                  <Input
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col lg={5}>Email</Col>
              <Col lg={19}>
                <Form.Item
                  className="mb-10"
                  name={["companyContactDetails", "email"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input Email!",
                    },
                    {
                      pattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" style={{ height: 32 }}>
              {!detailsDisable && (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ backgroundColor: "aliceblue" }}
                    onClick={() => setDetailsDisable(true)}
                  >
                    Cancel
                  </Button>

                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    htmlType="submit"
                    loading={saveButtonLoading}
                  >
                    Save
                  </Button>
                </Space>
              )}
            </Row>
          </Form>
          {/* <Row justify="space-between" className="pt-25">
            <Col className="fs-15 fw-600">GST</Col>
            <Col>
              {gstDisable && (
                <FormOutlined
                  className="ca-edit-btn"
                  style={{ color: "rgb(106 119 227)" }}
                  onClick={() => {
                    setGstDisable(false);
                    setInfoDisable(true);
                    setDetailsDisable(true);
                  }}
                />
              )}
            </Col>
          </Row>
          <Divider className="my-12" />
          <Form
            form={form3}
            disabled={gstDisable}
            autoComplete="off"
            name="Gst"
            onFinish={(val) => {
              let data = {
                gstDetails: {
                  ...val?.gstDetails,
                  gst: {
                    id: val?.gstDetails?.gst?.value,
                    name: val?.gstDetails?.gst?.label,
                    rate: val?.gstDetails?.gst?.title,
                  },
                  isGSTEnabled: showData,
                },
              };
              setSaveButtonLoading(true);
              updateCompanySettings!(
                SettingsSections.GstDetails,
                {
                  ...companySettings,
                  setting: {
                    ...companySettings?.setting!,
                    gstDetails: data?.gstDetails,
                  },
                },
                data
              )
                .then((result: any) => {
                  if (result?.status) {
                    setGstDisable(true);
                    openNotification("success", "Setting Updated successfully");
                    setSaveButtonLoading(false);
                  } else {
                    openNotification("error", "there is some error");
                    setSaveButtonLoading(false);
                  }
                })
                .catch((ex: any) => {
                  setSaveButtonLoading(false);
                  openNotification("error", ex.message);
                });
            }}
          >
            <Row>
              <Col lg={12}>Is business registered for GST</Col>
              <Col lg={12}>
                <Form.Item
                  className="mb-10"
                  name={["gstDetails", "isGSTEnabled"]}
                >
                  <Checkbox
                    checked={showData}
                    onChange={(e) => setShowData(e.target.checked)}
                  />
                </Form.Item>
              </Col>
              {showData && (
                <>
                  <Col lg={12}>Registration number</Col>
                  <Col lg={12}>
                    <Form.Item
                      className="mb-10"
                      name={["gstDetails", "registrationNo"]}
                    >
                      <Input disabled placeholder="1234567890" />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>Registration date</Col>
                  <Col lg={12}>
                    <Form.Item className="mb-10">
                      <DatePicker disabled />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>Gst Rate</Col>
                  <Col lg={12}>
                    <Form.Item className="mb-10" name={["gstDetails", "gst"]}>
                      <Select
                        labelInValue
                        placeholder="select"
                        popupMatchSelectWidth={false}
                        dropdownStyle={{ width: "250px" }}
                        style={{ width: "145px" }}
                        placement="bottomRight"
                      >
                        {companySettings?.gsts?.map((itm: any) => (
                          <Select.Option
                            key={itm.name}
                            value={itm.id}
                            title={itm.rate}
                          >
                            {itm.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Row justify="end" style={{ height: 32 }}>
              {!gstDisable && (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ backgroundColor: "aliceblue" }}
                    onClick={() => {
                      setGstDisable(true);
                      setShowData(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    htmlType="submit"
                    loading={saveButtonLoading}
                  >
                    Save
                  </Button>
                </Space>
              )}
            </Row>
          </Form> */}
        </Col>
      </Row>
    </>
  );
};

export default SettingsCompanyInfo;
