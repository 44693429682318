import React, { useEffect, useRef, useState } from "react";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
// import { notificationContext } from "../../Common/PageRoute";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  MenuProps,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { useLocation } from "react-router-dom";

import { dayjs } from "../../../Utilities/dayjs";
import {
  CloseOutlined,
  DownOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Utils } from "../../../Utilities/Utils";
import VoucherServices, {
  Party_Tax,
  ReconciledFilter,
  Voucher_Type,
} from "../../Services/VoucherServices";
import noteContext from "../../Context/StyleContext";
import deleteImg from "../../../images/deletebutton.svg";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import { AddLedger } from "../Master/Ledger/AddLedger";
import TextArea from "antd/es/input/TextArea";
import Party from "../Parties/Party";
import { number } from "echarts";
import { LocalChunkSize } from "papaparse";
import {
  PurchaseDropdownItems,
  SalesDropdownItems,
} from "./SalesandPurchaseDropdownList";
import {
  ModifierKey,
  useMultipleKeyboardShortcuts,
} from "../../Common/useMultipleKeyboardShortcuts";
import Icon from "@ant-design/icons/lib/components/Icon";

const AddPaymentInNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef: any = useRef(null);
  const selectRefIndex: any = useRef<number[]>([]);
  const { paymentId } = useParams();
  const { currentClient } = useClient();
  const { windowWidth } = React.useContext(noteContext);
  const { openNotification } = React.useContext(notificationContext);
  // const [itemList, setItemList] = useState<any[]>([]);
  const { companySettings, updateCompanySettings } = useClient();

  const [form] = Form.useForm();
  const [accountLedgerOption, setAccountLedgerOption] = React.useState<any[]>(
    []
  );
  const [partyLedgerOption, setPartyLedgerOption] = React.useState<any[]>([]);
  const [selectedPartyOption, setSelectedPartyOption] = React.useState<any[]>(
    []
  );
  const [refresh, setRefresh] = React?.useState(false);
  const [trigger, setTrigger] = React?.useState(false);

  const [openModel, setOpenModel] = React?.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [deleteItemList, setDeleteItemList] = React.useState<{
    name: any;
    index: number;
  }>({
    name: "",
    index: -1,
  });
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [currentBalance, setCurrentBalance] = React.useState<number>(0.0);
  const [accountIsParty, setAccountIsParty] = React.useState<Party_Tax>();
  const [partyRef, setPartyRef] = React.useState<any[]>([]);
  const [selectPartyRef, setSelectPartyRef] = React.useState<any[]>([]);
  const [editPayment, setEditPayment] = React.useState<{
    balance: number;
    id: string;
  }>({
    balance: 0.0,
    id: "",
  });
  // const [referencesByPartyList, getReferencesByPartyList] = React.useState<
  //   any[]
  // >([]);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [totalPaymentIn, setTotalPaymentIn] = React.useState<number>(0);
  const [disabledAmountInputs, setDisabledAmountInputs] = React.useState(
    Array(0).fill(false)
  );
  const [selectedParty, setSelectedParty] = useState<string>("");

  const [openLedgerDrawer, setOpenLedgerDrawer] = React.useState<{
    open: boolean;
    id: string;
  }>({
    open: false,
    id: "",
  });
  const [allUpdatePartyData, setAllUpdatePartyData] = React.useState<any[]>([]);
  const [dataFiled, setDateFiled] = React.useState<any>();
  const [salesInstantPayment, setSalesInstantPayment] =
    React.useState<boolean>(false);

  const [bankImportData, setBankImportData] = React.useState<{
    bankImportEntryRef: string;
    isReconciled: ReconciledFilter;
  }>({
    bankImportEntryRef: "",
    isReconciled: ReconciledFilter.nonreconciled,
  });
  
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  let splitPathInVchType: Voucher_Type;
  let splitPathLedgerByGroupsType: SearchLedgerByGroupsTypes;
  if (location.pathname.split("/")[4] === "sales") {
    splitPathInVchType = Voucher_Type.Receipt;
    splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.PaymentIn;
  } else {
    splitPathInVchType = Voucher_Type.Payment;
    splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.PaymentOut;
  }

  React.useEffect(() => {
    console.log("location.state?.ledgerId", location.state?.ledgerId);
    if (
      location?.state?.BankTransactionData &&
      accountLedgerOption?.length > 0
    ) {
      // console.log("oooooo", location?.state?.partyOrTax);
      const findBankAccountDetails = accountLedgerOption?.find(
        (x) => x.id === location.state?.ledgerId
      );
      const bankTransactionAmount =
        location?.state?.BankTransactionData?.moneyIn ||
        location?.state?.BankTransactionData?.moneyOut;
      // console.log("findBankAccountDetails", findBankAccountDetails);

      setAccountIsParty(findBankAccountDetails?.partyOrTax);
      setCurrentBalance(findBankAccountDetails.balance);
      setTotalPaymentIn(bankTransactionAmount);
      const dateImportTransactionD = dayjs(
        location?.state?.BankTransactionData?.date,
        "DD/MM/YYYY"
      );
      // onChangeRefAmountSet(bankTransactionAmount, 0, true);
      form?.setFieldsValue({
        payRecReference: location?.state?.BankTransactionData?.reference,
        date: dateImportTransactionD,
        bankEntryAmount:
          location?.state?.BankTransactionData?.moneyOut ||
          location?.state?.BankTransactionData?.moneyIn,
        effectiveLedger: {
          value: location.state?.ledgerId,
        },
        partyLedger: [
          {
            amount:
              location?.state?.BankTransactionData?.moneyOut ||
              location?.state?.BankTransactionData?.moneyIn,
            // other fields if needed
          },
        ],

        ref: [
          {
            value:
              location?.state?.BankTransactionData?.moneyOut ||
              location?.state?.BankTransactionData?.moneyIn,
            // other fields if needed
          },
        ],
      });
      setDateFiled(dateImportTransactionD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountLedgerOption]);

  // Date Api
  React.useEffect(() => {
    if (!location?.state?.BankTransactionData?.Date) {
      const getDate = async () => {
        if (currentClient?.id && !paymentId) {
          await VoucherServices.getMaxVoucherDate(
            currentClient?.id!,
            splitPathInVchType
          ).then((res) => {
            const givenDate = new Date(res?.result);
            const currentTime = new Date();

            // Set the time part of givenDate to match currentTime
            givenDate?.setHours(
              currentTime.getHours(),
              currentTime.getMinutes(),
              currentTime.getSeconds(),
              currentTime.getMilliseconds()
            );
            form.setFieldValue("date", dayjs(givenDate));

            setDateFiled(res.result);
          });
        }
      };
      getDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  const setEffectiveLedger = (voucherType: Voucher_Type) => {
    const ledger = companySettings?.setting?.defaultLedgers.find(
      (x) => x.voucherType === voucherType
    );
    if (ledger) {
      form.setFieldValue("effectiveLedger", {
        value: ledger.defaultLedgerId,
      });
    }
  };

  // ----- initial value -----
  React.useEffect(() => {
    if (currentClient?.id && !paymentId && dataFiled) {
      setLoading(true);
      VoucherServices.getVoucherMaxNo(
        currentClient?.id!,
        splitPathInVchType,
        dataFiled
      )
        .then((res) => {
          form.setFieldValue("number", res?.result);
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err.message);
        });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, dataFiled]);

  // Cash & bank account select
  React.useEffect(() => {
    inputRef?.current?.focus();
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes.BankAndCash
    ).then((res: any) => {
      if (res) {
        setAccountLedgerOption(res);
        // user select default ladger in settings (value set in Current Balance)

        const setEffectiveLedger = (voucherType: Voucher_Type) => {
          const ledger = companySettings?.setting?.defaultLedgers?.find(
            (x) => x.voucherType === voucherType
          );
          if (ledger) {
            form.setFieldValue("effectiveLedger", {
              value: ledger.defaultLedgerId,
            });
            return ledger;
          }
          return null;
        };

        const handleLedgerDetails = (voucherType: Voucher_Type) => {
          const ledger = setEffectiveLedger(voucherType);
          if (ledger) {
            const selectAccount = res.find(
              (x: any) => x.id === ledger.defaultLedgerId
            );
            if (selectAccount) {
              setCurrentBalance(selectAccount.balance);
              setAccountIsParty(selectAccount.partyOrTax);
              setSelectedParty(ledger.defaultLedgerId);
              setOpenLedgerDrawer({
                ...openLedgerDrawer,
                id: ledger.defaultLedgerId,
              });
            }
          }
        };

        if (!paymentId && !location.state?.ledgerId) {
          // debugger;
          handleLedgerDetails(splitPathInVchType);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // party & other payment select
  React.useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      splitPathLedgerByGroupsType
    ).then((res) => {
      if (res) {
        setPartyLedgerOption(res);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // post payment api
  const save = () => {
    const tempAmount = handleAllLedgerValue();
    form.validateFields().then(async (val: any) => {
      console.log("post", val);
      setSaveButtonLoading(true);
      console.log("val.date", val.date, "givenDate");

      const givenDate = new Date(val.date);
      const currentTime = new Date();

      // Set the time part of givenDate to match currentTime
      givenDate?.setHours(
        currentTime.getHours(),
        currentTime.getMinutes(),
        currentTime.getSeconds(),
        currentTime.getMilliseconds()
      );

      const data = {
        ...val,
        amount: tempAmount,
        effectiveLedger: [
          {
            id: val?.effectiveLedger.value,
            amount: tempAmount,
            partyOrTax: accountIsParty,
          },
        ],
        date: dayjs(givenDate).toISOString(),
        instantPayment: salesInstantPayment,
        isLocked: false,
        isReconciled: location.state?.ledgerId
          ? ReconciledFilter.reconciled
          : bankImportData.isReconciled,
        isAudited: false,
        isActive: true,
        bankImportEntryRef: location.state?.ledgerId
          ? location.state?.bankEntriesId
          : bankImportData.bankImportEntryRef,
        partyLedger: val.partyLedger.map((val: any) => {
          return {
            ...val,
            id: val?.id?.value,
            partyOrTax: val?.id?.title,
            ref:
              val?.ref?.length > 0
                ? val?.ref.map((val: any) => {
                    return {
                      ...val,
                      name: val?.name?.label ?? val?.name,
                      partyRef: val?.name?.value,
                    };
                  })
                : null,
          };
        }),
        // freightInsLedgers: val.partyLedger
        //   .filter((x: any) => x?.id?.title === 0)
        //   .map((val: any) => {
        //     return {
        //       ...val,
        //       id: val?.id?.value,
        //       partyOrTax: val?.id?.title,
        //       ref: val?.ref?.length > 0 ? val?.ref : null,
        //     };
        //   }),
        // statutoryLedgers: val.partyLedger
        //   .filter((x: any) => x?.id?.title === 2)
        //   .map((val: any) => {
        //     return {
        //       ...val,
        //       id: val?.id?.value,
        //       partyOrTax: val?.id?.title,
        //       ref: val?.ref?.length > 0 ? val?.ref : null,
        //     };
        //   }),
      };

      console.log("post data", data);

      await VoucherServices.postVoucher(
        currentClient?.id!,
        splitPathInVchType,
        data,
        paymentId
      )
        .then((res) => {
          if (res?.status) {

            openNotification(
              "success",
              `Payment ${res.result?.name} ${
                paymentId ? "Edit Successfully" : "Add Successfully"
              }`
            );
            // splitPathInVchType === Voucher_Type.Receipt
            //   ? navigate(
            //       `/accounts/clients/${currentClient?.id}/sales/receipts`
            //     )
            //   : navigate(
            //       `/accounts/clients/${currentClient?.id}/purchase/PaymentOut`
            //     );
            console.log("post........", location);

            if (location.state?.ledgerId) {
              navigate(
                `/accounts/clients/${currentClient?.id}/banking/bankTransactionsDetails/${location.state?.bankEntriesId}`,
                {
                  state: {
                    ledgerId: location.state?.ledgerId,
                    bankImportIndex: location.state?.bankImportIndex,
                    voucherRef: [
                      {
                        id: res.result?.id,
                        name: res.result?.name,
                        voucherType: res.result?.voucherType,
                      },
                    ],
                  },
                }
              );
            } else navigate(-1);
          } else if (res?.message) {
            openNotification("error", res?.message);
          }
        })
        .catch((ex) => openNotification("error", ex));
      setSaveButtonLoading(false);
    });
  };

  const onClickSelectPartyLedger = (value: string, index: number) => {
    // console.log("x", value, index);
    setSelectedPartyOption((prevState) => {
      const updatedSelectedOptions = [...prevState];
      updatedSelectedOptions[index] = value;
      // console.log(updatedSelectedOptions);
      return updatedSelectedOptions;
    });

    setTimeout(() => {
      const lastInputField = selectRefIndex?.current[index];
      if (lastInputField) {
        lastInputField.current.select();
      }
    }, 0);
  };

  const partySelect: any = async (
    partyId: string,
    index: number,
    title?: Party_Tax
  ) => {
    // if (!paymentId) {
    setSelectPartyRef((prevDataArray) => {
      const newArray = [...prevDataArray];
      if (index < 0 || index >= newArray.length) {
        newArray.push([]);
      } else {
        newArray.splice(index, 1, []);
      }
      return newArray;
    });
    // }
    // user select party
    if (title === Party_Tax.party) {
      // update case if user delete reference & again add same reference
      if (paymentId) {
        let onePartyUpdateData = allUpdatePartyData.find(
          (x) => x.id.value === partyId
        );
        console.log("onePartyUpdateData", onePartyUpdateData);

        if (onePartyUpdateData) {
          form.setFieldValue(["partyLedger", index], {
            ...onePartyUpdateData,
          });

          setSelectPartyRef((prevState: any[]) => {
            const updatedSelectedOptions: any[] = [...prevState];
            updatedSelectedOptions[index] = [...(prevState[index] || [])];
            updatedSelectedOptions[index] = onePartyUpdateData?.ref?.map(
              (x: any) => x?.parentRef
            );
            return updatedSelectedOptions;
          });

          setPartyRef((prevDataArray) => {
            const newArray = [...prevDataArray];
            newArray[index] = onePartyUpdateData?.ref?.map((x: any) => {
              return {
                ...x,
                maxAmount: x?.dueAmount + x?.value,
              };
            });
            return newArray;
          });
        }

        console.log("allUpdatePartyData", onePartyUpdateData);
      }

      await VoucherServices.getReferencesByParty(
        currentClient?.id!,
        partyId,
        splitPathInVchType
      ).then((res: any) => {
        console.log("result", res.result?.length, index);
        if (res.result?.length > 0) {
          const data = res.result?.map((v: any, i: number) => {
            return {
              name: res.result[i]?.refNo,
              maxAmount: res.result[i]?.dueAmount,
              dueAmount: res.result[i]?.dueAmount,
              // id: res.result[i]?.id,
              parentRef: res.result[i]?.id,
              id: null,
              refType: 1,
            };
          });
          // yadi user ne new REf me 0 amount dala to update me new nahi aahe ga get api se but meko 0 index pe amount aa jahe to
          // update case user party delete & again select delete party
          if (
            paymentId &&
            allUpdatePartyData.find((x) => x.id.value === partyId)
          ) {
            setPartyRef((prevDataArray) => {
              const prevArray = prevDataArray[index];
              // Filter  the common elements based on 'parentRefId'
              const filteredData = data.filter((newItem: any) => {
                return !prevArray.some(
                  (prevItem: any) => prevItem?.parentRef === newItem?.parentRef
                );
              });
              const updatedArray = [...prevArray, ...filteredData];
              console.log("newArray", updatedArray);
              const newArray = [...prevDataArray];
              newArray[index] = updatedArray;
              return newArray;
            });
          } else {
            // debugger;
            form.setFieldValue(["partyLedger", index, "ref"], [{}]);

            form.setFieldValue(["partyLedger", index, "ref", 0], {
              name: form.getFieldValue(["number"]),
              value:
                location.state?.ledgerId && index === 0
                  ? form.getFieldValue(["partyLedger", 0, "amount"])
                  : 0,
              amount: 0,
              refType: 0,
            });

            form.setFieldValue(["partyLedger", index, "ref", 1], {});

            setPartyRef((prevDataArray) => {
              const newArray = [...prevDataArray];
              newArray[index] = data;
              return newArray;
            });
          }
        } else {
          // debugger;
          form.setFieldValue(["partyLedger", index, "ref"], [{}]);
          form.setFieldValue(["partyLedger", index, "ref", 0], {
            name: form.getFieldValue(["number"]),
            value:
              location.state?.ledgerId && index === 0
                ? form.getFieldValue(["partyLedger", 0, "amount"])
                : 0,
            amount: 0,
            refType: 0,
          });
          setPartyRef((prevDataArray) => {
            const newArray = [...prevDataArray];
            newArray[index] = [];
            return newArray;
          });
        }
      });
    } else {
      setPartyRef((prevDataArray) => {
        const newArray = [...prevDataArray];
        newArray[index] = [];
        return newArray;
      });
    }

    setDisabledAmountInputs((prev) => {
      const newDisabledInputs = [...prev];
      newDisabledInputs[index] = title === Party_Tax.party ? true : false;
      return newDisabledInputs;
    });
    // }
    // handleLedgerNetAmount(index);
  };

  //  -------- Edit payment Get API ---------
  React.useEffect(() => {
    if (paymentId && accountLedgerOption?.length > 0) {
      const editData = async (paymentId: string) => {
        setLoading(true);
        try {
          const res = await VoucherServices.getVoucharById(
            currentClient?.id!,
            paymentId,
            splitPathInVchType
          );
          // debugger;
          if (res.result) {
            console.log(
              "result.............................",
              res.result.instantPayment
            );
            setSalesInstantPayment(res.result?.instantPayment ?? false);
            setTotalPaymentIn(Math.abs(res.result?.effectiveLedger[0]?.amount));
            setEditPayment({
              balance: res.result?.amountSummary?.totalInvoiceAmount,
              id: res.result?.effectiveLedger[0]?.id,
            });
            setBankImportData({
              bankImportEntryRef: res.result?.bankImportEntryRef,
              isReconciled: res.result?.isReconciled,
            });

            const partyLedger = await Promise.all(
              res.result?.partyLedger.map(
                async (v: any, partyIndex: number) => {
                  // console.log("partyIndex", partyIndex);

                  setCurrentBalance(
                    accountLedgerOption.find(
                      (x) => x.id === res.result?.effectiveLedger[0]?.id
                    )?.balance
                  );

                  // party ref set only
                  if (v?.partyOrTax === Party_Tax.party) {
                    setSelectPartyRef((prevState: any[]) => {
                      const updatedSelectedOptions: any[] = [...prevState];
                      updatedSelectedOptions[partyIndex] = [
                        ...(prevState[partyIndex] || []),
                      ];
                      updatedSelectedOptions[partyIndex] = v?.ref?.map(
                        (x: any) => x.parentRef
                      );
                      return updatedSelectedOptions;
                    });

                    setPartyRef((prevDataArray) => {
                      const newArray = [...prevDataArray];
                      // if (v.ref[0]?.refType !== 0) {
                      //   newArray[partyIndex] = {
                      //     id: {
                      //       label: res?.result?.number,
                      //     },
                      //     maxAmount: Infinity,
                      //   };
                      // }
                      // // Separate handling for the first index
                      // else if (v.ref[0]?.refType === 0) {
                      //   newArray[partyIndex] = {
                      //     ...newArray[partyIndex],
                      //     maxAmount: v.ref[0]?.dueAmount + v.ref[0]?.value,
                      //   };
                      // }

                      newArray[partyIndex] = v?.ref.map(
                        (x: any, refIndex: Number) => {
                          return {
                            ...x,
                            maxAmount: x?.dueAmount + x?.value,
                          };
                        }
                      );
                      return newArray;
                    });
                    // party New Ref.
                    await VoucherServices.getReferencesByParty(
                      currentClient?.id!,
                      v?.id,
                      splitPathInVchType
                    ).then((res) => {
                      const data = res.result?.map((x: any, i: number) => {
                        return {
                          name: res.result[i]?.refNo,
                          maxAmount: res.result[i]?.dueAmount,
                          dueAmount: res.result[i]?.dueAmount,
                          // id: res.result[i]?.id,
                          parentRef: res.result[i]?.id,
                          id: null,
                          refType: 1,
                        };
                      });

                      setPartyRef((prevDataArray) => {
                        const prevArray = prevDataArray[partyIndex];
                        // Filter  the common elements based on 'id'
                        const filteredData = data.filter((newItem: any) => {
                          return !prevArray.some(
                            (prevItem: any) =>
                              prevItem?.parentRef === newItem?.parentRef
                          );
                        });
                        const updatedArray = [...prevArray, ...filteredData];
                        console.log("newArray", updatedArray);
                        const newArray = [...prevDataArray];
                        newArray[partyIndex] = updatedArray;
                        return newArray;
                      });
                    });
                  } else {
                    setPartyRef((prevDataArray) => {
                      const newArray = [...prevDataArray];
                      newArray[partyIndex] = [];
                      return newArray;
                    });
                    setSelectPartyRef((prevState: any[]) => {
                      const updatedSelectedOptions: any[] = [...prevState];
                      updatedSelectedOptions[partyIndex] = [];
                      return updatedSelectedOptions;
                    });
                  }

                  setDisabledAmountInputs((prev) => {
                    const newDisabledInputs = [...prev];
                    newDisabledInputs[partyIndex] =
                      v.partyOrTax === Party_Tax.party ? true : false;
                    // console.log(newDisabledInputs);
                    return newDisabledInputs;
                  });
                  setSelectedPartyOption((prevState) => {
                    const updatedSelectedOptions = [...prevState];
                    updatedSelectedOptions[partyIndex] = v?.id;
                    return updatedSelectedOptions;
                  });

                  // send filed  instantPayment: true, update
                  return {
                    ...v,
                    ref:
                      v?.ref[0]?.refType === 0
                        ? [...v?.ref, {}]
                        : [
                            { refType: 0, name: res?.result?.number },
                            ...v?.ref,
                            {},
                          ],
                    amount: Math.abs(v?.amount),
                    id: {
                      value: v?.id,
                      label: v?.ledgerName,
                      title: v?.partyOrTax,
                    },
                  };
                }
              )
            );

            setAccountIsParty(res.result?.effectiveLedger[0]?.partyOrTax);
            setAllUpdatePartyData([...partyLedger]);
            form.setFieldsValue({
              ...res.result,
              effectiveLedger: {
                value: res.result?.effectiveLedger[0]?.id,
                label: res.result?.effectiveLedger[0]?.ledgerName,
              },
              partyLedger: [...partyLedger, { amount: 0 }],
              date: dayjs(res.result?.date),
            });
            setSelectedParty(res.result?.effectiveLedger[0]?.id);
            setLoading(false);
          }
        } catch (err: any) {
          openNotification("error", err.message);
          console.error("error..", err.message);
          setLoading(false);
        }
      };

      editData(paymentId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId, accountLedgerOption]);

  const handleLedgerNetAmount = (index: number) => {
    // console.log(
    //   "false.............",
    //   form.getFieldValue(["partyLedger", index])?.id?.title
    // );
    let partyLedgerlist = form.getFieldValue(["partyLedger"]);
    const currentPartyLedgerIndexData = partyLedgerlist[index];

    // console.log("currentPartyLedgerIndexData", currentPartyLedgerIndexData);

    form.setFieldValue(["partyLedger", index], {
      ...currentPartyLedgerIndexData,
      amount: currentPartyLedgerIndexData?.ref.reduce(
        (acc: number, curr: any) => {
          return (acc += Utils.getNumber(curr?.value));
        },
        0
      ),
    });

    handleAllLedgerValue();

    return 0;
  };

  const handleAllLedgerValue = () => {
    let partyLedgerlist = form.getFieldValue(["partyLedger"]);
    // console.log("partyLedgerlist", partyLedgerlist);

    const x = partyLedgerlist.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.amount));
    }, 0);

    // console.log("ccccccccccccccc.c.c.c.c.", x);

    setTotalPaymentIn(x);
    return x;
  };

  const onChangeSetPartyRef = async (
    partyIndex: number,
    refIndex: number,
    partyRefId: string
  ) => {
    console.log("ref.....onChangeSetPartyRef.", selectPartyRef);
    const x = partyRef[partyIndex].find((x: any) => x.parentRef === partyRefId);
    // console.log("data........", x);

    if (paymentId) {
      form.setFieldValue(["partyLedger", partyIndex, "ref", refIndex], {
        ...x,
        value: x.value ?? 0.0,
        dueAmount: x?.dueAmount,
      });
    } else {
      const partyIndexAmount = Utils.getNumber(
        form.getFieldValue(["partyLedger", partyIndex, "amount"])
      );
      const remainingRefAmount = Utils.getNumber(
        form
          .getFieldValue(["partyLedger", partyIndex, "ref"])
          .slice(1)
          .filter((_: any, idx: number) => idx !== refIndex)
          .reduce((acc: number, curr: any) => {
            return (acc += Utils.getNumber(curr?.value));
          }, 0)
      );
      let dueAmount;
      if (partyIndexAmount - (remainingRefAmount + x.dueAmount) < 0) {
        dueAmount = partyIndexAmount - remainingRefAmount;
      } else {
        dueAmount = x.dueAmount;
      }
      form.setFieldValue(["partyLedger", partyIndex, "ref", refIndex], {
        ...x,
        value: dueAmount,
      });
    }

    setSelectPartyRef((prevState: any[]) => {
      const updatedSelectedOptions: any[] = [...prevState];
      console.log("setSelectPartyRef.....", updatedSelectedOptions);
      updatedSelectedOptions[partyIndex] = [...(prevState[partyIndex] || [])];
      updatedSelectedOptions[partyIndex][refIndex] = partyRefId;
      return updatedSelectedOptions;
    });

    //  setSelectedPartyOption((prevState) => {
    //    const updatedSelectedOptions = [...prevState];
    //    updatedSelectedOptions[i] = v?.id;
    //    return updatedSelectedOptions;
    //  });

    onChangeRefAmountSet(x.dueAmount, partyIndex, false);
  };

  const onChangeRefAmountSet = (
    value: string,
    partyIndex: number,
    isParty: boolean,
    refIndex?: number
  ) => {
    if (refIndex) {
      setSelectPartyRef((prevDataArray) => {
        const newArray = [...prevDataArray];
        newArray[partyIndex] = [...(prevDataArray[partyIndex] || [])];
        newArray[partyIndex].splice(refIndex, 1);
        return newArray;
      });
    }
    console.log("ref..........", selectPartyRef, partyIndex, refIndex);

    if (isParty) {
      disabledAmountInputs[partyIndex] &&
        form.setFieldValue(
          ["partyLedger", partyIndex, "ref", 0, "value"],
          Utils.getNumber(value) -
            Utils.getNumber(
              form
                .getFieldValue(["partyLedger", partyIndex, "ref"])
                .slice(1)
                .reduce((acc: number, curr: any) => {
                  return (acc += Utils.getNumber(curr?.value));
                }, 0)
            )
        );
    } else {
      disabledAmountInputs[partyIndex] &&
        form.setFieldValue(
          ["partyLedger", partyIndex, "ref", 0, "value"],
          Utils.getNumber(
            form.getFieldValue(["partyLedger", partyIndex, "amount"])
          ) -
            Utils.getNumber(
              form
                .getFieldValue(["partyLedger", partyIndex, "ref"])
                .slice(1)
                .reduce((acc: number, curr: any) => {
                  return (acc += Utils.getNumber(curr?.value));
                }, 0)
            )
        );
    }
    // disabledAmountInputs[index1] &&
    //   form.setFieldValue(
    //     ["partyLedger", index1, "ref", 0, "value"],
    //     Utils.getNumber(form.getFieldValue(["partyLedger", index1, "amount"])) -
    //       Utils.getNumber(
    //         form
    //           .getFieldValue(["partyLedger", index1, "ref"])
    //           .slice(1)
    //           .reduce((acc: number, curr: any) => {
    //             return (acc += Utils.getNumber(curr?.value));
    //           }, 0)
    //       )
    //   );
  };

  // console.log(
  //   "selectedPartyOption",
  //   // selectedPartyOption,
  //   "partyRef",
  //   // partyRef,
  //   "selectPartyRef"
  //   // selectPartyRef
  // );

  const disAbledCheck = (
    currentBalance1: number,
    splitPathInVchType1: Voucher_Type,
    totalPaymentIn1: number
  ) => {
    return totalPaymentIn1 > currentBalance1 &&
      splitPathInVchType1 === Voucher_Type.Payment &&
      currentBalance1 <= 0
      ? true
      : false;
  };

  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  useEffect(() => {
    if (addLedgerResponse) {
      const exists = accountLedgerOption.some(
        (option) => option.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("effectiveLedger", {
          ...form.getFieldValue("effectiveLedger"),
          key: addLedgerResponse?.id,
          value: addLedgerResponse?.id,
          label: addLedgerResponse?.ledgerName,
        });
        // CustomerSelect(addLedgerResponse.id, true);
        const selectAccount = accountLedgerOption.find(
          (x) => x.id === addLedgerResponse?.id
        );
        setCurrentBalance(selectAccount?.balance);
        setAccountIsParty(selectAccount?.partyOrTax);
        setSelectedParty(addLedgerResponse?.id);
        setOpenLedgerDrawer({
          ...openLedgerDrawer,
          id: addLedgerResponse?.id,
        });
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountLedgerOption]);

  useEffect(() => {
    try {
      if (addLedgerResponse) {
        const exists = partyLedgerOption.some(
          (option: any) => option.id === addLedgerResponse.id
        );
        if (exists) {
          let partyLedger = [...(form.getFieldValue("partyLedger") || [])];
          partyLedger.pop();
          partyLedger.push(
            {
              amount: 0.0,
              id: {
                label: addLedgerResponse?.ledgerName,
                value: addLedgerResponse.id,
                key: addLedgerResponse.id,
              },
            },
            {}
          );
          const index = partyLedger.findIndex(
            (ledgerArray) => ledgerArray?.id?.value === addLedgerResponse.id
          );
          form.setFieldValue("partyLedger", partyLedger);
          partySelect(
            addLedgerResponse.id,
            index,
            addLedgerResponse?.ledgerName
          );
          onClickSelectPartyLedger(addLedgerResponse.id, index);
          setAddLedgerResponse(null);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyLedgerOption]);

  const shortcuts = [
    {
      key: "ENTER",
      modifiers: ["ctrlKey"] as ModifierKey[], // Ensure modifiers are typed correctly
      callback: () => save(),
    },
  ];
  useMultipleKeyboardShortcuts(shortcuts);

  console.log(
    "location?.state?.BankTransactionData?.moneyOut",
    location?.state?.BankTransactionData.moneyOut,
    totalPaymentIn
  );

  const saveDisable = (isDisabled: boolean) => {
    var x: any = "";
    if (
      location.state?.ledgerId &&
      splitPathInVchType === Voucher_Type.Payment
    ) {
      x =
        totalPaymentIn >
          currentBalance +
            (editPayment.id === openLedgerDrawer.id
              ? editPayment.balance
              : 0) ||
        totalPaymentIn !== location?.state?.BankTransactionData?.moneyOut
          ? `Insufficient balance & Amount must be  ${location?.state?.BankTransactionData?.moneyOut}`
          : "";
    } else if (location.state?.ledgerId) {
      x =
        totalPaymentIn !==
        (location?.state?.BankTransactionData?.moneyOut ||
          location?.state?.BankTransactionData?.moneyIn)
          ? `$Amount must be  ${location?.state?.BankTransactionData?.moneyOut}`
          : "";
    } else if (splitPathInVchType === Voucher_Type.Payment) {
      x =
        totalPaymentIn >
        currentBalance +
          (editPayment.id === openLedgerDrawer.id ? editPayment.balance : 0)
          ? "Insufficient balance."
          : "";
    }

    if (isDisabled) {
      if (x !== "") return true;
      else return false;
    } else return x;
  };

  return (
    <>
      <Spin indicator={antIcon} spinning={loading}>
        <Form
          form={form}
          name="paymentIn"
          autoComplete="off"
          initialValues={{
            partyLedger: [{}],
            date: dayjs(),
          }}
        >
          <Row justify="space-between">
            <Col span={8} className="dashedBoxStyle">
              <Row gutter={[14, 10]}>
                <Col span={14}>
                  <Row justify="space-between">
                    <Col className="themeColor fw-500">Account</Col>
                    {selectedParty && (
                      <Col className="fw-500">
                        <Tooltip title="View Account">
                          <EyeOutlined
                            onClick={() =>
                              setOpenLedgerDrawer({
                                ...openLedgerDrawer,
                                open: true,
                                id: selectedParty,
                              })
                            }
                          />
                        </Tooltip>
                      </Col>
                    )}
                  </Row>

                  <Form.Item
                    name="effectiveLedger"
                    className="m-0"
                    rules={[{ required: true, message: "Select a Account" }]}
                  >
                    <Select
                      labelInValue
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select Account"
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      ref={inputRef}
                      disabled={location.state?.ledgerId ? true : false}
                      // showArrow={false}
                      notFoundContent={null}
                      onChange={(e) => {
                        const selectAccount = accountLedgerOption.find(
                          (x) => x.id === e?.value
                        );
                        // setCurrentBalance(
                        //   Math.abs(selectAccount?.balance) +
                        //     (Utils.getNumber(selectAccount?.balance) === 0
                        //       ? ""
                        //       : Utils.getNumber(selectAccount?.balance) > 0
                        //       ? " Dr"
                        //       : " Cr")
                        // );
                        setCurrentBalance(selectAccount?.balance);

                        setAccountIsParty(selectAccount?.partyOrTax);
                        setSelectedParty(e?.value);
                        setOpenLedgerDrawer({
                          ...openLedgerDrawer,
                          id: e?.value,
                        });
                      }}
                      // labelInValue
                      // onSearch={(value: string) => setSearchCustomer(value)}
                      // onSelect={(value: any) => CustomerSelect(value?.value)}
                      options={accountLedgerOption?.map((value: any) => ({
                        value: value?.id,
                        label: value?.ledgerName.concat(
                          value?.aliasName
                            ? `${" (" + value?.aliasName + ")"}`
                            : ""
                        ),
                        // title: value?.balance,
                      }))}
                      dropdownRender={(customerOptions) => (
                        <>
                          {customerOptions}
                          <Divider style={{ margin: "5px 0px" }} />
                          <Button
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                            type="link"
                            onClick={() => {
                              setOpenLedgerDrawer({
                                ...openLedgerDrawer,
                                open: true,
                                id: "",
                              });
                              setOpenModel({
                                type: "addLedger",
                                param: {
                                  open: true,
                                },
                              });
                              // setEditParty({ id: "" });
                            }}
                          >
                            New Ledger
                          </Button>
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <span className="themeColor fw-500">Date</span>
                  <Form.Item
                    name="date"
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: "Select Date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      disabled={location.state?.ledgerId ? true : false}
                      onChange={(val) => {
                        setDateFiled(dayjs(val).toISOString());
                      }}
                      // disabledDate={(current) =>
                      //   current && current > dayjs().endOf("day")
                      // }
                    />
                  </Form.Item>
                </Col>

                <Col span={14}>
                  <span className="themeColor fw-500">Current Balance</span>
                  {/* <Form.Item name="account" className="m-0"> */}
                  <Input
                    disabled
                    value={
                      Math.abs(currentBalance) +
                      (Utils.getNumber(currentBalance) === 0
                        ? ""
                        : Utils.getNumber(currentBalance) > 0
                        ? " Dr"
                        : " Cr")
                    }
                  />
                  {/* </Form.Item> */}
                </Col>

                <Col span={10}>
                  <span className="themeColor fw-500">
                    {splitPathInVchType === Voucher_Type.Receipt
                      ? "Receipt No."
                      : " Payment No."}
                  </span>
                  <Form.Item
                    name="number"
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: "Please enter No.",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={14}>
                  <span className="themeColor fw-500">Reference</span>
                  <Form.Item name="payRecReference">
                    <Input />
                  </Form.Item>
                </Col>

                {location.state?.ledgerId && (
                  <>
                    <Col span={10}>
                      <span className="themeColor fw-500">Amount</span>
                      <Form.Item
                        name="bankEntryAmount"
                        className="m-0"
                        rules={[
                          {
                            required: true,
                            message: "Please enter No.",
                          },
                        ]}
                      >
                        <Input
                          disabled
                          style={{
                            backgroundColor: "#e7f4f5",
                            border: "1px solid #1890ff",
                            borderRadius: "5px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </Col>

            <Col span={4}>
              <div className="fs-35 fw-400 themeColor textEnd">
                {splitPathInVchType === Voucher_Type.Receipt
                  ? companySettings?.setting?.receipt?.title
                  : companySettings?.setting?.payment?.title}
                <Dropdown
                  menu={{
                    items:
                      splitPathInVchType === Voucher_Type?.Receipt
                        ? SalesDropdownItems()
                        : PurchaseDropdownItems(),
                    selectable: true,
                    defaultSelectedKeys: [
                      location?.pathname?.split("/")[5] === "addReceipt" ||
                      "editReceipt"
                        ? "2"
                        : "-1",
                    ],
                  }}
                  trigger={["click"]}
                  autoFocus={true}
                >
                  <Typography.Link>
                    <Space>
                      <DownOutlined
                        style={{
                          fontSize: "12px",
                          color: "black",
                          padding: "0px 0px 10px 10px",
                        }}
                      />
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </div>
            </Col>
          </Row>

          <div style={{ overflowY: "auto" }}>
            <table
              className="AddPageTabelDesign dashedBorderTop"
              style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "5%" }} className="textCenter">
                    S No.
                  </th>

                  <th
                    style={{
                      width: "61%",
                      borderLeft: "1px solid rgb(240, 245, 255)",
                    }}
                    className="textStart ps-6"
                  >
                    Name
                  </th>

                  <th style={{ width: "30%" }} className="textStart ps-6">
                    Amount
                  </th>
                </tr>
              </thead>

              <tbody>
                <Form.List name="partyLedger">
                  {(fields, { add, remove, move }) => {
                    selectRefIndex.current = fields.map(
                      (_, index) =>
                        selectRefIndex.current[index] || React.createRef()
                    );
                    return (
                      <>
                        {confirmation && (
                          <ConfirmationModal
                            open={confirmation}
                            text={`Do you really want to delete this item "${form.getFieldValue(
                              ["items"]
                            )}" ?`}
                            onNo={() => setConfirmation(false)}
                            onYes={() => {
                              remove(deleteItemList.name);
                              setConfirmation(false);
                              handleAllLedgerValue();
                              setItemLength(fields.length - 2);

                              setPartyRef((prev) => {
                                const allPrevArray = [...prev];
                                // console.log(
                                //   "allPrevArray----------",
                                //   allPrevArray,
                                //   deleteItemList.index
                                // );
                                allPrevArray.splice(deleteItemList.index, 1);
                                return allPrevArray;
                              });

                              setSelectPartyRef((prev: any[]) => {
                                const allPrevReference = [...prev];
                                allPrevReference.splice(
                                  deleteItemList.index,
                                  1
                                );
                                return allPrevReference;
                              });

                              setDisabledAmountInputs((prev) => {
                                const newDisabledInputs = [...prev];
                                // console.log(
                                //   "newDisabledInputs",
                                //   newDisabledInputs
                                // );
                                newDisabledInputs.splice(
                                  deleteItemList.index,
                                  1
                                );
                                return newDisabledInputs;
                              });

                              setSelectedPartyOption((prev) => {
                                const newDisabledInputs = [...prev];
                                newDisabledInputs.splice(
                                  deleteItemList.index,
                                  1
                                );
                                return newDisabledInputs;
                              });
                            }}
                          />
                        )}
                        {fields.map(({ key, name, ...restField }, index1) => {
                          const isInputDisabled = disabledAmountInputs[index1];
                          return (
                            <>
                              <tr key={key}>
                                <td>
                                  {fields.length !== index1 + 1 && (
                                    <div className="ps-10">{index1 + 1}.</div>
                                  )}
                                </td>

                                <td>
                                  <Form.Item
                                    name={[name, "id"]}
                                    className="m-0"
                                    {...restField}
                                  >
                                    <Select
                                      labelInValue
                                      showSearch
                                      placeholder="Search to items"
                                      optionFilterProp="children"
                                      popupMatchSelectWidth={false}
                                      style={{
                                        width: "100%",
                                      }}
                                      dropdownStyle={{ width: "250px" }}
                                      placement="bottomRight"
                                      // onSearch={(value: string) =>
                                      //   setSearchItem(value)
                                      // }

                                      options={partyLedgerOption
                                        .filter(
                                          (option) =>
                                            !selectedPartyOption.includes(
                                              option.id
                                            )
                                        )
                                        .map((option: any) => ({
                                          value: option?.id,
                                          label: option?.ledgerName.concat(
                                            option?.aliasName
                                              ? `${
                                                  " (" + option?.aliasName + ")"
                                                }`
                                              : ""
                                          ),
                                          title: option?.partyOrTax,
                                        }))}
                                      filterOption={(input, option) =>
                                        (option?.label?.toString() ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      onChange={(e: any) => {
                                        if (fields.length === index1 + 1) add();
                                        partySelect(e.value, index1, e.title);
                                        onClickSelectPartyLedger(
                                          e.value,
                                          index1
                                        );
                                      }}
                                      dropdownRender={(customerOptions) => (
                                        <>
                                          {customerOptions}
                                          <Divider
                                            style={{ margin: "5px 0px" }}
                                          />
                                          <Button
                                            style={{ width: "100%" }}
                                            icon={<PlusOutlined />}
                                            type="link"
                                            onClick={() => {
                                              setOpenModel({
                                                type: "addLedger",
                                                param: {
                                                  open: true,
                                                },
                                              });
                                              // setEditParty({ id: "" });
                                            }}
                                          >
                                            New Ledger
                                          </Button>
                                        </>
                                      )}
                                    />
                                  </Form.Item>
                                </td>

                                <td>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "amount"]}
                                    rules={[
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (
                                            fields.length !== index1 + 1 &&
                                            isInputDisabled
                                          ) {
                                            if (
                                              Utils.getNumber(
                                                getFieldValue([
                                                  "partyLedger",
                                                  index1,
                                                  "amount",
                                                ])
                                              ) !==
                                              Utils.getNumber(
                                                getFieldValue([
                                                  "partyLedger",
                                                  index1,
                                                  "ref",
                                                ]).reduce(
                                                  (acc: number, curr: any) => {
                                                    return (acc +=
                                                      Utils.getNumber(
                                                        curr?.value
                                                      ));
                                                  },
                                                  0
                                                )
                                              )
                                            ) {
                                              return Promise.reject(
                                                new Error(
                                                  "Reference amount do not match!"
                                                )
                                              );
                                            } else {
                                              return Promise.resolve();
                                            }
                                          } else {
                                            return Promise.resolve();
                                          }
                                        },
                                      }),
                                    ]}
                                  >
                                    <InputNumber
                                      defaultValue={0.0}
                                      ref={selectRefIndex?.current[index1]}
                                      controls={false}
                                      style={{ width: "100%" }}
                                      step={0.01}
                                      min={0}
                                      // disabled={isInputDisabled}
                                      // prefix="&#8377;"
                                      onBlur={(e) => {
                                        handleAllLedgerValue();
                                        // handelAmountChange(
                                        //   Utils.getNumber(e.target.value),
                                        //   index
                                        // );
                                        // getAllocatedAmount(Utils.getNumber(e.target.value));
                                      }}
                                      // onKeyPress={(e) =>
                                      //   !/[0-9]/.test(e.key) &&
                                      //   e.preventDefault()
                                      // }
                                      onChange={(e: any) => {
                                        onChangeRefAmountSet(e, index1, true);
                                      }}
                                    />
                                  </Form.Item>
                                </td>

                                <td style={{ width: 25 }}>
                                  {fields.length !== index1 + 1 && (
                                    <img
                                      className="cursor mx-1"
                                      onClick={() => {
                                        setDeleteItemList({
                                          name: name,
                                          index: index1,
                                        });
                                        setConfirmation(true);
                                      }}
                                      src={deleteImg}
                                      alt="Delete"
                                    />
                                  )}
                                </td>
                              </tr>

                              {/* Nest Form.List */}
                              <tr
                                style={{
                                  display: !isInputDisabled
                                    ? "none"
                                    : "contents",
                                }}
                              >
                                <td colSpan={2}>
                                  <table style={{ width: "100%" }}>
                                    <tbody>
                                      <Form.Item className="m-0" name="ref">
                                        <Form.List name={[name, "ref"]}>
                                          {(
                                            subFields,
                                            { add, remove, move }
                                          ) => {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                {subFields.map(
                                                  (subField, index) => {
                                                    const isInputDisabled1 =
                                                      subFields.length !==
                                                      index + 1
                                                        ? true
                                                        : false;
                                                    return (
                                                      <tr
                                                        style={{
                                                          paddingLeft: "105px",
                                                        }}
                                                        key={index}
                                                      >
                                                        <td>
                                                          {index === 0 ? (
                                                            <Tooltip
                                                              title="New Reference"
                                                              placement="left"
                                                              color="#389e0d"
                                                            >
                                                              <Avatar
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  verticalAlign:
                                                                    "middle",
                                                                  color:
                                                                    "#389e0d",
                                                                  backgroundColor:
                                                                    "#f6ffed",
                                                                  borderColor:
                                                                    "#b7eb8f",
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                                size="small"
                                                              >
                                                                N
                                                              </Avatar>
                                                            </Tooltip>
                                                          ) : (
                                                            <Tooltip
                                                              title="Old Reference"
                                                              placement="left"
                                                              color="#08979C"
                                                            >
                                                              <Avatar
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  verticalAlign:
                                                                    "middle",
                                                                  color:
                                                                    "#08979C",
                                                                  backgroundColor:
                                                                    "#E6FFFB",
                                                                  borderColor:
                                                                    "rgb(127 231 235)",
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                                size="small"
                                                              >
                                                                A
                                                              </Avatar>
                                                            </Tooltip>
                                                          )}
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "30%",
                                                          }}
                                                        >
                                                          <Form.Item
                                                            className="m-0"
                                                            name={[
                                                              subField.name,
                                                              "name",
                                                            ]}
                                                            rules={[
                                                              {
                                                                required:
                                                                  index !== 0 &&
                                                                  subFields.length !==
                                                                    index + 1 &&
                                                                  form.getFieldValue(
                                                                    [
                                                                      "partyLedger",
                                                                      index1,
                                                                      "ref",
                                                                      index,
                                                                      "value",
                                                                    ]
                                                                  ) > 0
                                                                    ? true
                                                                    : false,
                                                                message:
                                                                  "Select a invoice",
                                                              },
                                                            ]}
                                                          >
                                                            <Select
                                                              disabled={
                                                                index === 0 &&
                                                                true
                                                              }
                                                              labelInValue
                                                              showSearch
                                                              optionFilterProp="children"
                                                              placeholder="Select Account"
                                                              filterOption={(
                                                                input,
                                                                option
                                                              ) =>
                                                                (
                                                                  option?.label?.toString() ??
                                                                  ""
                                                                )
                                                                  .toLowerCase()
                                                                  .includes(
                                                                    input.toLowerCase()
                                                                  )
                                                              }
                                                              // showArrow={false}
                                                              notFoundContent={
                                                                null
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  subFields.length ===
                                                                  index + 1
                                                                )
                                                                  add();

                                                                onChangeSetPartyRef(
                                                                  index1,
                                                                  index,
                                                                  e?.value
                                                                );
                                                              }}
                                                              options={partyRef[
                                                                index1
                                                              ]
                                                                ?.filter(
                                                                  (
                                                                    option: any
                                                                  ) =>
                                                                    !selectPartyRef[
                                                                      index1
                                                                    ]?.includes(
                                                                      option.parentRef
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    value: any
                                                                  ) => ({
                                                                    value:
                                                                      value?.parentRef,
                                                                    label:
                                                                      value?.name,
                                                                    // title: value?.balance,
                                                                  })
                                                                )}
                                                            />
                                                          </Form.Item>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "20%",
                                                          }}
                                                        >
                                                          <Form.Item
                                                            className="m-0"
                                                            name={[
                                                              subField.name,
                                                              "dueAmount",
                                                            ]}
                                                          >
                                                            <InputNumber
                                                              step={0.01}
                                                              disabled
                                                              controls={false}
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                              placeholder="Amount"
                                                            />
                                                          </Form.Item>
                                                        </td>

                                                        <td
                                                          style={{
                                                            width: "40%",
                                                          }}
                                                        >
                                                          <Form.Item
                                                            className="m-0"
                                                            name={[
                                                              subField.name,
                                                              "value",
                                                            ]}
                                                            rules={[
                                                              {
                                                                required:
                                                                  index !== 0 &&
                                                                  form.getFieldValue(
                                                                    [
                                                                      "partyLedger",
                                                                      index1,
                                                                      "ref",
                                                                      index,
                                                                      "name",
                                                                    ]
                                                                  )
                                                                    ? true
                                                                    : false,
                                                                message:
                                                                  "Enter a amount",
                                                              },
                                                              {
                                                                validator: (
                                                                  _,
                                                                  value
                                                                ) => {
                                                                  if (
                                                                    value < 0
                                                                  ) {
                                                                    return Promise.reject(
                                                                      "Number must be non-negative"
                                                                    );
                                                                  }
                                                                  return Promise.resolve();
                                                                },
                                                              },
                                                            ]}
                                                          >
                                                            <InputNumber
                                                              step={0.01}
                                                              min={0.0}
                                                              max={
                                                                index !== 0
                                                                  ? paymentId
                                                                    ? Math.round(
                                                                        (form.getFieldValue(
                                                                          [
                                                                            "partyLedger",
                                                                            index1,
                                                                            "ref",
                                                                            index,
                                                                            "dueAmount",
                                                                          ]
                                                                        ) +
                                                                          form.getFieldValue(
                                                                            [
                                                                              "partyLedger",
                                                                              index1,
                                                                              "ref",
                                                                              index,
                                                                              "value",
                                                                            ]
                                                                          )) *
                                                                          100
                                                                      ) / 100
                                                                    : Math.round(
                                                                        form.getFieldValue(
                                                                          [
                                                                            "partyLedger",
                                                                            index1,
                                                                            "ref",
                                                                            index,
                                                                            "dueAmount",
                                                                          ]
                                                                        ) * 100
                                                                      ) / 100
                                                                  : Infinity
                                                              }
                                                              controls={false}
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                              placeholder="amount"
                                                              // onKeyPress={(e) =>
                                                              //   !/[0-9]/.test(
                                                              //     e.key
                                                              //   ) &&
                                                              //   e.preventDefault()
                                                              // }
                                                              onBlur={() => {
                                                                if (
                                                                  subFields.length ===
                                                                  index + 1
                                                                )
                                                                  add();

                                                                // console.log(
                                                                //   "onChage",
                                                                //   index,
                                                                //   form.getFieldValue(
                                                                //     [
                                                                //       "partyLedger",
                                                                //       index1,
                                                                //       "ref",
                                                                //       index,
                                                                //       "name",
                                                                //     ]
                                                                //   )
                                                                // )
                                                              }}
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                // console.log(
                                                                //   "x..",
                                                                //   index1,
                                                                //   index,
                                                                //   Math.round(
                                                                //     partyRef[
                                                                //       index1
                                                                //     ][index]
                                                                //       ?.dueAmount
                                                                //   )
                                                                // );

                                                                onChangeRefAmountSet(
                                                                  e,
                                                                  index1,
                                                                  false
                                                                );

                                                                if (
                                                                  Utils.getNumber(
                                                                    form.getFieldValue(
                                                                      [
                                                                        "partyLedger",
                                                                        index1,
                                                                        "amount",
                                                                      ]
                                                                    )
                                                                  ) !==
                                                                  Utils.getNumber(
                                                                    form
                                                                      .getFieldValue(
                                                                        [
                                                                          "partyLedger",
                                                                          index1,
                                                                          "ref",
                                                                        ]
                                                                      )
                                                                      .reduce(
                                                                        (
                                                                          acc: number,
                                                                          curr: any
                                                                        ) => {
                                                                          return (acc +=
                                                                            Utils.getNumber(
                                                                              curr?.value
                                                                            ));
                                                                        },
                                                                        0
                                                                      )
                                                                  )
                                                                ) {
                                                                  console.log(
                                                                    "error"
                                                                  );
                                                                  form.validateFields(
                                                                    [
                                                                      "partyLedger",
                                                                      index1,
                                                                      "amount",
                                                                    ]
                                                                  );
                                                                  return Promise.resolve();
                                                                }
                                                              }}
                                                              // onBlur={() =>
                                                              //   handleLedgerNetAmount(
                                                              //     index1
                                                              //   )subField
                                                              // }
                                                            />
                                                          </Form.Item>
                                                        </td>
                                                        <td
                                                        // style={{ width: "20%" }}
                                                        >
                                                          <td
                                                            style={{
                                                              width: 25,
                                                            }}
                                                          >
                                                            {index !== 0 &&
                                                              subFields.length !==
                                                                index + 1 && (
                                                                <CloseOutlined
                                                                  onClick={() => {
                                                                    remove(
                                                                      subField.name
                                                                    );

                                                                    onChangeRefAmountSet(
                                                                      "0",
                                                                      index1,
                                                                      false,
                                                                      index
                                                                    );
                                                                  }}
                                                                />
                                                              )}
                                                          </td>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            );
                                          }}
                                        </Form.List>
                                      </Form.Item>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    );
                  }}
                </Form.List>
              </tbody>
              {/* {itemLength > 0 && (
                <tfoot>
                  <tr>
                    <th colSpan={5} className="textEnd pe-14">
                      Total
                    </th>
                    <th colSpan={2} className="textEnd pe-14">
                      {footerTotal.qty}
                    </th>
                    <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.unitPrice)}
                    </th>
                    <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                    </th>
                    {isGSTEnabled && (
                      <th colSpan={2} className="textEnd pe-12">
                        ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                      </th>
                    )}
                    <th className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.amount)}
                    </th>
                  </tr>
                </tfoot>
              )} */}
            </table>
          </div>

          <Row className="pt-10">
            <Col lg={12} style={{ paddingTop: "15px" }}>
              <Form.Item name="narration" className="m-0">
                <TextArea
                  placeholder="Write Narration"
                  style={{ width: "450px" }}
                />
              </Form.Item>
            </Col>
            <Col lg={6} offset={5}>
              <Row gutter={[0, 8]}>
                {/* <Col lg={12}>Bank Amount Set</Col>

                <Col lg={12} className="textEnd">
                  ₹{" "}
                  {Utils.getFormattedNumber(
                    location?.state?.BankTransactionData?.moneyIn ||
                      location?.state?.BankTransactionData?.moneyOut
                  )}
                </Col> */}

                <Col lg={12} style={{ fontWeight: 600, fontSize: "16px" }}>
                  Total
                  {splitPathInVchType === Voucher_Type.Receipt
                    ? " Receipt "
                    : " Payments "}
                  Amount
                </Col>

                <Col
                  lg={12}
                  className="textEnd"
                  style={{ fontWeight: 600, fontSize: "16px" }}
                >
                  ₹ {Utils.getFormattedNumber(totalPaymentIn)}
                </Col>
              </Row>

              <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-5"
              />

              <Row justify="end" className="mt-10">
                <Col>
                  <Button
                    onClick={() =>
                      location.state?.ledgerId
                        ? navigate(
                            `/accounts/clients/${currentClient?.id}/banking/bankTransactionsDetails/${location.state?.bankEntriesId}`,
                            {
                              state: {
                                ledgerId: location.state?.ledgerId,
                                bankImportIndex: null,
                              },
                            }
                          )
                        : navigate(-1)
                    }
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className="ps-14">
                  <Form.Item>
                    <Tooltip
                      title={
                        saveDisable(false)

                        // totalPaymentIn >
                        //   currentBalance +
                        //     (editPayment.id === openLedgerDrawer.id
                        //       ? editPayment.balance
                        //       : 0) &&
                        // splitPathInVchType === Voucher_Type.Payment
                        //   ? "Current Balance less than 0"
                        //   : ""
                      }
                      placement="top"
                      color="red"
                    >
                      <Button
                        type="primary"
                        onClick={() => save()}
                        loading={saveButtonLoading}
                        disabled={saveDisable(true)}
                        // totalPaymentIn >
                        //   currentBalance +
                        //     (editPayment.id === openLedgerDrawer.id
                        //       ? editPayment.balance
                        //       : 0) &&
                        // splitPathInVchType === Voucher_Type.Payment
                        //   ? true
                        //   : false
                      >
                        {paymentId ? "Update" : "Save"}
                      </Button>
                    </Tooltip>
                  </Form.Item>
                </Col>

                {/* <Col className="ps-14">
                  <Form.Item>
                    <Button
                      // loading={saveNewLoading}
                      // onClick={() => {
                      //   addDeliveryChallan(true);
                      // }}
                      type="primary"
                    >
                      {paymentId ? "Update & New" : "Save & New"}
                    </Button>
                  </Form.Item>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
      {openLedgerDrawer.open && (
        <AddLedger
          openDrawer={openLedgerDrawer.open}
          id={openLedgerDrawer?.id}
          setOpenDrawer={() => setOpenLedgerDrawer({ open: false, id: "" })}
        />
      )}
      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setTrigger(!trigger)}
          setAddLedgerResponse={setAddLedgerResponse}
          // setRefresh={setRefresh}
        />
      )}
    </>
  );
};


export default AddPaymentInNew;