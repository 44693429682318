import React from "react";
import {
  DownloadOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Row, DatePicker, Card, Spin } from "antd";
import { useClient } from "../../Context/ClientContext";
import { NavLink, useLocation } from "react-router-dom";
import { dayjs } from "../../../Utilities/dayjs";
import { ReportSettings } from "../../Common/CAPComponents";
import { ITrialBalanceSettings } from "./Ageing";
import ReportsServiceNew, {
  Statements,
} from "../../Services/ReportsServiceNew";
import { useTheme } from "../../Context/ThemeContext";
import { Utils } from "../../../Utilities/Utils";

const ProfitAndLossNew: React.FC<{ type: Statements }> = (props) => {
  const { RangePicker } = DatePicker;
  const { themeData } = useTheme();
  const location = useLocation();
  const { currentClient, companySettings } = useClient();
  const [shouldRefresh, setShouldRefresh] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any[]>();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  console.log("Company Setting :", companySettings);

  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [settings, setSettings] = React.useState<
    ITrialBalanceSettings | undefined
  >();
  // let SelStatement = props.type;

  React.useEffect(() => {
    setSettings(companySettings?.setting?.tbSettings);
  }, [companySettings, shouldRefresh]);

  React.useEffect(() => {
    getProftAndLossList(currentClient?.id!, props.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    filterDate.from,
    filterDate.to,
    props.type,
    shouldRefresh,
  ]);

  const getProftAndLossList = async (
    clientId: string,
    statements: Statements
  ) => {
    if (statements && clientId) {
      setLoading(true);
      await ReportsServiceNew.getFinalStatement(clientId, statements)
        .then((res: any) => {
          setData(res);
          setLoading(false);
        })
        .catch((err: any) => {
          console.error("err..", err.message);
          setLoading(false);
        });
    }
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <div style={{ width: "80%" }} className="m-auto">
        <Row>
          {/* <Button onClick={() => console.log("hello")}>Abc</Button> */}
          <Col lg={12}>
            <>
              <div className="themeColor fs-20 fw-600">
                {currentClient?.businessName}
              </div>
              <p style={{ color: "gray" }}>
                {location.pathname.split("/")[4] === "profitAndLossnew"
                  ? "Statement of Profit and Loss (for the year ended"
                  : "Balance Sheet (as on"}
                {" " +
                  dayjs(companySettings?.period?.to).format("DD/MM/YYYY") +
                  ")"}
              </p>
            </>
          </Col>
          <Col lg={12}>
            <Row justify={"end"} gutter={[8, 0]}>
              <Col>
                <RangePicker
                  defaultValue={[
                    dayjs(filterDate?.from, "DD/MM/YYYY"),
                    dayjs(filterDate?.to, "DD/MM/YYYY"),
                  ]}
                  allowClear={false}
                  format="DD/MM/YYYY"
                  style={{ width: "220px", border: "1px solid #f1f3ff" }}
                  onChange={(e: any) => {
                    e === null
                      ? setFilterDate({})
                      : setFilterDate({
                          ...filterDate,
                          from: dayjs(e[0]).format("DD/MM/YYYY"),
                          to: dayjs(e[1]).format("DD/MM/YYYY"),
                        });
                  }}
                  presets={[
                    {
                      label: "Today",
                      value: [dayjs().add(0, "d"), dayjs()],
                    },
                    {
                      label: "Last 7 Days",
                      value: [dayjs().add(-7, "d"), dayjs()],
                    },
                    {
                      label: "Last 15 Days",
                      value: [dayjs().add(-15, "d"), dayjs()],
                    },
                    {
                      label: "This Month",
                      value: [dayjs().startOf("month"), dayjs()],
                    },
                    {
                      label: "Last Month",
                      value: [
                        dayjs().date(0).startOf("month"),
                        dayjs().date(0),
                      ],
                    },
                    {
                      label: "Quarter 1",
                      value: [
                        dayjs().month(3).startOf("month"),
                        dayjs().month(5).endOf("month"),
                      ],
                    },
                    {
                      label: "Quarter 2",
                      value: [
                        dayjs().month(6).startOf("month"),
                        dayjs().month(8).endOf("month"),
                      ],
                    },
                    {
                      label: "Quarter 3",
                      value: [
                        dayjs().month(9).startOf("month"),
                        dayjs().month(11).endOf("month"),
                      ],
                    },
                    {
                      label: "Quarter 4",
                      value: [
                        dayjs().month(0).startOf("month"),
                        dayjs().month(2).endOf("month"),
                      ],
                    },
                    {
                      label: "This Year",
                      value: [dayjs().month(0).startOf("month"), dayjs()],
                    },
                    {
                      label: "Last Year",
                      value: [
                        dayjs().month(-12).startOf("month"),
                        dayjs().month(-1).endOf("month"),
                      ],
                    },
                  ]}
                />
              </Col>
              <Col>
                <Button icon={<DownloadOutlined />}></Button>
              </Col>
              {/* <Col>
                <Button
                  onClick={() => setIsModalOpen(true)}
                  icon={<SettingOutlined />}
                ></Button>
              </Col> */}
              <Col>
                <Button
                  icon={<ReloadOutlined />}
                  disabled={loading}
                  onClick={() => setShouldRefresh(!shouldRefresh)}
                ></Button>
              </Col>
            </Row>
          </Col>
          <Divider style={{ margin: "0px" }} />
        </Row>
        <Row>
          <Col span={24}>
            <Spin
              indicator={antIcon}
              spinning={loading}
              style={{ width: "100%" }}
            >
              <table width="100%" className="ca-viewmodel-table">
                <thead>
                  <tr>
                    <th
                      rowSpan={2}
                      style={{
                        width: "90%",
                        paddingLeft: "10px",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      Particulars
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      {dayjs(companySettings?.period?.from).year().toString() +
                        "-" +
                        dayjs(companySettings?.period?.to)
                          .year()
                          .toString()
                          .substring(2)}
                    </th>
                  </tr>
                  <tr>
                    <th>{"Amount (₹)"}</th>
                  </tr>
                </thead>
                <thead></thead>
                <tbody>
                  {data?.map((reco: any) => {
                    return (
                      <>
                        {reco?.name !== "" && reco?.value !== "" ? (
                          <>
                            {" "}
                            <tr
                              style={{
                                lineHeight: "30px",
                                background: "#f2f8fc",
                              }}
                            >
                              <td
                                style={{
                                  width: "90%",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  color: "#543043",
                                }}
                                // align={
                                //   SelStatement === Statements.BalanceSheet
                                //     ? "right"
                                //     : "left"
                                // }
                              >
                                {reco?.name}
                              </td>
                              <td
                                align="right"
                                style={{
                                  width: "10%",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  color: "#543043",
                                }}
                              >
                                {reco?.value !== 0
                                  ? Utils.getFormattedNumber(reco?.value)
                                  : ""}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        {reco?.children ? (
                          <>
                            {reco?.children?.map((creco: any) => {
                              return (
                                <>
                                  <tr>
                                    <td
                                      style={{
                                        width: "90%",
                                        paddingLeft: "30px",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "#193e5e",
                                      }}
                                    >
                                      {creco?.name}
                                    </td>
                                    <td
                                      align="right"
                                      style={{
                                        width: "10%",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "#193e5e",
                                      }}
                                    >
                                      {Utils.getFormattedNumber(creco?.value)}
                                    </td>
                                  </tr>
                                  {creco?.children ? (
                                    <>
                                      {creco?.children?.map((greco: any) => {
                                        return (
                                          <tr>
                                            <td
                                              style={{
                                                width: "90%",
                                                paddingLeft: "60px",
                                              }}
                                            >
                                              <NavLink
                                                to={`/accounts/clients/${currentClient?.id}/master/group/${greco?.id}`}
                                              >
                                                {greco?.name}
                                              </NavLink>
                                            </td>
                                            <td
                                              align="right"
                                              style={{ width: "10%" }}
                                            >
                                              {Utils.getFormattedNumber(
                                                greco?.value
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </Spin>
          </Col>
        </Row>
      </div>

      {/* customize setting modal */}
      {isModalOpen && (
        <ReportSettings
          isOpen={isModalOpen}
          setOpen={setIsModalOpen}
          type="PnL"
          value={settings}
          refresh={shouldRefresh}
          setRefresh={setShouldRefresh}
        />
      )}
    </Card>
  );
};

export default ProfitAndLossNew;
