import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum AssetStatus {
  All,
  Active,
  Disposed,
}

class FixedAssetsServiceNew {
  route = "/v1/api/Acc_New_FixedAsset";

  // Get Initial data and edit by id
  getFixedAsset = (clientId: string, id?: string, isDisposed?: boolean) =>
    ApiUtility.get(
      `${this.route}/${clientId}/?${
        id ? `id=${id}&` : ""
      }isDisposed=${isDisposed}`
    );

  getAssetMaxNo = (companyId: string, voucherDate?: string) =>
    ApiUtility.get(
      `${this.route}/${companyId}/GetAssetMaxNo/?voucherDate=${voucherDate}`
    );

  // journalPost = (clientId: string, lastAccdate: string, params: any) =>
  //   ApiUtility.post(
  //     `${this.route}/${clientId}/Journal?lastAccdate=${lastAccdate}`,
  //     params
  //   );

  // rollBackJournal = (
  //   clientId: string,
  //   id: string,
  //   rollback: boolean,
  //   jrnlIds?: string[]
  // ) =>
  //   ApiUtility.get(
  //     `${this.route}/${clientId}/${id}/Rollback?rollback=${rollback}&${jrnlIds
  //       ?.map((id: any, i: number) => `jrnlIds=${id}`)
  //       .join("&")}`
  //   );
  //https://localhost:7142/v1/api/Acc_FixedAsset/22/11/Rollback?rollback=true&jrnlIds=33&jrnlIds=44

  PostFixedAssets = (clientId: string, params: any, id?: any) =>
    ApiUtility.post(`${this.route}/${clientId}${id ? "/" + id : ""}`, params);

  getFixedAssetsList = (
    companyId: string,
    start: number,
    length: number,
    sortCol: any,
    sortDir: string,
    search: string,
    status: AssetStatus
    // type?: string,
    // lastAccDate?: string,
    // isDispose?: boolean
  ) =>
    ApiUtility.getResult(`${this.route}/${companyId}/GetList`, {
      start,
      length,
      sortCol,
      sortDir,
      search,
      status,
      // type,
      // lastAccDate,
      // isDispose,
    });

  // deleteAsset = (clientId: string, id: string, reactivate: boolean) =>
  //   ApiUtility.post(
  //     `${this.route}/${clientId}/${id}/Delete?reactivate=${reactivate}`,
  //     ""
  //   );

  // searchAsset = (clientId: string, term?: string, length?: number) =>
  //   ApiUtility.get(`${this.route}/${clientId}/SearchAsset`, { term, length });

  postDispose = (clientId: string, assetId: string, params: any) =>
    ApiUtility.post(
      `${this.route}/${clientId}/Disposalentry/${assetId}`,
      params
    );

  // reActivateAsset = (clientId: string, id: string) =>
  //   ApiUtility.post(
  //     `${this.route}/${clientId}/${id}/Activate`,
  //     id
  //   );
}
export default new FixedAssetsServiceNew();
