import React, { useContext } from "react";
import {
  Button,
  Col,
  Row,
  Input,
  DatePicker,
  Table,
  Tooltip,
  Form,
  Select,
  Card,
  Skeleton,
  Space,
} from "antd";
import {
  DeleteTwoTone,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import "./Journals.css";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import "../../Common/Acc_Common.css";
//import JournalService from "../../Services/JournalService";
import VoucherServices, {
  VoucherFilterStatus,
  Voucher_Type,
} from "../../Services/VoucherServices";
import { useTheme } from "../../Context/ThemeContext";
import { dayjs } from "../../../Utilities/dayjs";
import ViewJournal from "./ViewJournal";
import { Utils } from "../../../Utilities/Utils";
import noteContext from "../../Context/StyleContext";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import { TransactionTypes } from "../../../Types/CommonConstants";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";

// export const TransactionTypeNames = [
//   "Unknown",
//   "Journal",
//   "Sales Invoice",
//   "Purchase Invoice",
//   "Credit Note",
//   "Purchase Credit Note",
//   "Receipt",
//   "Payment",
//   "Bad Debts",
//   "Fixed Asset",
//   "Depreciate",
//   "Dividend Accrual",
//   "Dividend Payment",
// ];

const JournalsTable: React.FC = () => {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<VoucherFilterStatus>(
    VoucherFilterStatus.Active
  );
  const [journalId, setJournalId] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [voucherDetailData, setVoucherDetailData] = React.useState<any>([]);
  //const [journalList, setJournalList] = React.useState<any[]>([]);
  const [searchJournal, setSearchJournal] = React.useState<string>("");
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [totAmount, setTotAmount] = React.useState<number>(0);
  const [deleteOrRevive, setDeleteOrRevive] = React.useState<any>({
    id: "",
    isRev: false,
  });
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });

  interface IJournalList {
    key: React.Key;
    id: string;
    number: number;
    ledgerName: string;
    date: any;
    amount: number;
    voucherStatus: number;
  }
  // interface DataType {
  //   id: string;
  //   key: string;
  //   date: string;
  //   journalno: string;
  //   reference: string;
  //   amount: string;
  //   type: number;
  // }

  const columns: ColumnsType<IJournalList> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "Date",
      width: "10%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Ref No.",
      dataIndex: "number",
      width: "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <>
            {record?.voucherStatus !== 1 ? (
              <>
                <span
                  style={{ color: "#1677FF", cursor: "pointer" }}
                  onClick={() => {
                    setIsOpen(true);
                    setJournalId(record?.id);
                    console.log(record?.id, "jrndata");
                  }}
                >
                  {record?.number}
                </span>
              </>
            ) : (
              <>
                <span style={{ color: "red" }}>{record?.number}</span>
              </>
            )}
          </>
        </Skeleton>
      ),
    },
    {
      title: "Account Name",
      dataIndex: "ledgerName",
      key: "account",
      // sorter: true,
      width: "25%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.ledgerName}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      // width: "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>₹ {Utils.getFormattedNumber(record?.amount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.amount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "icon2",
      align: "center",
      width:"10%",
      render: (_, record) => (
        <Skeleton
          active
          paragraph={{ rows: 1 }}
          loading={loading}
          title={false}
          className="cursor"
        >
          {record?.voucherStatus !== 1 ? (
            <>
              <Row gutter={13} justify={"center"}>
                <Col>
                  <Tooltip
                    title="Edit"
                    placement="top"
                    arrow={true}
                    color="#444653"
                  >
                    <EditOutlined
                      className="cursor"
                      onClick={() => {
                        navigate(
                          `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                        );
                      }}
                    />
                  </Tooltip>
                </Col>
                <Tooltip
                  title="Delete"
                  placement="top"
                  arrow={true}
                  color="red"
                >
                  <DeleteTwoTone
                    className="ca-delete-btn cursor"
                    twoToneColor="#ff879d"
                    onClick={() => {
                      setConfirmation(true);
                      setJournalId(record?.id);
                    }}
                  />
                </Tooltip>
              </Row>
            </>
          ) : (
            <>
              <Tooltip title="Revive Voucher">
                <SyncOutlined
                  style={{ color: "green" }}
                  onClick={() => {
                    navigate(
                      `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                    );
                  }}
                />
              </Tooltip>
            </>
          )}
        </Skeleton>
      ),
    },
  ];

  // if (status === "deleted") {
  //   columns.push({
  //     width: "25%",
  //     align: "left",
  //     render: (_, record) => (
  //       <Skeleton
  //         active
  //         loading={loading}
  //         paragraph={{ rows: 1 }}
  //         title={false}
  //         className="cursor"
  //       >
  //         <Tooltip title="Revive journal and its effects on calculations">
  //           <SyncOutlined
  //             className="ps-32"
  //             onClick={() => {
  //               setConfirmation(true);
  //               setJournalId(record?.id);
  //             }}
  //           />
  //         </Tooltip>
  //       </Skeleton>
  //     ),
  //   });
  // } else {
  //   columns.push(
  //     {
  //       title: "  ",
  //       align: "center",
  //       dataIndex: "editicon",
  //       width: "5%",
  //       render: (_, record) => (
  //         <Skeleton
  //           active
  //           loading={loading}
  //           paragraph={{ rows: 1 }}
  //           title={false}
  //           className="cursor"
  //         >
  //           <Tooltip
  //             title={
  //               record?.type === TransactionTypes.FixedAsset ||
  //               record?.type === TransactionTypes.Depreciate
  //                 ? `Editing is disabled as journal is posted from ${
  //                     TransactionTypeNames[record?.type]
  //                   }`
  //                 : "Edit"
  //             }
  //             placement="top"
  //             color={
  //               record?.type === TransactionTypes.FixedAsset ||
  //               record?.type === TransactionTypes.Depreciate
  //                 ? "gray"
  //                 : "#3D4998"
  //             }
  //           >
  //             <EditOutlined
  //               style={{
  //                 color:
  //                   record?.type === TransactionTypes.FixedAsset ||
  //                   record?.type === TransactionTypes.Depreciate
  //                     ? "rgb(216 207 207)"
  //                     : "#444653",
  //               }}
  //               className={
  //                 record?.type === TransactionTypes.FixedAsset ||
  //                 record?.type === TransactionTypes.Depreciate
  //                   ? ""
  //                   : "ca-edit-btn"
  //               }
  //               onClick={() => {
  //                 record?.type !== TransactionTypes.FixedAsset &&
  //                   record?.type !== TransactionTypes.Depreciate &&
  //                   navigate(
  //                     `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
  //                   );
  //               }}
  //             />
  //           </Tooltip>
  //         </Skeleton>
  //       ),
  //     },
  //     {
  //       title: "  ",
  //       dataIndex: "deleteicon",
  //       align: "left",
  //       width: "20%",
  //       render: (_, record) => (
  //         <Skeleton
  //           active
  //           loading={loading}
  //           paragraph={{ rows: 1 }}
  //           title={false}
  //           className="cursor"
  //         >
  //           <Tooltip
  //             title={
  //               record?.type === TransactionTypes.FixedAsset ||
  //               record?.type === TransactionTypes.Depreciate
  //                 ? `Deleting is disabled as journal is posted from ${
  //                     TransactionTypeNames[record?.type]
  //                   }`
  //                 : "Delete"
  //             }
  //             placement="top"
  //             color={
  //               record?.type === TransactionTypes.FixedAsset ||
  //               record?.type === TransactionTypes.Depreciate
  //                 ? "gray"
  //                 : "red"
  //             }
  //           >
  //             <DeleteTwoTone
  //               className={
  //                 record?.type === TransactionTypes.FixedAsset ||
  //                 record?.type === TransactionTypes.Depreciate
  //                   ? "ps-6"
  //                   : "ca-delete-btn"
  //               }
  //               twoToneColor={
  //                 record?.type === TransactionTypes.FixedAsset ||
  //                 record?.type === TransactionTypes.Depreciate
  //                   ? "rgb(216 207 207)"
  //                   : "#ff879d"
  //               }
  //               onClick={() => {
  //                 if (
  //                   record?.type !== TransactionTypes.FixedAsset &&
  //                   record?.type !== TransactionTypes.Depreciate
  //                 ) {
  //                   setConfirmation(true);
  //                   setJournalId(record?.id);
  //                 }
  //               }}
  //             />
  //           </Tooltip>
  //         </Skeleton>
  //       ),
  //     }
  //   );
  // }
  // --------- Get TableData API ---------

  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getJournalList(
        currentClient?.id,
        Voucher_Type.Journal,
        (page - 1) * pageSize,
        pageSize,
        filterDate.from,
        filterDate.to,
        filter.sortCol,
        filter.sortDir,
        undefined,
        undefined,
        searchJournal,
        status
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    searchJournal,
    filterDate.from,
    filterDate.to,
    status,
    refreshAtDelete,
  ]);

  const getJournalList = async (
    clientId: string,
    voucherType: Voucher_Type = 10,
    start: number,
    length: number,
    fromDate: string = "",
    toDate: string = "",
    sortCol?: string,
    sortDir?: string,
    noPartyId?: string,
    npReconciled?: string,
    searchJournal?: string,
    status?: VoucherFilterStatus
  ) => {
    setLoading(true);
    await VoucherServices.getVoucharList(
      clientId,
      voucherType,
      start,
      length,
      fromDate,
      toDate,
      sortCol,
      sortDir,
      noPartyId,
      npReconciled,
      searchJournal,
      status
    )
      .then((res: any) => {
        setTotAmount(res?.result?.totalAmount);
        setVoucherDetailData(
          res?.result?.voucherItems
            ?.map((el: any, index: number) => {
              //  console.log("This is my data", el);
              return {
                id: el?.vId,
                key: `${el?.vId}-${index}`, // Unique key using vId and index
                date: dayjs(el?.date).format("DD/MM/YYYY"),
                number: el?.number,
                ledgerName: el?.pLedger[0].ledgerName,
                amount: el?.invAmount,
                voucherStatus: el?.voucherStatus,
              };
            })
            .flat()
        );
        setTotalRecords(res.result?.totalRecords);
        setLoading(false);
        // setVoucherDetailsData(res);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        //setVoucherDetailsData([]);
      });
  };
  //console.log(voucherDetailData, "dataAll");

  // ----- Delete or revive api -----
  React.useEffect(() => {
    if (deleteOrRevive.id) {
      const DeleteOrRevive = async () => {
        setLoading(true);
        try {
          const res = await VoucherServices.deleteVoucher(
            currentClient?.id!,
            deleteOrRevive.id
          );
          if (res.result) {
            setRefreshAtDelete(!refreshAtDelete);
            openNotification(
              "success",
              `Entry ${
                status === VoucherFilterStatus.Deleted ? `revived` : `deleted`
              } successfully ${deleteOrRevive.number} `
            );
          } else {
            openNotification("error", res.message);
          }
        } catch (err: any) {
          console.error("error..", err);
          openNotification("error", err.message);
        }
        setDeleteOrRevive({ id: "", isRev: false, number: "" }); //due to this component is rerendering twice
      };
      DeleteOrRevive();
      setConfirmation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrRevive?.id]);

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  const exportCsv = () => {
    VoucherServices.exportCsv(
      currentClient?.id,
      Voucher_Type.Journal,
      (page - 1) * pageSize,
      pageSize,
      filterDate.from,
      filterDate.to,
      filter.sortCol,
      filter.sortDir,
      undefined,
      undefined,
      searchJournal,
      status
      // searchCustomer
      // invoiceStatus
    )
      .then((res) => {
        if (res) {
          console.log("download sucess");
        }
      })
      .catch((ex: any) => {
        console.error("error..", ex.message);
        openNotification("error", ex.message);
      });
  };
  // ----- Short-Cut for Open Model -----F
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    navigate(`/accounts/clients/${currentClient?.id}/journals/add`);
  });

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <React.Fragment>
        <Row style={{ marginTop: 10 }}>
          <Col lg={10} md={24} xxl={12} xl={10}>
            <Space size={5}>
              <Button
                type="primary"
                style={{
                  color: "white",
                  marginBottom: "0px",
                }}
                onClick={() => {
                  navigate(
                    `/accounts/clients/${currentClient?.id}/journals/add`
                  );
                }}
              >
                <PlusOutlined />
                Journal
              </Button>
              <Button
                type="default"
                icon={<DownloadOutlined />}
                onClick={() => exportCsv()}
              >
                Export
              </Button>
            </Space>
          </Col>
          <Col lg={12} md={24} xxl={12}>
            <Row justify="end" gutter={8}>
              <Col>
                {/* <Form.Item name="search" className="inputBoxMb"> */}
                <Input
                  name="search"
                  style={{ width: "200px", border: "1px solid #f1f3ff" }}
                  placeholder="Search Ledger"
                  allowClear
                  onChange={(e: any) => {
                    setLoading(true);
                    if (!e.cancelable) {
                      const searchItem = setTimeout(() => {
                        setPage(1);
                        setSearchJournal(e.target.value);
                      }, 500);
                      return () => clearTimeout(searchItem);
                    } else {
                      setSearchJournal(e.target.value);
                    }
                  }}
                  suffix={
                    loading || searchJournal ? (
                      ""
                    ) : (
                      <img alt="search" src={search} />
                    )
                  }
                />
                {/* </Form.Item> */}
              </Col>
              <Col>
                <RangePicker
                  defaultValue={[
                    dayjs(filterDate?.from, "DD/MM/YYYY"),
                    dayjs(filterDate?.to, "DD/MM/YYYY"),
                  ]}
                  format="DD/MM/YYYY"
                  style={{ width: "220px", border: "1px solid #f1f3ff" }}
                  onChange={(e: any) => {
                    // console.log(e, "date");
                    e === null
                      ? setFilterDate({})
                      : setFilterDate({
                          ...filterDate,
                          from: dayjs(e[0]).format("DD/MM/YYYY"),
                          to: dayjs(e[1]).format("DD/MM/YYYY"),
                        });
                  }}
                  presets={[
                    {
                      label: "Today",
                      value: [dayjs().add(0, "d"), dayjs()],
                    },
                    {
                      label: "Last 7 Days",
                      value: [dayjs().add(-7, "d"), dayjs()],
                    },
                    {
                      label: "Last 15 Days",
                      value: [dayjs().add(-15, "d"), dayjs()],
                    },
                    {
                      label: "This Month",
                      value: [dayjs().startOf("month"), dayjs()],
                    },
                    {
                      label: "Last Month",
                      value: [
                        dayjs().date(0).startOf("month"),
                        dayjs().date(0),
                      ],
                    },
                    {
                      label: "Quarter 1",
                      value: [
                        dayjs().month(3).startOf("month"),
                        dayjs().month(5).endOf("month"),
                      ],
                    },
                    {
                      label: "Quarter 2",
                      value: [
                        dayjs().month(6).startOf("month"),
                        dayjs().month(8).endOf("month"),
                      ],
                    },
                    {
                      label: "Quarter 3",
                      value: [
                        dayjs().month(9).startOf("month"),
                        dayjs().month(11).endOf("month"),
                      ],
                    },
                    {
                      label: "Quarter 4",
                      value: [
                        dayjs().month(0).startOf("month"),
                        dayjs().month(2).endOf("month"),
                      ],
                    },
                    {
                      label: "This Year",
                      value: [dayjs().month(0).startOf("month"), dayjs()],
                    },
                    {
                      label: "Last Year",
                      value: [
                        dayjs().month(-12).startOf("month"),
                        dayjs().month(-1).endOf("month"),
                      ],
                    },
                  ]}
                />
              </Col>
              <Col>
                <Select
                  value={status}
                  style={{ width: windowWidth === 1024 ? 80 : 120 }}
                  options={[
                    { value: VoucherFilterStatus.All, label: "All" },
                    { value: VoucherFilterStatus.Active, label: "Active" },
                    { value: VoucherFilterStatus.Deleted, label: "Deleted" },
                  ]}
                  onSelect={(val: VoucherFilterStatus) => {
                    setPage(1);
                    setStatus(val);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          locale={{
            triggerDesc: "",
            triggerAsc: "",
            cancelSort: "",
          }}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                  key: `loading-${index}`,
                })) as IJournalList[])
              : voucherDetailData
          }
          columns={columns}
          onChange={tableSort}
          pagination={{
            total: totalRecords,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["10", "15", "25", "50", "100"],
            showTotal: (totalRecords, page) =>
              `${page[0]}-${page[1]} of ${totalRecords} items`,
          }}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row
                  style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell
                    index={1}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="fw600">
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} className="fw600" align="right">
                    ₹ {Utils.getFormattedNumber(totAmount)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} className="fw600">
                    {"   "}
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={6} className="fw600">
                    {"   "}
                  </Table.Summary.Cell> */}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        {confirmation && (
          <ConfirmationModal
            open={confirmation}
            onNo={() => setConfirmation(false)}
            onYes={() => {
              setDeleteOrRevive({
                id: journalId,
                isRev: status === VoucherFilterStatus.Deleted ? true : false,
              });
              // setRefreshAtDelete(!refreshAtDelete);
            }}
            text={
              status === VoucherFilterStatus.Deleted
                ? `Are you sure you want to revive?`
                : `Are you sure you want to delete?`
            }
          />
        )}

        {open && (
          <ViewJournal
            open={open}
            onCancel={() => setIsOpen(false)}
            journalId={journalId}
          />
        )}
      </React.Fragment>
    </Card>
  );
};

export default JournalsTable;
