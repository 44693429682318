import React from "react";
import { Route, Routes } from "react-router";
import Depreciations from "./Depreciations";
import Disposed from "./Disposed";
import FixedAssets from "./FixedAssets";
import ResourceNotFound from "../../Common/ResourceNotFound";
const AssetsNestedRoute: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path="/fixedAsset" element={<FixedAssets />} />
        {/* <Route path="/depreciations" element={<Depreciations />} /> */}
        <Route path="/disposed" element={<Disposed />} />
        <Route path="*" element={<ResourceNotFound pageHeight="100%" />} />
      </Routes>
    </div>
  );
};
export default AssetsNestedRoute;
