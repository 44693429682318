import React, { useContext } from "react";
import {
  Row,
  Table,
  Input,
  Col,
  Card,
  Button,
  Space,
  Tooltip,
  Skeleton,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import "./Assets.css";
import {
  PlusOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useTheme } from "../../Context/ThemeContext";
import DisposeAssetmodel from "./DisposeAssetmodel";
import FixedAssetsService, {
  AssetStatus,
} from "../../Services/FixedAssetsServiceNew";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import { useClient } from "../../Context/ClientContext";
import search from "../../Images/search.svg";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import ViewAssetModel from "./ViewAssetModel";
interface IDisposedListDataType {
  key: string;
  id: string;
  number: string;
  name: string;
  sno: number;
  //description: string;
  date: string;
  disposalValue: number;
  disposedate: string;
  depreciation: number;
  price: string;
  gainloss: number;
}

const Disposed: React.FC = () => {
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [isDisposeOpen, setIsDisposeOpen] = React.useState<boolean>(false);
  const [searchDispose, setSearchDispose] = React.useState("");
  const [disposeList, setDisposeList] = React.useState<any[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [disposedId, setDispodedId] = React.useState<string>("");
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [reRenderData, setReRenderData] = React.useState<boolean>(false);
  const [isViewModelOpen, setIsViewModelOpen] = React.useState<boolean>(false);
  const [type, setType] = React.useState<boolean>(Boolean)

  const columns: ColumnsType<IDisposedListDataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      width: "5%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Asset Reference",
      dataIndex: "number",
      width: 150,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ cursor: "pointer", color: "#1677FF" }}
            onClick={() => {
              setIsViewModelOpen(true);
              setDispodedId(record.id);
              setType(true)
            }}
          >  {record?.number}</span>
        </Skeleton>
      ),
    },
    {
      title: "Asset Name",
      dataIndex: "name",
      width: 150,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   width: 300,
    // },
    {
      title: "Purchase Date",
      dataIndex: "date",
      width: 150,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Dispose Date",
      dataIndex: "disposedate",
      width: 150,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.disposedate}
        </Skeleton>
      ),
    },
    {
      title: "Purchase Price",
      dataIndex: "price",
      align: "right",
      width: 150,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.price}
        </Skeleton>
      ),
    },
    {
      title: "Disposal Value",
      dataIndex: "disposalValue",
      align: "right",
      width: 150,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.disposalValue}
        </Skeleton>
      ),
    },
    {
      title: "Depreciation",
      dataIndex: "depreciation",
      align: "right",
      width: 150,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.depreciation}
        </Skeleton>
      ),
    },
    {
      // title: "Gain/Loss",
      title: (
        <>
          {"Gain/Loss "}
          <Tooltip
            title="Disposal Value - (Asset Value - Accumulated Depreciation)"
            placement="top"
            color="#4b5b95"
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "gainloss",
      align: "right",
      width: 150,
      render: (x) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <div style={{ color: x > 0 ? "green" : "red" }}>
            {Utils.getFormattedCurrency(x)}
          </div>
        </Skeleton>
      ),
    },
    {
      title: " ",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div className="cursor">
            <Tooltip title="Activate disposed asset">
              <SyncOutlined
                onClick={() => {
                  setConfirmation(true);
                  setDispodedId(record?.id);
                }}
              />
            </Tooltip>
          </div>
        </Skeleton>
      ),
    },
  ];

  const getList = async (
    clientId: string,
    start: number,
    length: number,
    sortCol: any,
    sortDir: any,
    search: string,
    status: AssetStatus
  ) => {
    await FixedAssetsService.getFixedAssetsList(
      clientId,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status
    )
      .then((res) => {
        if (res != null || res?.items?.length > 0) {
          //console.log("res?.items", res?.items);
          setDisposeList(
            res?.items?.map((e: any, index: number) => ({
              key: index,
              sno: index + 1,
              id: e?.id,
              number: e?.number,
              name: e?.name,
              date: dayjs(e?.invoiceDetail.date).format("DD/MM/YYYY"),
              price: Utils.getFormattedNumber(e?.invoiceDetail.price),
              disposalValue: Utils.getFormattedNumber(e?.disposalValue),
              disposedate: dayjs(e?.disposalDate).format("DD/MM/YYYY"),
              depreciation: Utils.getFormattedNumber(
                e?.accumulatedDepreciation
              ),
              gainloss: e?.pnLAmount,
              //description: e?.description,
              // isDepreciable: e?.isDepreciable === true ? "Yes" : "No",
              // journals: e?.journals,
              // jrNumber: e?.jrNumber,
            }))
          );
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setDisposeList([]);
          setTotalRecords(res?.totalRecords);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);

    getList(
      currentClient?.id!,
      (page - 1) * pageSize,
      pageSize,
      undefined,
      undefined,
      searchDispose,
      AssetStatus.Disposed
      // "", // tyep
      // "", // lastAccDate
      // true
    );
  }, [currentClient?.id, searchDispose, page, pageSize, reRenderData]);

  // ----- Short-Cut for Open Model -----F
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    setIsDisposeOpen(true);
  });

  return (
    <>
      <Card
        size="small"
        className="TableCard"
        style={{
          // width: "97%",
          // marginLeft: "16px",
          marginTop: "6px",
          padding: "48px 0px 0px 0px",
          boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
      >
        <Row
          justify={"space-between"}
          style={{
            // paddingTop: props?.partyId ? "10px" : "0px"
            // marginTop: 10,
            padding: "0px 0px 3px 0px",
          }}
        >
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsDisposeOpen(true);
              }}
            >
              Dispose Asset
            </Button>
          </Col>
          <Space direction="vertical" />
          <Col>
            <Input
              style={{ width: "200px" }}
              placeholder="Search Disposed Asset"
              allowClear
              onChange={(e: any) => {
                setPage(1);
                setSearchDispose(e.target.value);
              }}
              suffix={
                loading || searchDispose ? (
                  ""
                ) : (
                  <img alt="search" src={search} />
                )
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24}>
            <Table
              className={`Tabel-style table-${themeData?.componentSize ?? "middle"
                }`}
              columns={columns}
              // dataSource={disposeList}
              dataSource={
                loading
                  ? (Array.from({ length: pageSize }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as IDisposedListDataType[])
                  : disposeList
              }
              size="small"
              pagination={{
                total: totalRecords,
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                onChange: (page: number, pageSize: number) => {
                  setPage(page);
                  setPageSize(pageSize);
                },
                pageSizeOptions: ["10", "15", "25", "50", "100"],
              }}
              scroll={disposeList.length > 15 ? { x: 700, y: 620 } : { x: 600 }}
            />
          </Col>
        </Row>
      </Card>
      {isDisposeOpen && (
        <DisposeAssetmodel
          isDisposeOpen={isDisposeOpen}
          setIsDisposeOpen={setIsDisposeOpen}
          setReRender={setReRenderData}
          reRender={reRenderData}
        />
      )}

      {isViewModelOpen && (
        <ViewAssetModel
          isViewModelOpen={isViewModelOpen}
          onCancel={() => setIsViewModelOpen(false)}
          assetId={disposedId}
          type={type}
        />
      )}
      {/* {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          text="Are you sure to re-activate the disposed asset?"
          onYes={() => {
            setLoading(true);
            FixedAssetsService.reActivateAsset(currentClient?.id!, assetId)
              .then((res: any) => {
                if (res?.status) {
                  openNotification("success", `Entry activate successfully`);
                  setReRenderData(!reRenderData);
                }
                setLoading(false);
              })
              .catch((ex) => {
                setLoading(false);
                openNotification("error", ex);
              });
            setConfirmation(false);
          }}
        />
      )} */}
    </>
  );
};
export default Disposed;
