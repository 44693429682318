import React, { useEffect, useState } from "react";
import { dep } from "./Depreciation.js";
import {
  Row,
  Button,
  Input,
  Modal,
  Space,
  Col,
  Form,
  DatePicker,
  Select,
  TreeSelect,
  Switch,
  Divider,
  Spin,
  Card,
  Tooltip,
} from "antd";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { useClient } from "../../Context/ClientContext";
import FixedAssetsServiceNew from "../../Services/FixedAssetsServiceNew";
import {
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import ChartofAccountServiceNew from "../../Services/ChartofAccountServicesNew";
import { notificationContext } from "../../Common/PageRoute";
// import AddGroup from "../Master/Group/AddGroup.js";
import ChartofAccountServicesNew from "../../Services/ChartofAccountServicesNew";
import AddGroup from "../Master/Group/AddGroup";
import AddLedger from "../Master/Ledger/AddLedger";
interface ModalProps {
  isModalOpen: boolean;
  isConfirmation: boolean;
  modelCancel: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  editAsset: any;
}
// const props: UploadProps = {
//   name: "file",
//   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
//   headers: {
//     authorization: "authorization-text",
//   },
//   onChange(info) {
//     if (info.file.status !== "uploading") {
//       // console.log(info.file, info.fileList);
//     }
//     if (info.file.status === "done") {
//       message.success(`${info.file.name} file uploaded successfully`);
//     } else if (info.file.status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
// };

export enum PmtMethod {
  bank,
  cash,
  party,
}
export enum PnLAccountGroups {
  CostofSales = 2,
  SellingAndDistributionCosts = 7,
  AdministrativeExpenses = 8,
}
export enum CalcMethod {
  WrittenDownValue,
  StraightLine,
}

const AssetModel = ({
  setIsModalOpen,
  isModalOpen,
  isConfirmation,
  setIsConfirmation,
  setTrigger,
  editAsset,
}: ModalProps) => {
  const [form] = Form.useForm();
  const [isDepreciable, setDepreciable] = React.useState<boolean>(true);
  // const [isGstEnabled, setIsGstEnabled] = React.useState<boolean>();
  // const [methodValue, setMethodValue] = React.useState<string>("Rate");
  // const [accounts, setAccounts] = useState<any[]>([]);
  // const [bankAccount, setBankAccount] = useState<any[]>([]);
  const [paymentMode, setPaymentMode] = React.useState<any>();
  const [residualValue, setResidualValue] = React.useState<number>(0);
  const [location, setLocation] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [supplierOptions, setSupplierOptions] = useState<any>([]);
  const [depreciationOptions, setDepreciationOptions] = useState<any>([]);
  const [payLedgers, setPayLedgers] = useState<any>([]);
  // const [fixedAssetsOptions, setFixedAssetsOptions] = useState<any>([]);
  const [fixedAssetsGroups, setFixedAssetsGroups] = useState<any>([]);
  const [categoryValue, setCategoryValue] = useState<string>("");
  const [groupValue, setGroupValue] = useState<string>("");
  const [voucherDate, setVoucherDate] = useState<string>();

  const [depMethod, setDepMethod] = useState<CalcMethod>(
    CalcMethod.WrittenDownValue
  );
  const [groupData, setGroupData] = React.useState<any[]>([]);
  const { currentClient, companySettings } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const gstOption = companySettings?.setting?.gstDetails?.gst;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: "",
  });
  const [groupListTrigger, setGroupListTrigger] =
    React.useState<boolean>(false);
  // const [paymentListTrigger, setPaymentListTrigger] =
  //   React.useState<boolean>(false);

  useEffect(() => {
    setVoucherDate(dayjs().toISOString());
    const getGroupFixedAssets = async (clientId: String) => {
      if (clientId) {
        // ------- Get Fixed Assets Groups APi ------
        await ChartofAccountServiceNew.getGroupList(
          currentClient?.id!,
          "",
          "",
          "",
          "",
          SearchLedgerByGroupsTypes.FixedAssets
        )
          .then((res: any) => {
            if (res?.items?.length > 0) {
              setFixedAssetsGroups(res?.items);
            }
          })
          .catch((err: any) => {
            console.error("error......", err?.message);
          });

        // ------- Search Depreciation Ledgers APi ------
        await LedgerService.getLedgersByGroupName(
          currentClient?.id!,
          SearchLedgerByGroupsTypes.Depreciation
        ).then((res: any) => {
          setDepreciationOptions(res);
          //console.log(supplierOptions);
        });
      }
    };
    getGroupFixedAssets(currentClient?.id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, groupListTrigger]); //, searchSupplier.search]);

  const GetGroupList = (GrpList: any[]) => {
    return GrpList?.map((itm: any) => {
      const list: any = {
        key: itm?.id,
        value: itm?.id,
        title: itm?.group_Name,
      };
      if (itm?.children?.length > 0) {
        list.children = GetGroupList([...itm?.children]);
      }
      return list;
    });
  };

  const AssetsGroupOptions = GetGroupList(fixedAssetsGroups);

  function searchCategory(CategoryData: any, searchId: string): any {
    for (const child of CategoryData) {
      if (child.Id === searchId) {
        return child;
      }
      if (child.Children.length > 0) {
        const result = searchCategory(child.Children, searchId);
        if (result) {
          return result;
        }
      }
    }
  }

  const SetDepRatenLife = (SelCategory: string, SelDepMethod: CalcMethod) => {
    if (SelCategory !== "") {
      const depFilterReco = searchCategory(dep, SelCategory);
      form.setFieldValue("lifeCycleDuration", depFilterReco?.Life);
      if (SelDepMethod === CalcMethod.WrittenDownValue) {
        form.setFieldValue("depRate", depFilterReco?.WDVRate);
      } else {
        form.setFieldValue("depRate", depFilterReco?.SLMRate);
      }
    }
  };

  const handleSLMDepRate = (val: any) => {
    if (depMethod === CalcMethod.StraightLine) {
      form.setFieldValue("depRate", 100 / val?.target?.value);
    } else {
      return;
    }
  };

  const handlePurchaseDateChange = (selDate: any) => {
    setVoucherDate(selDate.toISOString());
    form.setFieldValue(["invoiceDetail", "purPayDate"], selDate);
    form.setFieldValue("depreciationStartDate", selDate);
  };
  const onAssetDepMethodChange = (selValue: CalcMethod) => {
    form.setFieldValue(
      "residualValue",
      selValue === CalcMethod.StraightLine ? residualValue : ""
    );
    setDepMethod(selValue);
    SetDepRatenLife(categoryValue, selValue);
  };

  function onAssetCategoryChange(selValue: string) {
    setCategoryValue(selValue);
    SetDepRatenLife(selValue, depMethod);
  }

  const onPaymentModeChange = (selValue: PmtMethod) => {
    form.setFieldValue("paymentLedger", "");

    if (selValue === PmtMethod.bank) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.BankOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    if (selValue === PmtMethod.party) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.PartiesOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    if (selValue === PmtMethod.cash) {
      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.CashOnly
      ).then((res: any) => {
        setPayLedgers(res);
      });
    }
    setPaymentMode(selValue);
  };

  const onAssetGroupChange = (selValue: string) => {
    setGroupValue(selValue);
  };

  const handleLocation = (selValue: number) => {
    //alert(typeof selValue);
    setLocation(selValue);
  };

  const GetCategoryList = (DepList: any[]) => {
    return DepList?.map((itm: any) => {
      const list: any = {
        key: itm.Id,
        value: itm.Id,
        title: itm.Title,
      };
      if (itm.Children.length > 0) {
        list.children = GetCategoryList([...itm.Children]);
      }
      return list;
    });
  };
  const AssetCategoryOptions = GetCategoryList(dep);

  const initialValue = {
    warrantyExpDate: dayjs(),
    depreciationStartDate: dayjs(),
    // payDate: dayjs(),
    invoiceDetail: {
      date: dayjs(),
      purPayDate: dayjs(),
      gst: {
        key: gstOption && gstOption[0].rate,
        value: gstOption && gstOption[0].id,
        label: gstOption && gstOption[0].name,
      },
    },
    taxCredit: gstOption === null ? 0 : 1,
    price: Utils.getFormattedNumber(0),
    depMethod: CalcMethod.WrittenDownValue,

    // creditor: {
    //   paymentMethod: PmtMethod.Supplier,
    //   gst: {
    //     key: gstOption && gstOption[0].rate,
    //     value: gstOption && gstOption[0].id,
    //     label: gstOption && gstOption[0].name,
    //   },
    // },
    // accountGroupType: PnLAccountGroups.AdministrativeExpenses,
    //method: CalcMethod.WrittenDownValue,
    //rate: 0,
    //year: 0,
    //startDate: dayjs(),
    //accumulatedDep: Utils.getFormattedNumber(0),
  };

  // //------- Search Fixed Assets Ledgers APi------
  // useEffect(() => {
  //   LedgerService.getLedgersByGroupName(
  //     currentClient?.id!,
  //     SearchLedgerByGroupsTypes.FixedAssets
  //   ).then((res: any) => {
  //     setFixedAssetsOptions(res);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentClient?.id]); //, searchSupplier.search]);

  //------- Search Disposal Ledgers APi------

  useEffect(() => {
    if (currentClient?.id && !editAsset?.id) {
      setLoading(true);

      FixedAssetsServiceNew.getAssetMaxNo(
        currentClient?.id!,
        voucherDate?.toString()
      )
        .then((res) => {
          console.log("FixedAsssetResult", res);

          form.setFieldValue("number", res?.result);
          form.setFieldValue("date", dayjs());
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err.message);
        });
      setLoading(false);
    }
    // LedgerService.getLedgersByGroupName(
    //   currentClient?.id!,
    //   SearchLedgerByGroupsTypes.PartiesWithBank
    // ).then((res: any) => {
    //   setSupplierOptions(res);
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, voucherDate]); //, searchSupplier.search]);

  //----Initial value and Edit Asset ------
  React.useEffect(() => {
    // inputRef.current?.focus();
    setLoading(true);
    const getListData = async () => {
      let temp = await ChartofAccountServicesNew.getGroupList(
        currentClient?.id!
      );
      if (temp) {
        setGroupData(temp.items);
        // if (props.editData.id) {
        //   setGroupType(props.editData?.group_Type);
        //   form.setFieldsValue(props.editData);
        // }

        setLoading(false);
      } else {
        setGroupData([]);
        console.log("Error", temp);
        setLoading(false);
      }
    };

    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    FixedAssetsServiceNew.getFixedAsset(
      currentClient?.id!,
      editAsset?.id,
      false
    )
      .then((res) => {
        if (res.status) {
          const ass = res?.result;
          setVoucherDate(ass?.invoiceDetail?.voucherDate);
          const asset = {
            ...ass,
            invoiceDetail: {
              ...ass?.invoiceDetail,
              date: dayjs(ass?.invoiceDetail?.date),
              invNumber: ass?.invoiceDetail?.invNumber,
              //supplier: ass?.invoiceDetail?.supplier,
              //"qty": 0,
              price: ass?.invoiceDetail?.price,
              totalValue: ass?.invoiceDetail?.price,
              voucherDate: dayjs(ass?.invoiceDetail?.voucherDate),
              purPayDate: dayjs(ass?.invoiceDetail?.purPayDate),
              gst:
                ass?.gst !== null
                  ? {
                      key: ass?.gst?.rate,
                      label: ass?.gst?.name,
                      value: ass?.gst?.id,
                    }
                  : null,
            },
            taxCredit: ass?.taxCredit === true ? 1 : 0,
            location: Utils.getNumber(ass?.location),
            depreciationStartDate: dayjs(ass?.depreciationStartDate),
            warrantyExpDate: dayjs(ass?.warrantyExpDate),
            // date: dayjs(ass.date),
            // account: {
            //   label: ass?.account?.name,
            //   value: ass?.account?.id,
            // },
            // price: Utils.getFormattedNumber(ass?.price),
            // startDate: dayjs(ass?.depreciationStartDate),
            // accumulatedDep: Utils.getFormattedNumber(
            //   ass?.accumulatedDepreciation
            // ),
            // bankAccount: {
            //   label: ass?.bankAccount?.name,
            //   value: ass?.bankAccount?.id,
            // },
            // creditor: {
            //   ...ass?.creditor,
            //   refrence: {
            //     label: ass?.creditor?.refrence?.name,
            //     value: ass?.creditor?.refrence?.id,
            //   },
            //   gst: {
            //     key: ass?.creditor?.gst?.rate,
            //     label: ass?.creditor?.gst?.name,
            //     value: ass?.creditor?.gst?.id,
            //   },
            // },
          };
          setPaymentMode(
            ass?.payMethod === 0
              ? PmtMethod.bank
              : ass?.payMethod === 1
              ? PmtMethod.cash
              : PmtMethod.party
          );
          setDepMethod(
            ass?.depMethod === 0
              ? CalcMethod.WrittenDownValue
              : CalcMethod.StraightLine
          );
          setResidualValue(ass?.residualValue);
          if (editAsset?.id) {
            console.log("Edit Record: ", asset);
            onPaymentModeChange(asset.payMethod);
            form.setFieldsValue(asset);
            //setLocation(ass?.location === "1" ? 1 : 2);
            //setPaymentMode(ass?.payMode);
          } else {
            form.setFieldValue("number", ass?.number);
          }
          setDepreciable(ass?.isDepreciable);
          //setIsGstEnabled(res?.result?.isGstEnabled);
          // if (res?.result?.fixedAssetAccounts) {
          //   let acc: ISelectGroup[] = res?.result?.fixedAssetAccounts?.map(
          //     (group: any) => ({
          //       label: `${group.typeName} (${group.groupName})`,
          //       options: group.accounts?.map((a: AccountShort) => ({
          //         label: a.name,
          //         value: a.id,
          //       })),
          //     })
          //   );
          //   setAccounts([...acc]);
          // }
          setLoading(false);
        } else setLoading(false);
      })
      .catch((ex) => {
        openNotification("error", ex.message);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  //  ---- submit assets -------
  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        setSaveButtonLoading(true);
        const asset = {
          ...values,
          //date: dayjs(values?.date).format("DD/MM/YYYY HH:mm:ss"),
          status: 1,
          isDepreciable: isDepreciable,
          invoiceDetail: {
            ...values?.invoiceDetail,
            date: dayjs(values?.invoiceDetail?.date),
            invNumber: values?.invoiceDetail?.invNumber,
            //supplier: values?.invoiceDetail?.supplier,
            //"qty": 0,
            price: values?.invoiceDetail?.price,
            totalValue: values?.invoiceDetail?.price,
            voucherDate: dayjs(voucherDate),
            purPayDate: dayjs(values?.invoiceDetail?.purPayDate),
          },
          gST:
            values?.invoiceDetail?.gst?.value === null
              ? null
              : {
                  id: values?.invoiceDetail?.gst?.value,
                  rate: values?.invoiceDetail?.gst?.key,
                  name: values?.invoiceDetail?.gst?.label,
                },
          depreciationStartDate: dayjs(values?.depreciationStartDate).startOf(
            "day"
          ),
          warrantyExpDate: dayjs(values?.warrantyExpDate).endOf("day"),
          // account: {
          //   id: values.account.value,
          //   name: values.account.label,
          // },
          // price: Utils.getNumber(values.price),
          // isDepreciable: isDepreciable,
          // startDate: dayjs(values?.startDate).format("DD/MM/YYYY"),
          // accumulatedDep: Utils.getNumber(values?.accumulatedDep),
        };
        console.log("Post Data: ", asset);
        FixedAssetsServiceNew.PostFixedAssets(
          currentClient?.id!,
          asset,
          editAsset?.id
        )
          .then((res: any) => {
            if (res.result) {
              openNotification(
                "success",
                `Asset ${
                  editAsset?.id ? "Edit successfully" : "Add successfully"
                }`
              );
              setIsModalOpen(false);
              setTrigger((x) => !x);
              form.resetFields();
              setSaveButtonLoading(false);
            } else setSaveButtonLoading(false);
          })
          .catch((error) => {
            openNotification("error", error);
            setSaveButtonLoading(false);
          });
      })
      .catch(({ errorFields, err }) => {
        form.scrollToField(errorFields[0].name[0]);
        setSaveButtonLoading(false);
      });
  };

  const handleCancel = () => {
    const findvalue = form.isFieldsTouched();
    if (findvalue) {
      setIsConfirmation(true);
    } else {
      setIsModalOpen(false);
    }
  };

  //------ Initial Invoice --------//
  // const GetInitialInvoice = async () => {
  //   try {
  //     const res = await FixedAssetsService.initialInvoice(currentClient?.id!);
  //     if (res.result) {
  //       console.log("res.result", res.result);

  //       form.setFieldValue("number", res?.result?.number);
  //       setAccounts(res?.result?.accounts);
  //       setIsGstEnabled(res?.result?.isGstEnabled);
  //       setBankAccount(res?.result?.bankAccounts);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // //------ GetFixed Assets API -------//
  // const GetFixedAssets = async () => {
  //   try {
  //     setModelLoading(true);
  //     let res = await FixedAssetsServiceNew.getFixedAsset(
  //       currentClient?.id!,
  //       editAsset?.id
  //     );
  //     if (res.result) {
  //       //setAccounts(res?.result?.fixedAssetAccounts);
  //       //setIsGstEnabled(res?.result?.isGstEnabled);
  //       const setdata = {
  //         name: res.result.asset?.name,
  //         number: res.result.asset?.number,
  //         date:
  //           res.result.asset?.date === ""
  //             ? undefined
  //             : dayjs(res?.result?.asset?.date),
  //         // account: {
  //         //   value: res.result.asset.account?.id,
  //         //   label: res.result.asset.account?.name,
  //         // },
  //         // accountGroupType: res?.result?.asset?.accountGroupType,
  //         price: res.result.asset?.price,
  //         isDepreciable: res.result.asset?.isDepreciable,
  //         // creditor: {
  //         //   paymentMethod: res.result.asset.creditor?.paymentMethod,
  //         //   gst: {
  //         //     value: res.result.asset.creditor?.gst?.id,
  //         //     title: res.result.asset.creditor?.gst?.rate,
  //         //     label: res.result.asset.creditor?.gst?.name,
  //         //     key: res.result.asset.creditor?.gst?.name,
  //         //   },
  //         //   refrence: {
  //         //     value: res.result.asset.creditor?.refrence?.id,
  //         //     label: res.result.asset.creditor?.refrence?.name,
  //         //   },
  //         // },
  //         startDate:
  //           res.result.asset?.startDate === ""
  //             ? undefined
  //             : dayjs(res?.result?.asset?.startDate),
  //         description: res.result.asset?.description,
  //         accumulatedDep: res.result.asset?.accumulatedDepreciation,
  //         method: res?.result.asset?.method,
  //         rate: res.result.asset?.rate,
  //         year: res.result.asset?.year,
  //       };
  //       setOpen(setdata?.isDepreciable);
  //       setPaymentMethod(setdata?.creditor?.paymentMethod);
  //       form.setFieldsValue({
  //         ...setdata,
  //       });
  //       setModelLoading(false);
  //     }
  //   } catch (err: any) {
  //     setModelLoading(true);
  //   }
  // };

  //----  Initial Value Invoice and Fixed Assets Get Api-----
  // useEffect(() => {
  //   editAsset?.id !== null ? GetFixedAssets() : GetInitialInvoice();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [editAsset?.id]);

  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  useEffect(() => {
    console.log("addLedgerResponse", addLedgerResponse);

    if (addLedgerResponse) {
      const exists = payLedgers.some(
        (option: any) => option?.id === addLedgerResponse.id
      );
      console.log("exists", exists, "payLedgers", payLedgers);
      if (exists) {
        form.setFieldValue("paymentLedger", addLedgerResponse?.id);
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payLedgers]);

  return (
    <div>
      <Modal
        title={editAsset?.id ? "Edit Fixed Asset " : "Add Fixed Asset"}
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
        width={"70%"}
        footer={
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={onSubmit}
              loading={saveButtonLoading}
            >
              {editAsset?.id !== null ? "Update" : "Save"}
            </Button>
          </>
        }
        className="modelpadding"
      >
        <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <Spin indicator={antIcon} spinning={loading}>
            <Form
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              //style={{ maxWidth: 1000 }}
              autoComplete="off"
              form={form}
              requiredMark={false}
              layout="vertical"
              size="middle"
              //onFinishFailed={}

              initialValues={editAsset?.id ? {} : initialValue}
            >
              <div className="inputboxmb">
                <Card title="Asset Detail" size="small">
                  <Row>
                    <Col lg={16} md={16}>
                      <Form.Item
                        name="name"
                        label="Asset Name"
                        rules={[
                          {
                            required: true,
                            message: "Name required",
                            pattern: /^[ A-Za-z0-9_/(/)-]*$/,
                          },
                        ]}
                        //className="inputboxsize inputboxmb"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4} style={{ paddingLeft: "8px" }}>
                      <Form.Item
                        name="number"
                        label="Asset Reference"
                        //className="inputboxsize inputboxmb"
                      >
                        <Input type="string" disabled />
                      </Form.Item>
                    </Col>

                    <Col lg={4} md={4} style={{ paddingLeft: "8px" }}>
                      <Form.Item
                        name="isDepreciable"
                        valuePropName="checked"
                        style={{ display: "flex", flexDirection: "row" }}
                        labelCol={{ lg: 24 }}
                        wrapperCol={{ lg: 24 }}
                        //className="inputboxmb"
                      >
                        {/* <span style={{ paddingRight: "30px" }}> */}
                        Depreciable Asset
                        {/* </span> */}
                        <br />
                        <Switch
                          style={{ margin: "10px 4px 0px" }}
                          checked={isDepreciable}
                          onChange={(isChecked) => {
                            setDepreciable(isChecked);
                            //console.log(isChecked, isDepreciable);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col lg={4} md={4}>
                      <Form.Item name="aliasName" label="Alias / Short Name">
                        <Input />
                      </Form.Item>
                    </Col> */}
                    <Col lg={4} md={4} style={{ padding: "0 4px" }}>
                      <Form.Item name="identityCode" label="Identity No.">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4} style={{ padding: "0 4px" }}>
                      <Form.Item name="model" label="Model">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={12} style={{ padding: "0 4px" }}>
                      <Form.Item
                        name="notes"
                        label="Notes"
                        //className="inputboxmb"
                      >
                        <Input
                        // addonAfter={
                        //   <Upload
                        //     {...props}
                        //     maxCount={1}
                        //     beforeUpload={() => {
                        //       return false;
                        //     }}
                        //   >
                        //     <Button icon={<PaperClipOutlined />}></Button>
                        //   </Upload>
                        // }
                        // className="filetype"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <Form.Item
                        name="group"
                        label="Asset Group"
                        rules={[
                          { required: true, message: "Please select Group." },
                        ]}
                        //className="inputboxmb"
                      >
                        <TreeSelect
                          //className="inputboxsize"
                          treeNodeFilterProp="title"
                          showSearch
                          placeholder="Please select Asset Group"
                          allowClear
                          value={groupValue}
                          //treeDefaultExpandAll
                          style={{ marginBottom: "3px" }}
                          treeData={AssetsGroupOptions}
                          onChange={onAssetGroupChange}
                          dropdownRender={(customerOptions) => (
                            <>
                              {customerOptions}
                              <Divider style={{ margin: "5px 0px" }} />
                              <Button
                                style={{ width: "100%" }}
                                icon={<PlusOutlined />}
                                type="link"
                                onClick={() =>
                                  setOpenModel({ type: "addGroup", param: "" })
                                }
                              >
                                New Group
                              </Button>
                            </>
                          )}
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={18} md={18} style={{ padding: "0 4px" }}>
                      <Form.Item
                        name="description"
                        label="Description"
                        //className="inputboxmb"
                      >
                        <Input
                        // addonAfter={
                        //   <Upload
                        //     {...props}
                        //     maxCount={1}
                        //     beforeUpload={() => {
                        //       return false;
                        //     }}
                        //   >
                        //     <Button icon={<PaperClipOutlined />}></Button>
                        //   </Upload>
                        // }
                        // className="filetype"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                <Card title="Purchase Detail" size="small">
                  <Row>
                    <Col lg={3} md={3}>
                      <Form.Item
                        name={["invoiceDetail", "date"]}
                        label="Purchase Date"
                        rules={[
                          { required: true, message: "Purchase Date required" },
                        ]}
                        //className="inputboxsize inputboxmb"
                      >
                        <DatePicker
                          allowClear={false}
                          format={"DD/MM/YYYY"}
                          onChange={handlePurchaseDateChange}
                          // disabledDate={(current) =>
                          //   current && current > dayjs().endOf("day")
                          // }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={4} md={4} style={{ padding: "0 4px" }}>
                      <Form.Item
                        name={["invoiceDetail", "invNumber"]}
                        label="Invoice No."
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col lg={3} md={3}>
                      <Form.Item
                        name="taxCredit"
                        label="Tax Credit"
                        //className="inputboxsize inputboxmb"
                      >
                        <Select
                          //className="inputboxsize"
                          disabled={gstOption == null ? true : false}
                          style={{ marginBottom: "3px" }}
                          placeholder="Please select"
                          options={[
                            { value: 0, label: "Not Available" },
                            { value: 1, label: "Available" },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={4} md={4} style={{ padding: "0 4px" }}>
                      <Form.Item
                        name={["invoiceDetail", "price"]}
                        label={
                          <Space>
                            Purchase Price
                            <Tooltip
                              title="Please input the base purchase price for the Input Tax Credit, else include GST with the base price"
                              placement="top"
                              color="#4b5b95"
                            >
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </Space>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please provide price of Asset.",
                          },
                        ]}
                      >
                        <Input
                          className="textEnd"
                          onBlur={(e) =>
                            form.setFieldValue(
                              "price",
                              Utils.getFormattedNumber(
                                e.target.value
                                  ? Utils.getNumber(e.target.value)
                                  : 0
                              )
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={3} md={3}>
                      <Form.Item
                        name={["invoiceDetail", "gst"]}
                        label="GST"
                        //className="inputboxsize inputboxmb"
                      >
                        <Select
                          className="inputboxsize"
                          disabled={gstOption === null ? true : false}
                          style={{ marginBottom: "3px" }}
                          labelInValue
                          options={gstOption?.map((gst: any) => ({
                            key: gst.rate,
                            value: gst.id,
                            label: gst.name,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={3} md={3}>
                      <Form.Item
                        name={["invoiceDetail", "voucherDate"]}
                        label={
                          <Space>
                            Voucher Date
                            <Tooltip
                              title="Please input the Voucher Date in case you want to provide specific date for Voucher Entry else Purchase date will be Voucher Date."
                              placement="top"
                              color="#4b5b95"
                            >
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </Space>
                        }

                        //className="inputboxsize inputboxmb"
                      >
                        <DatePicker
                          allowClear={false}
                          format={"DD/MM/YYYY"}
                          onChange={(value: any) =>
                            setVoucherDate(value.toISOString())
                          }
                        />
                      </Form.Item>
                    </Col>

                    {/* <Col lg={7} md={7}>
                      <Form.Item
                        name={["invoiceDetail", "supplier"]}
                        label="Seller"
                        rules={[
                          {
                            required: true,
                            message: "Please select supplier from list!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ marginBottom: "3px" }}
                          options={supplierOptions?.map((value: any) => ({
                            value: value?.id,
                            label: value?.ledgerName,
                          }))}
                          dropdownRender={(supplierOptions) => (
                            <>
                              {supplierOptions}
                              <Divider style={{ margin: "5px 0px" }} />
                              <Button
                                style={{ width: "100%" }}
                                icon={<PlusOutlined />}
                                type="link"
                                onClick={() => {
                                  // setOpenModel({
                                  //   type: "addLedger",
                                  //   param: {
                                  //     open: true,
                                  //   },
                                  // });
                                  // setEditParty({ id: "" });
                                }}
                              >
                                New Ledger
                              </Button>
                            </>
                          )}
                        />
                      </Form.Item>
                    </Col> */}
                  </Row>
                </Card>
                <Card title="Payment Given Detail" size="small">
                  <Row>
                    <Col lg={4} md={4}>
                      <Form.Item
                        name="payMethod"
                        label="Payment Mode"
                        //className="inputboxsize inputboxmb"
                        rules={[
                          {
                            required: true,
                            message: "Payment mode is required",
                          },
                        ]}
                      >
                        <Select
                          //className="inputboxsize"
                          style={{ marginBottom: "3px" }}
                          placeholder="Please select payment mode"
                          onChange={onPaymentModeChange}
                          // onChange={(value: any) => {
                          //   setPaymentMode(value);
                          // }}
                          options={[
                            { value: PmtMethod.bank, label: "Bank" },
                            { value: PmtMethod.cash, label: "Cash" },
                            { value: PmtMethod.party, label: "Party" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={8} style={{ padding: "0 4px" }}>
                      <Form.Item
                        name="paymentLedger"
                        label="Payment Ledger"
                        rules={[
                          {
                            required: true,
                            message: "Payment Ledger is required",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label?.toString() ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ marginBottom: "3px" }}
                          options={payLedgers?.map((value: any) => ({
                            value: value?.id,
                            label: value?.ledgerName.concat(
                              value?.aliasName
                                ? `${" (" + value?.aliasName + ")"}`
                                : ""
                            ),
                          }))}
                          dropdownRender={(supplierOptions) => (
                            <>
                              {supplierOptions}
                              <Divider style={{ margin: "5px 0px" }} />
                              <Button
                                style={{ width: "100%" }}
                                icon={<PlusOutlined />}
                                type="link"
                                onClick={() => {
                                  setOpenModel({
                                    type: "addLedger",
                                    param: "",
                                  });
                                  // setEditParty({ id: "" });
                                }}
                              >
                                New Ledger
                              </Button>
                            </>
                          )}
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={4} md={4} style={{ padding: "0 4px" }}>
                      <Form.Item
                        name={["invoiceDetail", "purPayRef"]}
                        label="Payment Reference"
                      >
                        <Input
                          disabled={
                            paymentMode === PmtMethod.party
                              ? true
                              : paymentMode === PmtMethod.cash
                              ? true
                              : false
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={8} style={{ padding: "0 4px" }}>
                      <Form.Item
                        name={["invoiceDetail", "purPayDate"]}
                        label="Payment Date"
                        rules={[
                          { required: true, message: "Payment date required" },
                        ]}
                        //className="inputboxsize inputboxmb"
                      >
                        <DatePicker
                          allowClear={false}
                          format={"DD/MM/YYYY"}
                          disabled={
                            paymentMode === PmtMethod.party ? true : false
                          }

                          // disabledDate={(current) =>
                          //   current && current > dayjs().endOf("day")
                          // }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                {isDepreciable && (
                  <>
                    <Card title="Depreciation Detail" size="small">
                      <Row>
                        <Col lg={24} md={24}>
                          <Form.Item
                            name="category"
                            label="Asset Category"
                            rules={[
                              {
                                required: true,
                                message: "Asset category is mandatory.",
                              },
                            ]}
                            //className="inputboxsize inputboxmb"
                          >
                            <TreeSelect
                              //className="inputboxsize"
                              treeNodeFilterProp="title"
                              showSearch
                              placeholder="Please select Asset Category"
                              allowClear
                              value={categoryValue}
                              treeDefaultExpandAll
                              style={{ marginBottom: "3px" }}
                              treeData={AssetCategoryOptions}
                              onChange={(cat: string) =>
                                onAssetCategoryChange(cat)
                              }
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: "auto",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={6} style={{ padding: "0 4px" }}>
                          <Form.Item
                            name="depMethod"
                            label="Depreciation Method"
                            rules={[
                              {
                                required: true,
                                message: "Please select depreciation method.",
                              },
                            ]}
                            //className="inputboxsize inputboxmb"
                          >
                            <Select
                              //className="inputboxsize"
                              style={{ marginBottom: "3px" }}
                              onChange={(val) => onAssetDepMethodChange(val)}
                              placeholder="Please select depreciation method"
                              //defaultValue={CalcMethod.WrittenDownValue}
                              options={[
                                {
                                  value: CalcMethod.StraightLine,
                                  label: "Straight Line Method",
                                },
                                {
                                  value: CalcMethod.WrittenDownValue,
                                  label: "Written Down Method",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={4} md={4} style={{ padding: "0 4px" }}>
                          <Form.Item
                            name="lifeCycleDuration"
                            label="Asset Life in year"
                          >
                            <Input onBlur={handleSLMDepRate} />
                          </Form.Item>
                        </Col>
                        <Col lg={4} md={4} style={{ padding: "0 4px" }}>
                          <Form.Item
                            name="depRate"
                            label="Depreciation Rate"
                            rules={[
                              {
                                required: true,
                                message: "Please input depreciation rate!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        {/* <Col lg={8} md={8} style={{ padding: "0 4px" }}>
                          <Form.Item
                            name="depreciationLedger"
                            label="Depreciation Ledger"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select depreciation Ledger!",
                            //   },
                            // ]}
                            //className="inputboxsize inputboxmb"
                          >
                            <Select
                              //className="inputboxsize"
                              showSearch
                              style={{ marginBottom: "3px" }}
                              options={depreciationOptions?.map(
                                (value: any) => ({
                                  value: value?.id,
                                  label: value?.ledgerName,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col> */}
                        <Col lg={4} md={4}>
                          <Form.Item
                            name="depreciationStartDate"
                            label="Depreciation Start Date"
                            rules={[
                              {
                                required: true,
                                message: "Depreciation start date is required",
                              },
                            ]}
                            //className="inputboxsize inputboxmb"
                          >
                            <DatePicker
                              allowClear={false}
                              format={"DD/MM/YYYY"}
                              // disabledDate={(current) =>
                              //   current && current > dayjs().endOf("day")
                              // }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </>
                )}

                <Card title="Other Detail" size="small">
                  <Row>
                    <Col lg={8} md={8} style={{ padding: "0 4px" }}>
                      <Form.Item
                        name="location"
                        label="Asset Location"
                        //className="inputboxsize inputboxmb"
                      >
                        <Select
                          //className="inputboxsize"
                          value={location}
                          style={{ marginBottom: "3px" }}
                          onChange={handleLocation}
                          options={[
                            {
                              value: 1,
                              label: "Office",
                            },
                            {
                              value: 2,
                              label: "Plant",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={8} style={{ padding: "0 4px" }}>
                      <Form.Item
                        name="residualValue"
                        label="Residual Value of Asset"
                      >
                        <Input
                          disabled={
                            depMethod === CalcMethod.StraightLine ? false : true
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={8}>
                      <Form.Item
                        name="warrantyExpDate"
                        label="Warranty / Guarantee Expiry Date"
                        //className="inputboxsize inputboxmb"
                      >
                        <DatePicker
                          allowClear={false}
                          format={"DD/MM/YYYY"}
                          // disabledDate={(current) =>
                          //   current && current < dayjs().startOf("day")
                          // }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                {/* <Row>
                  <Col lg={24} md={24}>
                    <Form.Item
                      name="category"
                      label="Category"
                      rules={[{ required: true, message: "Select Category" }]}
                      //className="inputboxsize inputboxmb"
                    >
                      <Select
                        style={{ width: "100%", marginBottom: "3px" }}
                        placeholder="Select Category"
                        allowClear
                        showSearch
                        labelInValue
                        optionFilterProp="children"
                        // filterOption={(input, option) =>
                        //   (option?.label ?? "")
                        //     .toLowerCase()
                        //     .includes(input.toLowerCase())
                        // }
                        //options={accounts}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} md={8}>
                    <Form.Item
                      name={["creditor", "paymentMethod"]}
                      label="PaymentMethod"
                      className="inputboxsize inputboxmb"
                    >
                      <Select
                        className="inputboxsize"
                        style={{ marginBottom: "3px" }}
                        onChange={(value: any) => {
                          setPaymentMethod(value);
                        }}
                        options={[
                          { value: PmtMethod.bank, label: "Bank" },
                          { value: PmtMethod.Supplier, label: "Supplier" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8}>
                    <Form.Item
                      name={["creditor", "refrence"]}
                      label="Suppliers"
                      rules={[{ required: true, message: "Select supplier" }]}
                      className="inputboxsize inputboxmb"
                    >
                      <Select
                        showSearch
                        labelInValue
                        popupMatchSelectWidth={false}
                        filterOption={false}
                        className="inputboxsize"
                        style={{ marginBottom: "3px" }}
                        options={supplierOptions?.map((value: any) => ({
                          value: value?.id,
                          label: value?.name,
                        }))}
                        onSearch={(value: string) =>
                          setSearchSupplier({ search: value, length: 10 })
                        }
                        placeholder="Select"
                      />
                    </Form.Item>
                  </Col>
                  {paymentMethod === PmtMethod.bank && (
                    <Col lg={8} md={8}>
                      <Form.Item
                        name="bankAccount"
                        label="Bank Account"
                        className="inputboxsize inputboxmb"
                      >
                        <Select
                          style={{ marginBottom: "3px" }}
                          placeholder="Select"
                          className="inputboxsize"
                          popupMatchSelectWidth={false}
                          labelInValue
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLocaleLowerCase)
                          }
                          options={bankAccount}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {isGstEnabled && (
                  <>
                    <Row>
                      <Col lg={8} md={8}>
                        <Form.Item
                          name={["creditor", "gst"]}
                          label="Gst"
                          className="inputboxsize inputboxmb"
                        >
                          <Select
                            className="inputboxsize"
                            style={{ marginBottom: "3px" }}
                            labelInValue
                            options={gstOption?.map((gst: any) => ({
                              key: gst.rate,
                              value: gst.id,
                              label: gst.name,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <Col lg={19} md={24}>
                    <Form.Item
                      name="description"
                      label="Description"
                      className="inputboxmb"
                    >
                      <Input
                      // addonAfter={
                      //   <Upload
                      //     {...props}
                      //     maxCount={1}
                      //     beforeUpload={() => {
                      //       return false;
                      //     }}
                      //   >
                      //     <Button icon={<PaperClipOutlined />}></Button>
                      //   </Upload>
                      // }
                      // className="filetype"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "15px" }}>
                  <Form.Item
                    name="isDepreciable"
                    valuePropName="checked"
                    labelCol={{ lg: 24 }}
                    wrapperCol={{ lg: 24 }}
                    className="inputboxmb"
                  >
                    <span style={{ paddingRight: "30px" }}>
                      Depreciable Asset
                    </span>

                    <Switch
                      checked={isDepreciable}
                      onChange={(isChecked) => setDepreciable(isChecked)}
                    />
                  </Form.Item>
                  <Divider
                    className="botomline"
                    style={{ background: " rgb(214, 214, 214)" }}
                  />
                </Row>
                {isDepreciable && (
                  <>
                    <Row>
                      <b style={{ paddingBottom: "10px" }}>Depreciation</b>
                    </Row>
                    <Row>
                      <Col lg={9} md={9}>
                        <Form.Item
                          name="accountGroupType"
                          label="P&L account type"
                          className="inputboxmb"
                        >
                          <Select
                            className="inputboxsize"
                            style={{ marginBottom: "3px" }}
                            popupMatchSelectWidth={false}
                            options={[
                              {
                                value: PnLAccountGroups.AdministrativeExpenses,
                                label: "Administrative expenses",
                              },
                              {
                                value: PnLAccountGroups.CostofSales,
                                label: "Cost of Sales",
                              },
                              {
                                value:
                                  PnLAccountGroups.SellingAndDistributionCosts,
                                label: "Selling and distribution costs",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8}>
                        <Form.Item
                          name="method"
                          label="Method"
                          className="inputboxmb"
                        >
                          <Select
                            className="inputboxsize"
                            popupMatchSelectWidth={false}
                            style={{ marginBottom: "3px" }}
                            options={[
                              {
                                value: CalcMethod.WrittenDownValue,
                                label: "Written Down value",
                              },
                              {
                                value: CalcMethod.StraightLine,
                                label: "Straight Line",
                              },
                            ]}
                            onChange={(value: any) => {
                              value === 0
                                ? setMethodValue("Rate")
                                : setMethodValue("Year");
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={7} md={7}>
                        <Form.Item
                          name={methodValue === "Rate" ? "rate" : "year"}
                          label={methodValue}
                          className="inputboxmb"
                          rules={[
                            {
                              required: true,
                              message: `Please input ${
                                methodValue === "Rate" ? "rate" : "year"
                              }!`,
                            },
                          ]}
                        >
                          <InputNumber
                            controls={false}
                            className="inputboxsize"
                            maxLength={2}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={9} md={9}>
                        <Form.Item
                          name="startDate"
                          label="Start Date"
                          rules={[
                            { required: true, message: "Date is required" },
                          ]}
                          className="inputboxsize inputboxmb"
                        >
                          <DatePicker
                            style={{ width: "140px" }}
                            format={"DD/MM/YYYY"}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={8} md={8}>
                        <Form.Item
                          name="accumulatedDep"
                          label="Accumulated dep."
                          className="inputboxmb"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message:
                          //       "Can't fill the geater then and equal of a purchase amt. ",
                          //   },
                          // ]}
                        >
                          <Input
                            className="textEnd"
                            disabled
                            onBlur={(e) =>
                              form.setFieldValue(
                                "accumulatedDep",
                                Utils.getFormattedNumber(
                                  e.target.value
                                    ? Utils.getNumber(e.target.value)
                                    : 0
                                )
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider
                      className="botomline"
                      style={{ background: " rgb(214, 214, 214)" }}
                    />
                  </>
                )} */}
              </div>
            </Form>
          </Spin>
        </div>
      </Modal>
      {isConfirmation && (
        <ConfirmationModal
          open={isConfirmation}
          text={
            "All details filled will be lost, are you sure you want to continue?"
          }
          onNo={() => setIsConfirmation(false)}
          onYes={() => {
            setIsConfirmation(false);
            setIsModalOpen(false);
            form.resetFields();
          }}
        />
      )}
      {openModel.type === "addGroup" && (
        <AddGroup
          open={true}
          editData={openModel?.param}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setGroupListTrigger(!groupListTrigger)}
          groupData={groupData}
        />
      )}
      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={true}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() =>
            onPaymentModeChange(form.getFieldValue("payMethod"))
          }
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )}
    </div>
  );
};

export default AssetModel;
