import { CloseOutlined, FormOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useContext } from "react";
import { useClient } from "../../Context/ClientContext";
import { SettingsSections } from "../../Services/SettingService";
import { notificationContext } from "../../Common/PageRoute";
import { dayjs } from "../../../Utilities/dayjs";
import { GSTTypeOptions } from "../Clients/Clients";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";

const SettingsGst = () => {
  const [form] = Form.useForm();
  const [disable, setDisable] = React.useState<boolean>(true);
  const { companySettings, updateCompanySettings } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [gSTTypeValue, setGSTTypeValue] = React.useState<CompanyGSTTypes>(
    companySettings?.setting?.gstDetails?.scheme
      ? companySettings?.setting?.gstDetails?.scheme
      : CompanyGSTTypes.Unregistered
  );
  React.useEffect(() => {
    const set = companySettings?.setting;
    console.log("set", set?.gstDetails);

    const gst = set?.gstDetails?.gst?.map((itm: any) => {
      return {
        title: itm?.rate,
        label: itm?.name,
        value: itm?.id,
      };
    });
    const formData = {
      ...set,
      gstDetails: {
        ...set?.gstDetails,
        gstRegDateString: set?.gstDetails?.gstRegDateString
          ? dayjs(set?.gstDetails?.gstRegDateString, "DD/MM/YYYY")
          : "",
        gst: gst,
      },
    };
    form.setFieldsValue(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettings]);

  return (
    <>
      <div style={{ paddingTop: "0px 0px 3px 0px", width: "40%" }}>
        <Row justify="space-between">
          <Col className="fs-15 fw-600">GST</Col>
          <Col>
            {disable && (
              <Tooltip title="Edit">
                <FormOutlined
                  className="ca-edit-btn"
                  style={{ color: "rgb(106 119 227)" }}
                  onClick={() => {
                    setDisable(false);
                  }}
                />
              </Tooltip>
            )}
          </Col>
        </Row>
        <Divider className="my-12" />
        <Form
          form={form}
          disabled={disable}
          autoComplete="off"
          name="Gst"
          onFinish={(val) => {
            const gst = val?.gstDetails?.gst?.map((itm: any) => {
              return {
                id: itm?.value,
                name: itm?.label,
                rate: itm?.title,
              };
            });

            const data = {
              gstDetails: {
                ...val?.gstDetails,
                gstRegDateString: dayjs(
                  val?.gstDetails?.gstRegDateString
                ).format("DD/MM/YYYY"),
                gst: gst,
                // isGSTEnabled: showData,
              },
            };
            // console.log("data", data);

            setSaveButtonLoading(true);
            updateCompanySettings!(
              SettingsSections.GstDetails,
              {
                ...companySettings,
                setting: {
                  ...companySettings?.setting!,
                  gstDetails: data?.gstDetails,
                },
              },
              data
            )
              .then((result: any) => {
                if (result?.status) {
                  setDisable(true);
                  openNotification("success", "Setting Updated successfully");
                  setSaveButtonLoading(false);
                } else {
                  openNotification("error", "there is some error");
                  setSaveButtonLoading(false);
                }
              })
              .catch((ex: any) => {
                setSaveButtonLoading(false);
                openNotification("error", ex.message);
              });
          }}
        >
          <Row>
            <Col lg={8}>GST Type</Col>
            <Col lg={16}>
              <Form.Item
                name={["gstDetails", "scheme"]}
                className="inputBoxMb8 inputLabel33"
                rules={[
                  {
                    required: true,
                    message: "Please select the appropriate GST Type",
                  },
                ]}
              >
                <Select
                  onChange={(val) => {
                    setGSTTypeValue(val);
                  }}
                  options={GSTTypeOptions}
                />
              </Form.Item>
            </Col>
            {gSTTypeValue === CompanyGSTTypes.Regular && (
              <>
                <Col lg={8}>Registration Date</Col>
                <Col lg={16}>
                  <Form.Item
                    className="mb-10"
                    name={["gstDetails", "gstRegDateString"]}
                    rules={[
                      {
                        required: true,
                        message: "Please input Date!",
                      },
                    ]}
                  >
                    <DatePicker format={"DD/MM/YYYY"} />
                  </Form.Item>
                </Col>

                <Col lg={8}>GSTIN</Col>
                <Col lg={16} md={8} sm={12} xs={24}>
                  <Form.Item
                    name={["gstDetails", "registrationNo"]}
                    className="inputBoxMb8 inputLabel33"
                    rules={[
                      {
                        required: true,
                        pattern:
                          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                        message: "Enter Valid GST Number",
                      },
                    ]}
                  >
                    <Input
                      placeholder="00AABCC1234D1ZZ"
                      allowClear={true}
                      onChange={(e: any) => {
                        const capitalvalue = e.target.value.toUpperCase();
                        form.setFieldValue("gstin", capitalvalue);
                      }}
                      // onBlur={handlePANNo}
                      // disabled={
                      //   gSTTypeValue === CompanyGSTTypes.Unregistered
                      //     ? true
                      //     : false
                      // }
                      // onChange={(e: any) => {
                      //   if (e.target.value) setTouched(true);
                      //   else setTouched(false);
                      // }}
                    />
                  </Form.Item>
                </Col>

                <Col lg={8}>Sales GST Options</Col>
                <Col lg={16}>
                  <Form.Item
                    className="mb-10"
                    name={["gstDetails", "gst"]}
                    rules={[
                      {
                        required: true,
                        message: "Please input GST Options.",
                      },
                    ]}
                  >
                    <Select
                      labelInValue
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select GSTs"
                      onChange={(val) => console.log(val)}
                      options={companySettings?.gsts?.map((gst: any) => ({
                        title: gst?.rate,
                        value: gst?.id,
                        label: gst?.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Row justify="end" style={{ height: 32 }}>
            {!disable && (
              <Space>
                <Button
                  icon={<CloseOutlined />}
                  style={{ backgroundColor: "aliceblue" }}
                  onClick={() => {
                    setDisable(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  htmlType="submit"
                  loading={saveButtonLoading}
                >
                  Save
                </Button>
              </Space>
            )}
          </Row>
        </Form>
      </div>
    </>
  );
};

export default SettingsGst;
