import React, { useContext, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Space,
  Tag,
  Tooltip,
  Skeleton,
  Progress,
  notification,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  DownloadOutlined,
  FileTextOutlined,
  RetweetOutlined,
  EditOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import noteContext from "../../Context/StyleContext";
import SalesService, {
  DBRecordStatus,
  EstimateStatus,
} from "../../Services/SalesService";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import { FiDownload } from "react-icons/fi";
import { HiDotsHorizontal } from "react-icons/hi";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import VoucherServices, { Voucher_Type } from "../../Services/VoucherServices";
import ViewEstimateModel from "./ViewEstimateModel";
import { number } from "echarts";
import QuotationServices from "../../Services/QuotationServices";
import { percentCal } from "../../Common/CAPComponents";

export interface ISalesOrderListData {
  key: React.Key;
  id: string;
  number: string;
  // invoices: { id: string; name: string };
  customerName: { id: string; name: string };
  name: string;
  date: string;
  suppliedQty: number;
  totalQty: number;
  invAmount: number;
  totalInvoiceAmount: number;
  dueBalance: number;
  deleteType: number;
}

interface ISalesInvoiceProps {
  salesOrderList?: any;
  setSalesOrderList: (value: any) => void;
  LoadSkeleton?: boolean;
  setLoadSkeleton: (value: boolean) => void;
  partyId?: string;
}

const OrderTable = (props: ISalesInvoiceProps) => {
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const navigate = useNavigate();
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [salesOrderList, setSalesOrderList] = useState<ISalesOrderListData[]>(
    []
  );
  const [openPopArray, setOpenPopArray] = useState<boolean[]>([]);
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [estimateStatus, setEstimateStatus] = React.useState<number>(0);
  const [deleteEstimate, setDeleteEstimate] = React.useState<any>({
    status: DBRecordStatus.Active,
    open: false,
    name: "",
  });
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [estimateId, setEstimateId] = React.useState<string>("");
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [messageShow, setMessageShow] = React.useState<boolean>(true);
  const [confirmationModel, setConfirmationModel] =
    React.useState<boolean>(false);
  const [refresh, setRefresh] = React?.useState(false);

  let splitPathInVchType: Voucher_Type =
    location.pathname.split("/")[4] === "sales"
      ? Voucher_Type.SalesOrder
      : Voucher_Type.PurchaseOrder;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //-------- Estimate Delivery Challan --------
  // React.useEffect(() => {
  //   if (deleteEstimate.open) {
  //     SalesService.deleteSalesOrder(
  //       currentClient?.id!,
  //       estimateId,
  //       deleteEstimate.status
  //     )
  //       .then((res: any) => {
  //         if (res?.result) {
  //           setTrigger((x) => !x);
  //           console.log(res?.result);
  //           openNotification(
  //             "success",
  //             `${deleteEstimate.status === 1 ? "Delete" : "Active"} SalesOrder`
  //           );
  //           setDeleteEstimate({});
  //         }
  //       })
  //       .catch((ex: any) => {
  //         setDeleteEstimate({});
  //         console.error("Delete Api Error", ex);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deleteEstimate.open]);

  // --------- Table Columns  ---------
  const columns: ColumnsType<ISalesOrderListData> = [
    {
      title:
        splitPathInVchType === Voucher_Type.SalesOrder ? "SO. No." : "PO. No.",
      dataIndex: "number",
      width: width <= 1440 ? "10%" : "9%",
      render: (_, record: ISalesOrderListData) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              setIsOpen(true);
              setInvoiceId(record?.id);
            }}
          >
            {record?.number}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "10%",
      sorter: true,
      key: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Total Qty",
      dataIndex: "totalQty",
      align: "right",
      width: "8%",
      key: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.totalQty}
        </Skeleton>
      ),
    },
    {
      title:
        splitPathInVchType === Voucher_Type.SalesOrder
          ? "Supplied Qty."
          : "Received Qty",
      dataIndex: "suppliedQty",
      align: "right",
      width: "9%",
      key: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.suppliedQty}
        </Skeleton>
      ),
    },
    {
      title: "Pending Qty",
      dataIndex: "suppliedQty",
      width: "9%",
      key: "suppliedQty",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.totalQty - record?.suppliedQty}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "invAmount",
      sorter: true,
      align: "right",
      key: "invAmount",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          ₹ {Utils.getFormattedNumber(record?.invAmount)}
        </Skeleton>
      ),
    },
    {
      title: "Status",
      dataIndex: "address",
      width: "7%",
      // align: "center",
      render: (_, record) => (
        <>
          <Progress
            style={{ padding: "0px", margin: "0px" }}
            percent={
              Math.round((record?.suppliedQty / record?.totalQty) * 100) * 100
            }
          />
        </>
      ),
    },
    {
      title: "   ",
      dataIndex: "icon",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          <FiDownload style={{ color: "#395cd2" }} />
        </Skeleton>
      ),
    },
    {
      title: "   ",
      dataIndex: "icon2",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <div>
          {/* {record?.invoices?.id || record.deleteType === 1 ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tooltip title="Not Editable" placement="top" color="gray">
                <EditOutlined
                  // className="ca-edit-btn"
                  style={{ color: "rgb(216 207 207)" }}
                />
              </Tooltip>
            </Skeleton>
          ) : ( */}
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <Tooltip title="Edit" placement="top" color="#3D4998">
              <Link
                to={`/accounts/clients/${currentClient?.id}/${
                  splitPathInVchType === Voucher_Type.SalesOrder
                    ? "sales/editSalesOrder"
                    : "purchase/editPurchaseOrder"
                }/${record?.id}`}
              >
                <EditOutlined
                  className="ca-edit-btn"
                  onClick={() =>
                    navigate(
                      `/accounts/clients/${currentClient?.id}/${
                        splitPathInVchType === Voucher_Type.SalesOrder
                          ? "sales/editSalesOrder"
                          : "purchase/editPurchaseOrder"
                      }/${record?.id}`
                    )
                  }
                  style={{ color: "#444653" }}
                />
              </Link>
            </Tooltip>
          </Skeleton>
          {/* )} */}
        </div>
      ),
    },
    {
      title: "   ",
      dataIndex: "icon3",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <div>
          {/* {record?.invoices?.id || record.deleteType === 1 ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tooltip title="Create Invoice" placement="top" color="gray">
                <FileTextOutlined style={{ color: "rgb(216 207 207)" }} />
              </Tooltip>
            </Skeleton>
          ) : ( */}
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            {record?.totalQty - record?.suppliedQty > 0 ? (
              <Tooltip title="Create Invoice" placement="top" color="#64CA31">
                <Link
                  // splitPathInVchType
                  to={`/accounts/clients/${currentClient?.id}/${
                    splitPathInVchType === Voucher_Type.SalesOrder
                      ? "sales/addInvoice"
                      : "purchase/addPurchaseBill"
                  }/order/${record.id}`}
                >
                  <FileTextOutlined style={{ color: "#868ea5" }} />
                </Link>
              </Tooltip>
            ) : (
              <Tooltip title="Complete Order" placement="top">
                <FileTextOutlined style={{ color: "green" }} />
              </Tooltip>
            )}
          </Skeleton>
          {/* )} */}
        </div>
      ),
    },
    {
      title: "   ",
      dataIndex: "icon3",
      width: "3%",
      align: "center",
      render: (_, record, index) => (
        <div>
          {/* {!record?.id && record.deleteType === 0 && ( */}
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <Tooltip title="Delete" placement="top" color="red">
              <DeleteTwoTone
                className="ca-delete-btn"
                twoToneColor="#ff879d"
                onClick={() => {
                  setConfirmationModel(true);
                  setEstimateId(record.id);
                  // deleteData(record?.id);
                  setDeleteEstimate({
                    ...deleteEstimate,
                    status: DBRecordStatus.Deleted,
                    name: record.number,
                  });
                  handleOpenChange(index, false);
                }}
              />
            </Tooltip>
          </Skeleton>
          {/* )} */}
          {!record?.id && record.deleteType === 1 && (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tooltip title="Revive Sales Order" placement="top" color="green">
                <RetweetOutlined
                  className="ca-invoice-btn"
                  style={{ color: "#64CA31" }}
                  onClick={() => {
                    setConfirmationModel(true);
                    setEstimateId(record.id);
                    setDeleteEstimate({
                      ...deleteEstimate,
                      status: DBRecordStatus.Active,
                      name: record.number,
                    });
                  }}
                />
              </Tooltip>
            </Skeleton>
          )}
        </div>
      ),
    },
  ];

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Party Name",
      dataIndex: "customerName",
      width: "25%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.customerName?.name}
        </Skeleton>
      ),
    });
  }

  const handleOpenChange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenPopArray(updatedOpenPopArray);
  };

  const deleteData = async (id: string) => {
    let res = await QuotationServices?.deleteData(currentClient?.id, id);
    if (res?.result) {
      setRefresh((x: boolean) => !x);
      notification.success({
        message: "",
        description: "Deleted successfully.",
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "",
        description: res?.message || "An error occurred while Delete.",
        placement: "topRight",
      });
    }
  };

  // --------- Get SalesOrder TableData API ---------

  // --------- Get TableData API ---------
  React.useEffect(() => {
    if (currentClient?.id) {
      setLoading(true);
      getOrderList(
        currentClient?.id,
        splitPathInVchType,
        (page - 1) * pageSize,
        pageSize,
        searchCustomer,
        filterDate.from,
        filterDate.to,
        filter.sortCol,
        filter.sortDir
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    filterDate.from,
    filterDate.to,
    searchCustomer,
    refresh,
  ]);

  const getOrderList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    start: number,
    length: number,
    search?: string,
    fromDate: string = "",
    toDate: string = "",
    status?: string,
    sortCol?: string,
    sortDir?: string
  ) => {
    setLoading(true);
    await QuotationServices.getQuotationList(
      clientId,
      Voucher_Type,
      start,
      length,
      search,
      fromDate,
      toDate,
      status,
      sortCol,
      sortDir
    )
      .then((res: any) => {
        if (res?.result?.voucherItems?.length > 0) {
          console.log("res.items = ", res?.result?.voucherItems);
          // table list data
          setSalesOrderList(
            res?.result?.voucherItems?.map((v: any, index: number) => {
              return {
                key: index,
                id: v?.vId,
                date: dayjs(v?.date).format("DD/MM/YYYY"),
                number: v?.number,
                customerName: {
                  id: v?.pLedger[0].id,
                  name: v?.pLedger[0]?.ledgerName,
                },
                invAmount: v?.invAmount,
                totalQty: v?.totalQty,
                suppliedQty: v?.suppliedQty,
                refundAmount: v?.refundAmount,
                receivedAmount: v?.receivedAmount,
                dueBalance: v?.dueAmount,
              };
            })
          );
          //-----For Quotation/Estimate Graphs-----//
          props?.setSalesOrderList({
            totalInvoiceAmount: res?.result?.totalAmount,
            suppliedQty: res?.result?.totalSuppliedQty,
            totalQty: res?.result?.totalQty,
            pending: res?.result?.totalQty - res?.result?.totalSuppliedQty,
            totalReceivedAmount:
              res?.result?.totalSuppliedQty +
              res?.result?.totalQty -
              res?.result?.totalSuppliedQty,
          });
          setTotalRecords(res?.totalRecords);
        } else if (res?.result?.voucherItems?.length === 0) {
          setSalesOrderList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setSalesOrderList([]);
      });
  };

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "AutoNumber",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", filter, filter.column);
  };

  //----- Short-Cut for Sales Order -----
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    splitPathInVchType === Voucher_Type.SalesOrder
      ? navigate(`/accounts/clients/${currentClient?.id}/sales/addOrder`)
      : navigate(`/accounts/clients/${currentClient?.id}/purchase/addOrder`);
  });

  //----- Short-Cut for Sales Order TimeCondition -----
  setTimeout(() => {
    setMessageShow(false);
    // console.log("timeout");
  }, 6000);

  const exportCsv = () => {
    QuotationServices.exportCSVQuotation(
      currentClient?.id,
      splitPathInVchType,
      0,
      pageSize,
      filterDate.from,
      filterDate.to,
      filter.sortCol,
      filter.sortDir,
      undefined,
      undefined,
      searchCustomer
      // invoiceStatus
    )
      .then((res: any) => {
        if (res) {
          console.log("Export sucess");
        }
      })
      .catch((ex: any) => {
        openNotification("error", ex.message);
      });
  };

  return (
    <>
      {/* <Card
    //   size="small"
    //   className="TableCard"
    //   style={{
    //     marginTop: "6px",
    //     padding: "48px 0px 0px 0px",
    //     boxShadow: "0px 0px 10px 0px #96A1C84D",
    //   }}
    // > */}
      <Row
        justify="space-between"
        style={{
          // paddingTop: props?.partyId ? "10px" : "0px"
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col>
          <Space size={width <= 768 ? 440 : 5}>
            <Button
              type="primary"
              id="gfg"
              icon={<PlusOutlined />}
              onClick={() => {
                splitPathInVchType === Voucher_Type.PurchaseOrder
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addOrder`
                    )
                  : props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addOrder/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addOrder`
                    );
              }}
            >
              {splitPathInVchType === Voucher_Type.SalesOrder
                ? "Sales Order"
                : "Purchase Order"}
            </Button>
            {messageShow && (
              <>
                <Col style={{ paddingLeft: "10px" }}>
                  <Tag
                    style={{
                      // color: "#ced4d9",
                      fontWeight: 500,
                      borderColor: "#64646433",
                      backgroundColor: "#9696960f",
                    }}
                  >
                    Shift+N
                  </Tag>
                </Col>
              </>
            )}
            <Button
              type="default"
              icon={<DownloadOutlined />}
              onClick={() => exportCsv()}
            >
              Export
            </Button>
          </Space>
        </Col>

        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row gutter={width <= 768 ? 63 : 8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search..."
                allowClear
                onChange={(e: any) => {
                  setLoading(true);
                  if (!e.cancelable) {
                    const data = setTimeout(() => {
                      setPage(1);
                      setSearchCustomer(e.target.value);
                    }, 1000);
                    return () => clearTimeout(data);
                  } else {
                    setSearchCustomer(e.target.value);
                  }
                }}
                suffix={
                  loading || searchCustomer ? (
                    ""
                  ) : (
                    <img alt="search" src={search} />
                  )
                }
              />
            </Col>
            <Col>
              <RangePicker
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  console.log(e);
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            {/* <Col>
              <Select
                style={{ width: windowWidth === 1024 ? 80 : 120 }}
                value={estimateStatus}
                onSelect={(e) => {
                  setPage(1);
                  setEstimateStatus(e);
                }}
                options={[
                  { value: EstimateStatus.All, label: "All" },
                  { value: EstimateStatus.Active, label: "Active" },
                  { value: EstimateStatus.Pending, label: "Pending" },
                  { value: EstimateStatus.Accpected, label: "Accpected" },
                  { value: EstimateStatus.Deleted, label: "Deleted" },
                ]}
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        loading={false}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        scroll={
          pageSize > 10
            ? { x: width <= 768 ? 1000 : 700, y: 370 }
            : { x: width <= 768 ? 1000 : 600 }
        }
        rowSelection={rowSelection}
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as ISalesOrderListData[])
            : salesOrderList
        }
        onChange={tableSort}
        summary={() => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row
                style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
              >
                <Table.Summary.Cell
                  index={1}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="fw600">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={4}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} className="fw600" align="right">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="fw600" align="right">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} className="fw600" align="right">
                  ₹{" "}
                  {Utils.getFormattedNumber(
                    props?.salesOrderList?.totalInvoiceAmount
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        // style={{ overflowX: "auto" }}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />
      {open && (
        <ViewEstimateModel
          open={open}
          onCancel={() => setIsOpen(false)}
          estimateId={invoiceId}
          voucharType={splitPathInVchType}
        />
      )}
      {confirmationModel && (
        <ConfirmationModal
          open={confirmationModel}
          text={`Do you really want to ${
            deleteEstimate.status === 1 ? "Delete" : "Active"
          }  this SalesOrder"${deleteEstimate.name}"?`}
          onNo={() => setConfirmationModel(false)}
          onYes={() => {
            setConfirmationModel(false);
            deleteData(estimateId);
          }}
        />
      )}
    </>
  );
};
export default OrderTable;
