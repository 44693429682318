/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
// import { notificationContext } from "../../Common/PageRoute";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { useLocation } from "react-router-dom";

import { dayjs } from "../../../Utilities/dayjs";
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Utils } from "../../../Utilities/Utils";
import VoucherServices, {
  Party_Tax,
  Voucher_Type,
} from "../../Services/VoucherServices";
import noteContext from "../../Context/StyleContext";
import deleteImg from "../../../images/deletebutton.svg";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import { AddLedger } from "../Master/Ledger/AddLedger";
import TextArea from "antd/es/input/TextArea";
import ImportLedger from "../Settings/ImportLedger";
import TrialBalance from "../Reports/TrialBalance";

const AddJournalNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { journalId } = useParams();
  const { currentClient } = useClient();
  const { windowWidth } = React.useContext(noteContext);
  const { openNotification } = React.useContext(notificationContext);
  // const [itemList, setItemList] = useState<any[]>([]);sadasdadsds
  const [form] = Form.useForm();
  //   const [accountLedgerOption, setAccountLedgerOption] = React.useState<any[]>(
  //     []
  //   );
  const [partyLedgerOption, setPartyLedgerOption] = React.useState<any[]>([]);
  const [selectedPartyOption, setSelectedPartyOption] = React.useState<any[]>(
    []
  );
  const [trigger, setTrigger] = React?.useState(false);
  const [openModel, setOpenModel] = React?.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [deleteItemList, setDeleteItemList] = React.useState<{
    name: any;
    index: number;
  }>({
    name: "",
    index: -1,
  });
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  //   const [currentBalance, setCurrentBalance] = React.useState<number>(0);
  const [accountIsParty, setAccountIsParty] = React.useState<Party_Tax>();
  const [partyRef, setPartyRef] = React.useState<any[]>([]);
  const [selectPartyRef, setSelectPartyRef] = React.useState<any[]>([[]]);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [totalPaymentIn, setTotalPaymentIn] = React.useState<number>(0);
  const [disabledAmountInputs, setDisabledAmountInputs] = React.useState(
    Array(0).fill(false)
  );

  const [allUpdatePartyData, setAllUpdatePartyData] = React.useState<any[]>([]);
  const [debitSubTotal, setDebitSubTotal] = React.useState<number>(0);
  const [creditSubTotal, setCreditSubTotal] = React.useState<number>(0);
  const [dateFiled, setDateFiled] = React.useState<any>();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [importModal, setImportModal] = React.useState<any>({
    type: "",
    param: {},
  });

  console.log("importData", location.state?.importData);

  useEffect(() => {
    if (location?.state?.importData) {
      const formattedData = location.state.importData.map((item: any) => ({
        id: item.ledgerId,
        credit: item?.credit,
        debit: item?.debit,
      }));
      form.setFieldsValue({
        partyLedger: formattedData,
      });

      const debitTotal = location.state.importData.reduce(
        (total: any, item: any) => total + item.debit,
        0
      );
      setDebitSubTotal(debitTotal);
      const creditTotal = location.state.importData.reduce(
        (total: any, item: any) => total + item.credit,
        0
      );
      setCreditSubTotal(creditTotal);
    }
  }, [location, form]);

  // Date Api
  React.useEffect(() => {
    const getDate = async () => {
      if (currentClient?.id && !journalId) {
        await VoucherServices.getMaxVoucherDate(
          currentClient?.id!,
          Voucher_Type.Journal
        ).then((res) => {
          const givenDate = new Date(res?.result);
          const currentTime = new Date();

          // Set the time part of givenDate to match currentTime
          givenDate?.setHours(
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds(),
            currentTime.getMilliseconds()
          );
          form.setFieldValue("date", dayjs(givenDate));

          setDateFiled(res.result);
        });
      }
    };
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  // ----- initial value -----
  React.useEffect(() => {
    if (currentClient?.id && !journalId && dateFiled) {
      setLoading(true);
      VoucherServices.getVoucherMaxNo(
        currentClient?.id!,
        Voucher_Type.Journal,
        dateFiled
      )
        .then((res) => {
          form.setFieldValue("number", res?.result);
          if (location?.state?.trialBalanceData) {
            // const trialBalanceData = location.state.trialBalanceData;
            const trialBalanceData = location?.state?.trialBalanceData?.map(
              (led: any) => {
                return {
                  id: led?.ledgerId,
                  debit: led?.debit,
                  credit: led?.credit,
                  title: led?.partyOrTax,
                };
              }
            );
            form?.setFieldsValue({
              partyLedger: trialBalanceData,
            });
            const debitTotal = trialBalanceData.reduce(
              (total: any, item: any) => total + item.debit,
              0
            );
            setDebitSubTotal(debitTotal);
            const creditTotal = trialBalanceData.reduce(
              (total: any, item: any) => total + item.credit,
              0
            );
            setCreditSubTotal(creditTotal);
          }
          setLoading(false);
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err.message);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, dateFiled]);

  // party & other payment select
  React.useEffect(() => {
    setLoading(true);
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes.All
    ).then((res) => {
      if (res) {
        setPartyLedgerOption(res);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // post (save) jornal api
  const save = () => {
    form.validateFields().then(async (val: any) => {
      console.log("post", val);
      setSaveButtonLoading(true);

      const effectiveLedger: any[] = [];
      const partyLedger: any[] = [];
      val?.partyLedger?.map((x: any) => {
        if (parseFloat(x?.debit) > 0) {
          effectiveLedger.push({
            ...x,
            amount: x?.debit,
            id: x?.id?.value,
            partyOrTax: x?.id?.title,
            isDebitNature: true,
            ref:
              x?.ref?.length > 0
                ? x?.ref.map((x: any) => {
                    return {
                      ...x,
                      name: x?.name?.label ?? x?.name,
                      partyRef: x?.name?.value,
                    };
                  })
                : null,
          });
        } else {
          partyLedger.push({
            ...x,
            amount: x?.credit,
            id: x?.id?.value,
            partyOrTax: x?.id?.title,
            isDebitNature: false,
            ref:
              x?.ref?.length > 0
                ? x?.ref.map((x: any) => {
                    return {
                      ...x,
                      name: x?.name?.label ?? x?.name,
                      partyRef: x?.name?.value,
                    };
                  })
                : null,
          });
        }
      });

      const givenDate = new Date(val.date);
      const currentTime = new Date();

      // Set the time part of givenDate to match currentTime
      givenDate?.setHours(
        currentTime.getHours(),
        currentTime.getMinutes(),
        currentTime.getSeconds(),
        currentTime.getMilliseconds()
      );

      const data = {
        ...val,
        amount: totalPaymentIn,
        date: dayjs(givenDate).toISOString(),
        isLocked: false,
        isReconciled: false,
        isAudited: false,
        isActive: true,
        partyLedger: partyLedger,
        effectiveLedger: effectiveLedger,
      };

      // console.log("post data", data);

      await VoucherServices.postVoucher(
        currentClient?.id!,
        Voucher_Type.Journal,
        data,
        journalId
      )
        .then((res) => {
          if (res?.status) {
            openNotification(
              "success",
              `Journal ${journalId ? " Edit successfully" : "Add successfully"}`
            );
            navigate(-1);
            setSaveButtonLoading(false);
          } else if (res?.message) {
            openNotification("error", res?.message);
            setSaveButtonLoading(false);
          }
        })
        .catch((ex) => {
          setSaveButtonLoading(false);
          openNotification("error", ex);
        });
    });
  };

  const onClickSelectPartyLedger = (value: string, index: number) => {
    // console.log("x", value, index);
    setSelectedPartyOption((prevState) => {
      const updatedSelectedOptions = [...prevState];
      updatedSelectedOptions[index] = value;
      // console.log(updatedSelectedOptions);
      return updatedSelectedOptions;
    });
  };

  const partySelect: any = async (
    partyId: string,
    index: number,
    title?: Party_Tax
  ) => {
    // if (!journalId) {
    setSelectPartyRef((prevDataArray) => {
      const newArray = [...prevDataArray];
      if (index < 0 || index >= newArray.length) {
        newArray.push([]);
      } else {
        newArray.splice(index, 1, []);
      }
      return newArray;
    });

    if (title === Party_Tax.party) {
      // update case if user delete reference & again add same reference
      if (journalId) {
        let onePartyUpdateData = allUpdatePartyData.find(
          (x) => x.id.value === partyId
        );
        console.log("onePartyUpdateData", onePartyUpdateData);

        if (onePartyUpdateData) {
          // yadi user ne pahle hi add kar raha & deleter kar ke again add
          form.setFieldValue(["partyLedger", index], {
            ...onePartyUpdateData,
          });

          setSelectPartyRef((prevState: any[]) => {
            const updatedSelectedOptions: any[] = [...prevState];
            updatedSelectedOptions[index] = [...(prevState[index] || [])];
            updatedSelectedOptions[index] = onePartyUpdateData?.ref?.map(
              (x: any) => x?.parentRef
            );
            return updatedSelectedOptions;
          });

          setPartyRef((prevDataArray) => {
            const newArray = [...prevDataArray];
            newArray[index] = onePartyUpdateData?.ref?.map((x: any) => {
              return {
                ...x,
                maxAmount: x?.dueAmount + x?.value,
              };
            });
            return newArray;
          });
        }

        console.log("allUpdatePartyData", onePartyUpdateData);
      }

      await VoucherServices.getReferencesByParty(
        currentClient?.id!,
        partyId,
        Voucher_Type.Journal
      ).then((res: any) => {
        console.log("result", res.result?.length, index);
        if (res.result?.length > 0) {
          const data = res.result?.map((v: any, i: number) => {
            return {
              name: res.result[i]?.refNo,
              maxAmount: res.result[i]?.dueAmount,
              dueAmount: res.result[i]?.dueAmount,
              // id: res.result[i]?.id,
              parentRef: res.result[i]?.id,
              id: null,
              refType: 1,
            };
          });

          if (
            journalId &&
            allUpdatePartyData.find((x) => x.id?.value === partyId)
          ) {
            setPartyRef((prevDataArray) => {
              const prevArray = prevDataArray[index];
              // Filter out the common elements based on 'parentRefId'
              const filteredData = data.filter((newItem: any) => {
                return !prevArray.some(
                  (prevItem: any) => prevItem?.parentRef === newItem?.parentRef
                );
              });
              const updatedArray = [...prevArray, ...filteredData];
              console.log("newArray", updatedArray);
              const newArray = [...prevDataArray];
              newArray[index] = updatedArray;
              return newArray;
            });
          } else {
            // debugger;
            form.setFieldValue(["partyLedger", index, "ref"], [{}]);

            form.setFieldValue(["partyLedger", index, "ref", 0], {
              name: form.getFieldValue(["number"]),
              amount: 0,
              refType: 0,
            });

            form.setFieldValue(["partyLedger", index, "ref", 1], {});

            setPartyRef((prevDataArray) => {
              const newArray = [...prevDataArray];
              newArray[index] = data;
              return newArray;
            });
          }
        } else {
          form.setFieldValue(["partyLedger", index, "ref"], [{}]);
          form.setFieldValue(["partyLedger", index, "ref", 0], {
            name: form.getFieldValue(["number"]),
            amount: 0,
            refType: 0,
          });
          setPartyRef((prevDataArray) => {
            const newArray = [...prevDataArray];
            newArray[index] = [];
            return newArray;
          });
        }
      });
    } else {
      setPartyRef((prevDataArray) => {
        const newArray = [...prevDataArray];
        newArray[index] = [];
        return newArray;
      });
    }

    setDisabledAmountInputs((prev) => {
      const newDisabledInputs = [...prev];
      newDisabledInputs[index] = title === Party_Tax.party ? true : false;
      return newDisabledInputs;
    });
    // }
    // handleLedgerNetAmount(index);
  };

  console.log("data");

  //  -------- Edit payment Get API ---------
  React.useEffect(() => {
    if (journalId) {
      const editData = async (journalId: string) => {
        setLoading(true);
        try {
          const res = await VoucherServices.getVoucharById(
            currentClient?.id!,
            journalId,
            Voucher_Type.Journal
          );
          if (res.result) {
            // console.log("result.............................", res.result);
            setTotalPaymentIn(Math.abs(res.result?.effectiveLedger[0]?.amount));
            console.log("result", [
              ...res.result?.effectiveLedger,
              ...res.result?.partyLedger,
            ]);

            const x = [
              ...res.result?.effectiveLedger,
              ...res.result?.partyLedger,
            ];
            const partyLedger = await Promise.all(
              x.map(async (v: any, partyIndex: number) => {
                console.log("ref", v?.ref);

                // party ref set only
                if (v?.partyOrTax === Party_Tax.party) {
                  setSelectPartyRef((prevState: any[]) => {
                    const updatedSelectedOptions: any[] = [...prevState];
                    updatedSelectedOptions[partyIndex] = [
                      ...(prevState[partyIndex] || []),
                    ];
                    updatedSelectedOptions[partyIndex] =
                      v?.ref?.length > 0 &&
                      v?.ref?.map((x: any) => x.parentRef);
                    return updatedSelectedOptions;
                  });

                  setPartyRef((prevDataArray) => {
                    const newArray = [...prevDataArray];
                    newArray[partyIndex] =
                      v?.ref?.length > 0 &&
                      v?.ref.map((x: any, refIndex: Number) => {
                        return {
                          ...x,
                          maxAmount: x?.dueAmount + x?.value,
                        };
                      });
                    return newArray;
                  });

                  // party New Ref.
                  await VoucherServices.getReferencesByParty(
                    currentClient?.id!,
                    v?.id,
                    Voucher_Type.Journal
                  ).then((res) => {
                    const data = res.result?.map((x: any, i: number) => {
                      return {
                        name: res.result[i]?.refNo,
                        maxAmount: res.result[i]?.dueAmount,
                        dueAmount: res.result[i]?.dueAmount,
                        // id: res.result[i]?.id,
                        parentRef: res.result[i]?.id,
                        id: null,
                        refType: 1,
                      };
                    });

                    setPartyRef((prevDataArray) => {
                      const prevArray = prevDataArray[partyIndex];
                      // Filter out the common elements based on 'id'
                      const filteredData = data?.filter((newItem: any) => {
                        return !prevArray?.some(
                          (prevItem: any) =>
                            prevItem?.parentRef === newItem?.parentRef
                        );
                      });
                      const updatedArray = [...prevArray, ...filteredData];
                      // console.log("newArray", updatedArray);
                      const newArray = [...prevDataArray];
                      newArray[partyIndex] = updatedArray;
                      return newArray;
                    });
                  });
                } else {
                  setPartyRef((prevDataArray) => {
                    const newArray = [...prevDataArray];
                    newArray[partyIndex] = [];
                    return newArray;
                  });
                  setSelectPartyRef((prevState: any[]) => {
                    const updatedSelectedOptions: any[] = [...prevState];
                    updatedSelectedOptions[partyIndex] = [];
                    return updatedSelectedOptions;
                  });
                }

                setDisabledAmountInputs((prev) => {
                  const newDisabledInputs = [...prev];
                  newDisabledInputs[partyIndex] =
                    v.partyOrTax === Party_Tax.party ? true : false;
                  // console.log(newDisabledInputs);
                  return newDisabledInputs;
                });
                setSelectedPartyOption((prevState) => {
                  const updatedSelectedOptions = [...prevState];
                  updatedSelectedOptions[partyIndex] = v?.id;
                  return updatedSelectedOptions;
                });

                return {
                  ...v,
                  // ref:
                  // v?.ref[0]?.refType === 0
                  //   ? [...v?.ref, {}]
                  //   : [
                  //       { refType: 0, name: res?.result?.number },
                  //       ...v?.ref,
                  //       {},
                  //     ],
                  ref:
                    v?.ref?.length > 0
                      ? v?.ref[0]?.refType === 0
                        ? [
                            ...v?.ref?.map((x: any) => {
                              return {
                                ...x,
                                value: x?.value,
                                dueAmount: x?.dueAmount,
                              };
                            }),
                            {},
                          ]
                        : [
                            { refType: 0, name: res?.result?.number },
                            ...v?.ref?.map((x: any) => {
                              return {
                                ...x,
                                value: x?.value,
                                dueAmount: x?.dueAmount,
                              };
                            }),
                            {},
                          ]
                      : [],
                  debit: v?.isDebitNature ? Math.abs(v?.amount) : "0.00",
                  credit: !v?.isDebitNature ? Math.abs(v?.amount) : "0.00",
                  id: {
                    value: v?.id,
                    label: v?.ledgerName,
                    title: v?.partyOrTax,
                  },
                };
              })
            );

            console.log("partyLedger", partyLedger[0].ref);
            if (partyLedger) {
              form.setFieldsValue({
                ...res.result,
                partyLedger: [...partyLedger, { debit: 0.0, credit: 0.0 }],
                date: dayjs(res.result?.date),
              });
            }
            setDebitSubTotal(res.result?.amountSummary?.totalInvoiceAmount);
            setCreditSubTotal(res.result?.amountSummary?.totalInvoiceAmount);

            setAccountIsParty(res.result?.effectiveLedger[0]?.partyOrTax);
            setAllUpdatePartyData([...partyLedger]);

            setLoading(false);
          }
        } catch (err: any) {
          openNotification("error", err.message);
          console.error("error..", err.message);
          setLoading(false);
        }
      };

      editData(journalId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId]);

  const handleLedgerNetAmount = (index: number) => {
    // console.log(
    //   "false.............",
    //   form.getFieldValue(["partyLedger", index])?.id?.title
    // );
    let partyLedgerlist = form.getFieldValue(["partyLedger"]);
    const currentPartyLedgerIndexData = partyLedgerlist[index];

    // console.log("currentPartyLedgerIndexData", currentPartyLedgerIndexData);

    form.setFieldValue(["partyLedger", index], {
      ...currentPartyLedgerIndexData,
      amount: currentPartyLedgerIndexData?.ref.reduce(
        (acc: number, curr: any) => {
          return (acc += Utils.getNumber(curr?.value));
        },
        0
      ),
    });

    handleAllLedgerValue();

    return 0;
  };

  const handleAllLedgerValue = () => {
    let partyLedgerlist = form.getFieldValue(["partyLedger"]);
    // console.log("partyLedgerlist", partyLedgerlist);

    const x = partyLedgerlist.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.amount));
    }, 0);
    // console.log("ccccccccccccccc.c.c.c.c.", x);
    setTotalPaymentIn(x);
  };

  const onChangeSetPartyRef = async (
    partyIndex: number,
    refIndex: number,
    partyRefId: string
  ) => {
    console.log("ref.....onChangeSetPartyRef.", selectPartyRef);
    const x = partyRef[partyIndex].find((x: any) => x.parentRef === partyRefId);
    // console.log("data........", x);

    if (journalId) {
      form.setFieldValue(["partyLedger", partyIndex, "ref", refIndex], {
        ...x,
        value: x.value ?? 0.0,
        dueAmount: x?.dueAmount,
      });
    } else {
      const partyIndexAmount =
        Utils.getNumber(
          form.getFieldValue(["partyLedger", partyIndex, "debit"])
        ) > 0
          ? Utils.getNumber(
              form.getFieldValue(["partyLedger", partyIndex, "debit"])
            )
          : Utils.getNumber(
              form.getFieldValue(["partyLedger", partyIndex, "credit"])
            );
      const remainingRefAmount = Utils.getNumber(
        form
          .getFieldValue(["partyLedger", partyIndex, "ref"])
          .slice(1)
          .filter((_: any, idx: number) => idx !== refIndex)
          .reduce((acc: number, curr: any) => {
            return (acc += Utils.getNumber(curr?.value));
          }, 0)
      );
      let dueAmount;
      if (partyIndexAmount - (remainingRefAmount + x.dueAmount) < 0) {
        dueAmount = partyIndexAmount - remainingRefAmount;
      } else {
        dueAmount = x.dueAmount;
      }
      console.log(
        "partyIndexAmount",
        partyIndexAmount,
        "remainingRefAmount",
        remainingRefAmount
      );

      form.setFieldValue(["partyLedger", partyIndex, "ref", refIndex], {
        ...x,
        value: dueAmount,
      });
    }

    setSelectPartyRef((prevState: any[]) => {
      const updatedSelectedOptions: any[] = [...prevState];
      console.log("setSelectPartyRef.....", updatedSelectedOptions);
      updatedSelectedOptions[partyIndex] = [...(prevState[partyIndex] || [])];
      updatedSelectedOptions[partyIndex][refIndex] = partyRefId;
      return updatedSelectedOptions;
    });

    onChangeRefAmountSet(x.dueAmount, partyIndex, false);
  };

  const onChangeRefAmountSet = (
    value: string,
    partyIndex: number,
    isParty: boolean,
    refIndex?: number
  ) => {
    console.log("ref..........", selectPartyRef, partyIndex, refIndex);
    if (refIndex) {
      setSelectPartyRef((prevDataArray) => {
        const newArray = [...prevDataArray];
        newArray[partyIndex] = [...(prevDataArray[partyIndex] || [])];
        newArray[partyIndex].splice(refIndex, 1);
        return newArray;
      });
    }

    if (isParty) {
      disabledAmountInputs[partyIndex] &&
        form.setFieldValue(
          ["partyLedger", partyIndex, "ref", 0, "value"],
          Utils.getNumber(value) -
            Utils.getNumber(
              form
                .getFieldValue(["partyLedger", partyIndex, "ref"])
                .slice(1)
                .reduce((acc: number, curr: any) => {
                  return (acc += Utils.getNumber(curr?.value));
                }, 0)
            )
        );
    } else {
      disabledAmountInputs[partyIndex] &&
        form.setFieldValue(
          ["partyLedger", partyIndex, "ref", 0, "value"],
          Utils.getNumber(
            Utils.getNumber(
              form.getFieldValue(["partyLedger", partyIndex, "debit"])
            ) > 0
              ? form.getFieldValue(["partyLedger", partyIndex, "debit"])
              : form.getFieldValue(["partyLedger", partyIndex, "credit"])
          ) -
            Utils.getNumber(
              form
                .getFieldValue(["partyLedger", partyIndex, "ref"])
                .slice(1)
                .reduce((acc: number, curr: any) => {
                  return (acc += Utils.getNumber(curr?.value));
                }, 0)
            )
        );
    }
  };

  const handleTotalChange = (itemList: any) => {
    // Calculate the total credit and update state
    const TotalCredit = itemList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.credit));
    }, 0);
    setCreditSubTotal(TotalCredit);

    // Calculate the total credit and update state
    const TotalDebit = itemList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.debit));
    }, 0);
    setDebitSubTotal(TotalDebit);
  };

  const onCreditBlur = (val: string, index: number) => {
    const itemList = form.getFieldValue(["partyLedger"]);
    const updatedItems = [...itemList];
    console.log("val", val);
    if (Utils.getNumber(val) !== 0) {
      updatedItems[index].debit = Utils.getFormattedNumber(0);
      updatedItems[index].credit = Utils.getFormattedNumber(
        Utils.getNumber(val)
      );
    } else
      updatedItems[index].credit = Utils.getFormattedNumber(
        Utils.getNumber(val)
      );
    form.setFieldValue("partyLedger", updatedItems);
    handleTotalChange(updatedItems);
  };

  const onDebitBlur = (val: string, index: number) => {
    const itemList = form.getFieldValue(["partyLedger"]);
    const updatedItems = [...itemList];
    console.log("val", val);
    if (Utils.getNumber(val) !== 0) {
      updatedItems[index].credit = Utils.getFormattedNumber(0);
      updatedItems[index].debit = Utils.getFormattedNumber(
        Utils.getNumber(val)
      );
    } else
      updatedItems[index].debit = Utils.getFormattedNumber(
        Utils.getNumber(val)
      );
    form.setFieldValue("partyLedger", updatedItems);
    handleTotalChange(updatedItems);
  };

  const debitRefs: any = useRef([]);
  const creditRefs: any = useRef([]);

  const updateTotals = () => {
    const currentFields = form.getFieldValue("partyLedger") || [];
    const nonNullFields = currentFields.filter(
      (item: any) => item !== null && item !== undefined
    );

    const totalDebit = nonNullFields.reduce(
      (sum: number, item: any) => sum + Utils.getNumber(item.debit),
      0
    );

    const totalCredit = nonNullFields.reduce(
      (sum: number, item: any) => sum + Utils.getNumber(item.credit),
      0
    );

    const remainingDebit = totalCredit - totalDebit;
    const remainingCredit = totalDebit - totalCredit;
    const lastIndex = currentFields.length - 2;

    const updatedFields = [...currentFields];

    if (updatedFields.length > 2) {
      if (remainingDebit > 0) {
        debitRefs.current[lastIndex].select();
        updatedFields[lastIndex] = {
          ...updatedFields[lastIndex],
          debit: remainingDebit,
          credit: "0.00",
        };
      } else if (remainingCredit > 0) {
        creditRefs.current[lastIndex].select();
        updatedFields[lastIndex] = {
          ...updatedFields[lastIndex],
          credit: remainingCredit,
          debit: "0.00",
        };
      } else {
        debitRefs.current[lastIndex].select();
        updatedFields[lastIndex] = {
          ...updatedFields[lastIndex],
          debit: "0.00",
          credit: "0.00",
        };
      }
      form.setFieldsValue({ partyLedger: updatedFields });
      handleTotalChange(updatedFields);
    } else {
      updatedFields[lastIndex] = {
        ...updatedFields[lastIndex],
        debit: "0.00",
        credit: "0.00",
      };
      form.setFieldsValue({ partyLedger: updatedFields });
      debitRefs.current[lastIndex].select();
    }
  };

  const focusOnvalue = (i: number) => {
    const currentFields = [...form.getFieldValue("partyLedger")];
    if (currentFields[i]?.debit > currentFields[i]?.credit) {
      debitRefs.current[i].select();
    } else if (currentFields[i]?.credit > currentFields[i]?.debit) {
      creditRefs.current[i].select();
    } else {
      debitRefs.current[i].select();
    }
  };
  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  useEffect(() => {
    try {
      if (addLedgerResponse) {
        const exists = partyLedgerOption.some(
          (option: any) => option.id === addLedgerResponse.id
        );
        if (exists) {
          let partyLedger = [...(form.getFieldValue("partyLedger") || [])];
          partyLedger.pop();
          partyLedger.push({
            credit: 0.0,
            debit: 0.0,
            amount: 0,
            id: {
              label: addLedgerResponse?.ledgerName,
              value: addLedgerResponse.id,
              key: addLedgerResponse.id,
            },
          });
          partyLedger.push({
            credit: "0.00",
            debit: "0.00",
            amount: "0.00",
            id: {},
          });
          const index = partyLedger.findIndex(
            (ledgerArray) => ledgerArray?.id?.value === addLedgerResponse.id
          );
          form.setFieldValue("partyLedger", partyLedger);
          updateTotals();
          partySelect(
            addLedgerResponse.id,
            index,
            addLedgerResponse.ledgerName
          );
          onClickSelectPartyLedger(addLedgerResponse.id, index);
          setAddLedgerResponse(null);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyLedgerOption]);

  return (
    <>
      <Spin indicator={antIcon} spinning={loading}>
        <Form
          form={form}
          name="paymentIn"
          autoComplete="off"
          initialValues={{
            partyLedger: [{ amount: "0.00", credit: "0.00", debit: "0.00" }],
            date: dayjs(),
          }}
        >
          <Row justify="space-between">
            <Col span={11} className="dashedBoxStyle">
              <Row gutter={[14, 10]}>
                <Col span={7}>
                  <span className="themeColor fw-500">Date</span>
                  <Form.Item
                    name="date"
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: "Select Date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      onChange={(val) => {
                        setDateFiled(dayjs(val).toISOString());
                      }}
                      // disabledDate={(current) =>
                      //   current && current > dayjs().endOf("day")
                      // }
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <span className="themeColor fw-500">Journal No.</span>
                  <Form.Item
                    name="number"
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: "Please enter No.",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <div className="fs-35 fw-400 themeColor textEnd">Journal</div>
            </Col>
          </Row>
          <div style={{ overflowY: "auto" }}>
            <table
              className="AddPageTabelDesign dashedBorderTop"
              style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "5%" }} className="textCenter">
                    S No.
                  </th>

                  <th
                    style={{
                      width: "55%",
                      borderLeft: "1px solid rgb(240, 245, 255)",
                    }}
                    className="textStart ps-6"
                  >
                    Name
                  </th>

                  <th style={{ width: "20%" }} className="textStart ps-6">
                    Debit
                  </th>

                  <th style={{ width: "20%" }} className="textStart ps-6">
                    Credit
                  </th>
                </tr>
              </thead>

              <tbody>
                <Form.List name="partyLedger">
                  {(fields, { add, remove, move }) => {
                    return (
                      <>
                        {confirmation && (
                          <ConfirmationModal
                            open={confirmation}
                            // "${form.getFieldValue(["items"])}"
                            text={`Do you really want to delete this item ?`}
                            onNo={() => setConfirmation(false)}
                            onYes={() => {
                              onDebitBlur("0.0", deleteItemList.index);
                              onCreditBlur("0.0", deleteItemList.index);
                              remove(deleteItemList.name);
                              handleAllLedgerValue();
                              setItemLength(fields.length - 2);
                              setConfirmation(false);
                              setPartyRef((prev) => {
                                const allPrevArray = [...prev];
                                // console.log(
                                //   "allPrevArray----------",
                                //   allPrevArray,
                                //   deleteItemList.index
                                // );
                                allPrevArray.splice(deleteItemList.index, 1);
                                return allPrevArray;
                              });

                              setSelectPartyRef((prev: any[]) => {
                                const allPrevReference = [...prev];
                                allPrevReference.splice(
                                  deleteItemList.index,
                                  1
                                );
                                return allPrevReference;
                              });

                              setDisabledAmountInputs((prev) => {
                                const newDisabledInputs = [...prev];
                                // console.log(
                                //   "newDisabledInputs",
                                //   newDisabledInputs
                                // );
                                newDisabledInputs.splice(
                                  deleteItemList.index,
                                  1
                                );
                                return newDisabledInputs;
                              });

                              setSelectedPartyOption((prev) => {
                                const newDisabledInputs = [...prev];
                                newDisabledInputs.splice(
                                  deleteItemList.index,
                                  1
                                );
                                return newDisabledInputs;
                              });
                            }}
                          />
                        )}
                        {fields.map(({ key, name, ...restField }, index1) => {
                          const isInputDisabled = disabledAmountInputs[index1];
                          return (
                            <>
                              <tr key={key}>
                                <td>
                                  {fields.length !== index1 + 1 && (
                                    <div className="ps-10">{index1 + 1}.</div>
                                  )}
                                </td>

                                <td>
                                  <Form.Item
                                    name={[name, "id"]}
                                    className="m-0"
                                    {...restField}
                                  >
                                    <Select
                                      labelInValue
                                      showSearch
                                      placeholder="Search to items"
                                      optionFilterProp="children"
                                      popupMatchSelectWidth={false}
                                      style={{ width: "100%" }}
                                      dropdownStyle={{ width: "250px" }}
                                      placement="bottomRight"
                                      // onSearch={(value: string) =>
                                      //   setSearchItem(value)
                                      // }

                                      options={partyLedgerOption
                                        .filter(
                                          (option) =>
                                            !selectedPartyOption.includes(
                                              option.id
                                            )
                                        )
                                        .map((option: any) => ({
                                          value: option?.id,
                                          label: option?.ledgerName.concat(
                                            option?.aliasName
                                              ? `${
                                                  " (" + option?.aliasName + ")"
                                                }`
                                              : ""
                                          ),
                                          title: option?.partyOrTax,
                                        }))}
                                      filterOption={(input, option) =>
                                        (option?.label?.toString() ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      onChange={(e: any) => {
                                        console.log(
                                          "form",
                                          form.getFieldsValue()
                                        );

                                        if (fields.length === index1 + 1) {
                                          add();
                                          updateTotals();
                                        } else {
                                          focusOnvalue(index1);
                                        }
                                        partySelect(e.value, index1, e.title);
                                        onClickSelectPartyLedger(
                                          e.value,
                                          index1
                                        );
                                      }}
                                      dropdownRender={(customerOptions) => (
                                        <>
                                          {customerOptions}
                                          <Divider
                                            style={{ margin: "5px 0px" }}
                                          />
                                          <Button
                                            style={{ width: "100%" }}
                                            icon={<PlusOutlined />}
                                            type="link"
                                            onClick={() => {
                                              setOpenModel({
                                                type: "addLedger",
                                                param: {
                                                  open: true,
                                                },
                                              });
                                              // setEditParty({ id: "" });
                                            }}
                                          >
                                            New Ledger
                                          </Button>
                                        </>
                                      )}
                                    />
                                  </Form.Item>
                                </td>

                                <td>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "debit"]}
                                    // rules={[
                                    //   ({ getFieldValue }) => ({
                                    //     validator(_, value) {
                                    //       if (
                                    //         fields.length !== index1 + 1 &&
                                    //         isInputDisabled
                                    //       ) {
                                    //         if (
                                    //           Utils.getNumber(
                                    //             getFieldValue([
                                    //               "partyLedger",
                                    //               index1,
                                    //               "amount",
                                    //             ])
                                    //           ) !==
                                    //           Utils.getNumber(
                                    //             getFieldValue([
                                    //               "partyLedger",
                                    //               index1,
                                    //               "ref",
                                    //             ]).reduce(
                                    //               (acc: number, curr: any) => {
                                    //                 return (acc +=
                                    //                   Utils.getNumber(
                                    //                     curr?.value
                                    //                   ));
                                    //               },
                                    //               0
                                    //             )
                                    //           )
                                    //         ) {
                                    //           return Promise.reject(
                                    //             new Error(
                                    //               "Reference amount do not match!"
                                    //             )
                                    //           );
                                    //         } else {
                                    //           return Promise.resolve();
                                    //         }
                                    //       } else {
                                    //         return Promise.resolve();
                                    //       }
                                    //     },
                                    //   }),
                                    // ]}
                                  >
                                    <Input
                                      // disabled={isInputDisabled}
                                      // prefix="&#8377;"
                                      defaultValue="0.00"
                                      ref={(el) =>
                                        (debitRefs.current[index1] = el)
                                      }
                                      step={0.0}
                                      onBlur={(e) => {
                                        // handleAllLedgerValue();

                                        onDebitBlur(e.target.value, index1);
                                        Number(e.target.value) > 0 &&
                                          onChangeRefAmountSet(
                                            e.target.value,
                                            index1,
                                            true
                                          );

                                        // handelAmountChange(
                                        //   Utils.getNumber(e.target.value),
                                        //   index
                                        // );
                                        // getAllocatedAmount(Utils.getNumber(e.target.value));
                                      }}
                                      onChange={(e) => {
                                        onChangeRefAmountSet(
                                          e.target.value,
                                          index1,
                                          true
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                </td>

                                <td>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "credit"]}
                                    // rules={[
                                    //   ({ getFieldValue }) => ({
                                    //     validator(_, value) {
                                    //       if (
                                    //         fields.length !== index1 + 1 &&
                                    //         isInputDisabled
                                    //       ) {
                                    //         if (
                                    //           Utils.getNumber(
                                    //             getFieldValue([
                                    //               "partyLedger",
                                    //               index1,
                                    //               "amount",
                                    //             ])
                                    //           ) !==
                                    //           Utils.getNumber(
                                    //             getFieldValue([
                                    //               "partyLedger",
                                    //               index1,
                                    //               "ref",
                                    //             ]).reduce(
                                    //               (acc: number, curr: any) => {
                                    //                 return (acc +=
                                    //                   Utils.getNumber(
                                    //                     curr?.value
                                    //                   ));
                                    //               },
                                    //               0
                                    //             )
                                    //           )
                                    //         ) {
                                    //           return Promise.reject(
                                    //             new Error(
                                    //               "Reference amount do not match!"
                                    //             )
                                    //           );
                                    //         } else {
                                    //           return Promise.resolve();
                                    //         }
                                    //       } else {
                                    //         return Promise.resolve();
                                    //       }
                                    //     },
                                    //   }),
                                    // ]}
                                  >
                                    <InputNumber
                                      defaultValue="0.00"
                                      // disabled={isInputDisabled}
                                      // prefix="&#8377;"
                                      ref={(el) =>
                                        (creditRefs.current[index1] = el)
                                      }
                                      step={0.01}
                                      style={{ width: "100%" }}
                                      controls={false}
                                      onBlur={(e) => {
                                        // handleAllLedgerValue();
                                        onCreditBlur(e.target.value, index1);
                                        // handelAmountChange(
                                        //   Utils.getNumber(e.target.value),
                                        //   index
                                        // );
                                        // getAllocatedAmount(Utils.getNumber(e.target.value));
                                        Number(e.target.value) > 0 &&
                                          onChangeRefAmountSet(
                                            e.target.value,
                                            index1,
                                            true
                                          );
                                      }}
                                      onChange={(e: any) => {
                                        onChangeRefAmountSet(e, index1, true);
                                      }}
                                    />
                                  </Form.Item>
                                </td>

                                <td style={{ width: 25 }}>
                                  {fields.length !== index1 + 1 && (
                                    <img
                                      className="cursor mx-1"
                                      onClick={() => {
                                        setDeleteItemList({
                                          name: name,
                                          index: index1,
                                        });
                                        setConfirmation(true);
                                      }}
                                      src={deleteImg}
                                      alt="Delete"
                                    />
                                  )}
                                </td>
                              </tr>

                              {/* Nest Form.List */}
                              <tr
                                style={{
                                  display: !isInputDisabled
                                    ? "none"
                                    : "contents",
                                }}
                              >
                                <td colSpan={2}>
                                  <table style={{ width: "100%" }}>
                                    <tbody>
                                      <Form.Item className="m-0" name="ref">
                                        <Form.List name={[name, "ref"]}>
                                          {(
                                            subFields,
                                            { add, remove, move }
                                          ) => {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                {subFields.map(
                                                  (subField, index) => {
                                                    const isInputDisabled1 =
                                                      subFields.length !==
                                                      index + 1
                                                        ? true
                                                        : false;
                                                    return (
                                                      <tr
                                                        style={{
                                                          paddingLeft: "105px",
                                                        }}
                                                        key={index}
                                                      >
                                                        <td>
                                                          {index === 0 ? (
                                                            <Tooltip
                                                              title="New Reference"
                                                              placement="left"
                                                              color="#389e0d"
                                                            >
                                                              <Avatar
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  verticalAlign:
                                                                    "middle",
                                                                  color:
                                                                    "#389e0d",
                                                                  backgroundColor:
                                                                    "#f6ffed",
                                                                  borderColor:
                                                                    "#b7eb8f",
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                                size="small"
                                                              >
                                                                N
                                                              </Avatar>
                                                            </Tooltip>
                                                          ) : (
                                                            <Tooltip
                                                              title="Old Reference"
                                                              placement="left"
                                                              color="#08979C"
                                                            >
                                                              <Avatar
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  verticalAlign:
                                                                    "middle",
                                                                  color:
                                                                    "#08979C",
                                                                  backgroundColor:
                                                                    "#E6FFFB",
                                                                  borderColor:
                                                                    "rgb(127 231 235)",
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                                size="small"
                                                              >
                                                                A
                                                              </Avatar>
                                                            </Tooltip>
                                                          )}
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "30%",
                                                          }}
                                                        >
                                                          <Form.Item
                                                            className="m-0"
                                                            name={[
                                                              subField.name,
                                                              "name",
                                                            ]}
                                                            rules={[
                                                              {
                                                                required:
                                                                  index !== 0 &&
                                                                  subFields.length !==
                                                                    index + 1 &&
                                                                  form.getFieldValue(
                                                                    [
                                                                      "partyLedger",
                                                                      index1,
                                                                      "ref",
                                                                      index,
                                                                      "value",
                                                                    ]
                                                                  ) > 0
                                                                    ? true
                                                                    : false,
                                                                message:
                                                                  "Select a invoice",
                                                              },
                                                            ]}
                                                          >
                                                            <Select
                                                              disabled={
                                                                index === 0 &&
                                                                true
                                                              }
                                                              labelInValue
                                                              showSearch
                                                              optionFilterProp="children"
                                                              placeholder="Select Account"
                                                              filterOption={(
                                                                input,
                                                                option
                                                              ) =>
                                                                (
                                                                  option?.label?.toString() ??
                                                                  ""
                                                                )
                                                                  .toLowerCase()
                                                                  .includes(
                                                                    input.toLowerCase()
                                                                  )
                                                              }
                                                              // showArrow={false}
                                                              notFoundContent={
                                                                null
                                                              }
                                                              onChange={(e) => {
                                                                if (
                                                                  subFields.length ===
                                                                  index + 1
                                                                )
                                                                  add();

                                                                onChangeSetPartyRef(
                                                                  index1,
                                                                  index,
                                                                  e?.value
                                                                );
                                                              }}
                                                              options={partyRef[
                                                                index1
                                                              ]
                                                                ?.filter(
                                                                  (
                                                                    option: any
                                                                  ) =>
                                                                    !selectPartyRef[
                                                                      index1
                                                                    ]?.includes(
                                                                      option.parentRef
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    value: any
                                                                  ) => ({
                                                                    value:
                                                                      value?.parentRef,
                                                                    label:
                                                                      value?.name,
                                                                    // title: value?.balance,
                                                                  })
                                                                )}
                                                            />
                                                          </Form.Item>
                                                        </td>
                                                        <td
                                                          style={{
                                                            width: "20%",
                                                          }}
                                                        >
                                                          <Form.Item
                                                            className="m-0"
                                                            name={[
                                                              subField.name,
                                                              "dueAmount",
                                                            ]}
                                                          >
                                                            <InputNumber
                                                              step={0.01}
                                                              disabled
                                                              controls={false}
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                              placeholder="Amount"
                                                            />
                                                          </Form.Item>
                                                        </td>

                                                        <td
                                                          style={{
                                                            width: "40%",
                                                          }}
                                                        >
                                                          <Form.Item
                                                            className="m-0"
                                                            name={[
                                                              subField.name,
                                                              "value",
                                                            ]}
                                                            rules={[
                                                              {
                                                                required:
                                                                  index !== 0 &&
                                                                  form.getFieldValue(
                                                                    [
                                                                      "partyLedger",
                                                                      index1,
                                                                      "ref",
                                                                      index,
                                                                      "name",
                                                                    ]
                                                                  )
                                                                    ? true
                                                                    : false,
                                                                message:
                                                                  "Enter a amount",
                                                              },
                                                              {
                                                                validator: (
                                                                  _,
                                                                  value
                                                                ) => {
                                                                  if (
                                                                    value < 0
                                                                  ) {
                                                                    return Promise.reject(
                                                                      "Number must be non-negative"
                                                                    );
                                                                  }
                                                                  return Promise.resolve();
                                                                },
                                                              },
                                                            ]}
                                                          >
                                                            <InputNumber
                                                              min={0}
                                                              step={0.01}
                                                              max={
                                                                index !== 0
                                                                  ? journalId
                                                                    ? Math.round(
                                                                        (form.getFieldValue(
                                                                          [
                                                                            "partyLedger",
                                                                            index1,
                                                                            "ref",
                                                                            index,
                                                                            "dueAmount",
                                                                          ]
                                                                        ) +
                                                                          form.getFieldValue(
                                                                            [
                                                                              "partyLedger",
                                                                              index1,
                                                                              "ref",
                                                                              index,
                                                                              "value",
                                                                            ]
                                                                          )) *
                                                                          100
                                                                      ) / 100
                                                                    : Math.round(
                                                                        form.getFieldValue(
                                                                          [
                                                                            "partyLedger",
                                                                            index1,
                                                                            "ref",
                                                                            index,
                                                                            "dueAmount",
                                                                          ]
                                                                        ) * 100
                                                                      ) / 100
                                                                  : Infinity
                                                              }
                                                              controls={false}
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                              placeholder="amount"
                                                              onBlur={() => {
                                                                if (
                                                                  subFields.length ===
                                                                    index + 1 &&
                                                                  form.getFieldValue(
                                                                    [
                                                                      "partyLedger",
                                                                      index1,
                                                                      "ref",
                                                                      index,
                                                                      "value",
                                                                    ]
                                                                  ) > 0
                                                                )
                                                                  add();

                                                                // console.log(
                                                                //   "onChage",
                                                                //   index,
                                                                //   form.getFieldValue(
                                                                //     [
                                                                //       "partyLedger",
                                                                //       index1,
                                                                //       "ref",
                                                                //       index,
                                                                //       "name",
                                                                //     ]
                                                                //   )
                                                                // )
                                                              }}
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                onChangeRefAmountSet(
                                                                  e,
                                                                  index1,
                                                                  false
                                                                );

                                                                if (
                                                                  Utils.getNumber(
                                                                    form.getFieldValue(
                                                                      [
                                                                        "partyLedger",
                                                                        index1,
                                                                        "amount",
                                                                      ]
                                                                    )
                                                                  ) !==
                                                                  Utils.getNumber(
                                                                    form
                                                                      .getFieldValue(
                                                                        [
                                                                          "partyLedger",
                                                                          index1,
                                                                          "ref",
                                                                        ]
                                                                      )
                                                                      .reduce(
                                                                        (
                                                                          acc: number,
                                                                          curr: any
                                                                        ) => {
                                                                          return (acc +=
                                                                            Utils.getNumber(
                                                                              curr?.value
                                                                            ));
                                                                        },
                                                                        0
                                                                      )
                                                                  )
                                                                ) {
                                                                  console.log(
                                                                    "error"
                                                                  );
                                                                  form.validateFields(
                                                                    [
                                                                      "partyLedger",
                                                                      index1,
                                                                      "amount",
                                                                    ]
                                                                  );
                                                                  return Promise.resolve();
                                                                }
                                                              }}
                                                              // onBlur={() =>
                                                              //   handleLedgerNetAmount(
                                                              //     index1
                                                              //   )subField
                                                              // }
                                                            />
                                                          </Form.Item>
                                                        </td>
                                                        <td
                                                        // style={{ width: "20%" }}
                                                        >
                                                          <td
                                                            style={{
                                                              width: 25,
                                                            }}
                                                          >
                                                            {index !== 0 &&
                                                              subFields.length !==
                                                                index + 1 && (
                                                                <CloseOutlined
                                                                  onClick={() => {
                                                                    remove(
                                                                      subField.name
                                                                    );

                                                                    onChangeRefAmountSet(
                                                                      "0",
                                                                      index1,
                                                                      false,
                                                                      index
                                                                    );
                                                                  }}
                                                                />
                                                              )}
                                                          </td>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            );
                                          }}
                                        </Form.List>
                                      </Form.Item>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    );
                  }}
                </Form.List>
              </tbody>
              {/* {itemLength > 0 && (
                <tfoot>
                  <tr>
                    <th colSpan={5} className="textEnd pe-14">
                      Total
                    </th>
                    <th colSpan={2} className="textEnd pe-14">
                      {footerTotal.qty}
                    </th>
                    <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.unitPrice)}
                    </th>
                    <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                    </th>
                    {isGSTEnabled && (
                      <th colSpan={2} className="textEnd pe-12">
                        ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                      </th>
                    )}
                    <th className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.amount)}
                    </th>
                  </tr>
                </tfoot>
              )} */}
            </table>
          </div>

          <Row className="pt-10">
            <Col lg={12} style={{ paddingTop: "15px" }}>
              <Form.Item name="narration" className="m-0">
                <TextArea
                  placeholder="Write Narration"
                  style={{ width: "450px" }}
                />
              </Form.Item>
            </Col>

            <Col lg={6} md={24} offset={5}>
              <Row>
                <Col lg={8} md={8}>
                  Total Amount
                </Col>
                <Col lg={8} md={8} className="textEnd">
                  <span
                    style={{
                      color: "rgb(11, 48, 142)",
                      fontWeight: "400",
                      fontSize: "18px",
                    }}
                  >
                    &#8377;
                    {Utils.getFormattedNumber(debitSubTotal)}
                  </span>
                </Col>
                <Col lg={8} md={8} className="textEnd">
                  <span
                    style={{
                      color: "rgb(11, 48, 142)",
                      fontWeight: "400",
                      fontSize: "18px",
                    }}
                  >
                    &#8377;
                    {Utils.getFormattedNumber(creditSubTotal)}
                  </span>
                </Col>
              </Row>

              <Divider
                style={{
                  borderBottom: "2px solid rgb(203, 203, 203)",
                }}
                className="my-5"
              />

              <Row justify="end" className="mt-10">
                <Col>
                  <Button
                    onClick={() =>
                      // navigate(
                      //   `/accounts/clients/${currentClient?.id}/journals`
                      // )
                      navigate(-1)
                    }
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className="ps-14">
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => save()}
                      loading={saveButtonLoading}
                      disabled={
                        debitSubTotal === creditSubTotal &&
                        debitSubTotal !== 0 &&
                        debitSubTotal !== 0
                          ? false
                          : true
                      }
                    >
                      {journalId ? "Update" : "Save"}
                    </Button>
                  </Form.Item>
                </Col>

                {/* <Col className="ps-14">
                  <Form.Item>
                    <Button
                      // loading={saveNewLoading}
                      // onClick={() => {
                      //   addDeliveryChallan(true);
                      // }}
                      type="primary"
                    >
                      {journalId ? "Update & New" : "Save & New"}
                    </Button>
                  </Form.Item>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>

      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setTrigger(!trigger)}
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )}
    </>
  );
};

export default AddJournalNew;
