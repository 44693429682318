import React, { useContext, useEffect, useState } from "react";
import "./Assets.css";
import {
  DeleteTwoTone,
  EditOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Row,
  Button,
  Input,
  Table,
  Tooltip,
  Col,
  Card,
  Select,
  Space,
  Skeleton,
} from "antd";
import AssetModel from "./AssetModel";
import { ColumnsType } from "antd/es/table";
import FixedAssetsService, {
  AssetStatus,
} from "../../Services/FixedAssetsServiceNew";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import search from "../../Images/search.svg";
import { Utils } from "../../../Utilities/Utils";
import ViewAssetModel from "./ViewAssetModel";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import AssetJournalModal from "./AssetJournalModal";
import { dayjs } from "../../../Utilities/dayjs";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";

interface IDataType {
  key: string;
  id: string;
  sno: number;
  number: {
    no: string;
    id: string;
  };
  name: string;
  date: string;
  price: string;
  isDepreciable: string;
  status: AssetStatus;
  // journals: any[];
  // jrNumber: any[];
}
const FixedAssets = () => {
  const { openNotification } = useContext(notificationContext);
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const [successRollBack, setSeccesRollBack] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [fixedAssetList, setFixedAssetList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [editAsset, setEditAsset] = React.useState<any>(false);
  const [searchAsset, setSearchAsset] = React.useState("");
  const [isViewModelOpen, setIsViewModelOpen] = useState<boolean>(false);
  const [assetId, setAssetId] = React.useState<string>();
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [type, setType] = React?.useState<boolean>(Boolean);
  const [changeStatus, setChangeStatus] = React.useState<number>(
    AssetStatus.Active
  );
  const [deleteOrRevive, setDeleteOrRevive] = React.useState<any>({
    id: "",
    isRev: null,
  });
  const [journalModel, setJournalModel] = React.useState<{
    open: boolean;
    data: any;
  }>({ open: false, data: {} });
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);

  const showModal = () => {
    setEditAsset({ id: null });
    setIsModalOpen(true);
  };

  const modelCancel = () => {
    setIsModalOpen(false);
    setIsConfirmation(false);
    // form.resetFields();
  };



  // if (changeStatus === AssetStatus.Deleted) {
  //   columns.push({
  //     title: " ",
  //     dataIndex: "icon",
  //     width: "10%",
  //     align: "left",
  //     render: (_, record) => (
  //       <Skeleton
  //         active
  //         loading={loading}
  //         paragraph={{ rows: 1 }}
  //         title={false}
  //         className="cursor"
  //       >
  //         <Tooltip title="Revive Asset">
  //           <SyncOutlined
  //             onClick={() => {
  //               setConfirmation(true);
  //               setAssetId(record?.id);
  //               //console.log(record?.id, "assetidcheck");
  //             }}
  //           />
  //         </Tooltip>
  //       </Skeleton>
  //     ),
  //   });
  // } else {
  //   columns.push({
  //     title: " ",
  //     dataIndex: "icon",
  //     render: (_, record) => (
  //       <>
  //         {record?.journals?.length > 0 ? (
  //           <div className="cursor">
  //             <Tooltip title="Rollback">
  //               <SyncOutlined
  //                 onClick={() => {
  //                   setJournalModel({ open: true, data: record });
  //                 }}
  //               />
  //             </Tooltip>
  //           </div>
  //         ) : (
  //           <Space size={30}>
  //             <Tooltip
  //               title="Edit"
  //               placement="top"
  //               arrow={true}
  //               color="rgb(61, 73, 152)"
  //             >
  //               <EditOutlined
  //                 style={{ cursor: "pointer" }}
  //                 onClick={() => {
  //                   setIsModalOpen(true);
  //                   setEditAsset(record);
  //                 }}
  //               />
  //             </Tooltip>
  //             <Tooltip title="Delete" placement="top" arrow={true} color="red">
  //               <DeleteTwoTone
  //                 style={{ cursor: "pointer" }}
  //                 twoToneColor="#ff879d"
  //                 className="ca-delete-btn"
  //                 onClick={() => {
  //                   setAssetId(record?.id);
  //                   setConfirmation(true);
  //                 }}
  //               />
  //             </Tooltip>
  //           </Space>
  //         )}
  //       </>
  //     ),
  //   });
  // }

  const getList = async (
    clientId: string,
    start: number,
    length: number,
    sortCol: any,
    sortDir: any,
    search: string,
    status: AssetStatus
  ) => {
    await FixedAssetsService.getFixedAssetsList(
      clientId,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status
    )
      .then((res) => {
        if (res != null || res?.items?.length > 0) {
          setFixedAssetList(
            res?.items?.map((e: any, index: number) => ({
              key: index,
              sno: index + 1,
              id: e?.id,
              // number: { no: e?.number, id: e?.id },
              number: e?.number,
              name: e?.name,
              date: e?.invoiceDetail.date,
              status: e?.status,
              price: Utils.getFormattedNumber(e?.invoiceDetail.price),
              isDepreciable: e?.isDepreciable === true ? "Yes" : "No",
              //journals: e?.journals,
              //jrNumber: e?.jrNumber,
            }))
          );
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setFixedAssetList([]);
          setTotalRecords(res?.totalRecords);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    setLoading(false);
  };

  //for getlist
  useEffect(() => {
    setLoading(true);

    getList(
      currentClient?.id!,
      (page - 1) * pageSize,
      pageSize,
      undefined,
      undefined,
      searchAsset,
      changeStatus
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    trigger,
    page,
    pageSize,
    searchAsset,
    changeStatus,
    refreshAtDelete,
    successRollBack,
  ]);

  // //--------- Delete or revive API --------//
  // React.useEffect(() => {
  //   if (deleteOrRevive.id) {
  //     setLoading(true);
  //     FixedAssetsService.deleteAsset(
  //       currentClient?.id!,
  //       deleteOrRevive.id,
  //       deleteOrRevive.isRev
  //     )
  //       .then((res: any) => {
  //         if (res?.result) {
  //           setRefreshAtDelete(!refreshAtDelete);
  //           openNotification(
  //             "success",
  //             `Asset ${
  //               changeStatus === 2 ? "Revived" : "Deleted"
  //             } successfully `
  //           );
  //         }
  //         setLoading(false);
  //       })
  //       .catch((err: any) => {
  //         setLoading(false);
  //         openNotification("error", err);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deleteOrRevive]);

  // ----- Short-Cut for Open Model -----F
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    setIsModalOpen(true);
  });



  const columns: ColumnsType<IDataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Asset Reference",
      dataIndex: "number",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span

            style={{ cursor: "pointer", color: "#1677FF" }}
            onClick={() => {
              setIsViewModelOpen(true);
              setAssetId(record.id);
              setType(false)
            }}
          >
            {record?.number}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Asset Name",
      dataIndex: "name",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    },
    {
      title: "Purchase Date",
      dataIndex: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {dayjs(record?.date).format("DD/MM/YYYY")}
        </Skeleton>
      ),
    },
    {
      title: "Purchase Price",
      dataIndex: "price",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.price}
        </Skeleton>
      ),
    },
    {
      title: "Depreciable Asset",
      dataIndex: "isDepreciable",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.isDepreciable}
        </Skeleton>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <>
            {record.status !== AssetStatus.Disposed ? (
              <Tooltip title="Edit" color="#444653" placement="top">
                <EditOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setEditAsset(record);
                  }}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </>
        </Skeleton>
      ),
    },
  ];

  return (
    <>
      <Card
        size="small"
        className="TableCard"
        style={{
          // width: "97%",
          // marginLeft: "16px",
          marginTop: "6px",
          padding: "48px 0px 0px 0px",
          boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
      >
        <Row
          justify={"space-between"}
          style={{
            // paddingTop: props?.partyId ? "10px" : "0px"
            // marginTop: 10,
            padding: "0px 0px 3px 0px",
          }}
        >
          <Col>
            <Row>
              <Col>
                <Button type="primary" onClick={showModal}>
                  <PlusOutlined />
                  Fixed Assets
                </Button>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col>
                <Input
                  style={{ width: "200px" }}
                  placeholder="Search Asset"
                  allowClear
                  onChange={(e: any) => {
                    setPage(1);
                    setSearchAsset(e.target.value);
                    //console.log(e.target.value, "search");
                  }}
                  suffix={
                    loading || searchAsset ? (
                      ""
                    ) : (
                      <img alt="search" src={search} />
                    )
                  }
                />
              </Col>
              <Space size={5}>
                <span style={{ marginLeft: "10px" }}>Filter</span>
                <Col style={{ justifyContent: "end" }}>
                  <Select
                    defaultValue={AssetStatus.Active}
                    style={{ width: 120 }}
                    options={[
                      { value: AssetStatus.All, label: "ALL" },
                      { value: AssetStatus.Active, label: "Active" },
                      { value: AssetStatus.Disposed, label: "Disposed" },
                    ]}
                    onChange={(e: any) => {
                      setChangeStatus(e);
                      console.log(e, "changestatusassets");
                    }}
                  />
                </Col>
              </Space>
            </Row>
          </Col>
        </Row>
        <Table
          className={`Tabel-style table-${themeData?.componentSize ?? "middle"
            }`}
          columns={columns}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as IDataType[])
              : fixedAssetList
          }
          pagination={{
            total: totalRecords,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["10", "15", "25", "50", "100"],
          }}
          scroll={fixedAssetList.length > 15 ? { x: 700, y: 620 } : { x: 600 }}
        />
        {confirmation && (
          <ConfirmationModal
            open={confirmation}
            onNo={() => setConfirmation(false)}
            onYes={() => {
              setDeleteOrRevive({
                id: assetId,
                isRev: changeStatus === AssetStatus.Disposed ? true : false,
              });
              //console.log("yogesh");
              setConfirmation(false);
            }}
            text={
              changeStatus === AssetStatus.Disposed
                ? `Are you sure you want to revive ?`
                : `Are you sure you want to Delete this row ?`
            }
          />
        )}
        {isModalOpen && (
          <AssetModel
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isConfirmation={isConfirmation}
            setIsConfirmation={setIsConfirmation}
            modelCancel={modelCancel}
            setTrigger={setTrigger}
            editAsset={editAsset}
          />
        )}
        {/* <DisposeAssetmodel
        isDisposeOpen={isDisposeOpen}
        setIsDisposeOpen={setIsDisposeOpen}
        disposeRecord={disposeRecord}
      /> */}
        {isViewModelOpen && (
          <ViewAssetModel
            isViewModelOpen={isViewModelOpen}
            onCancel={() => setIsViewModelOpen(false)}
            assetId={assetId}
            type={type}
          />
        )}
      </Card>
      {journalModel.open && (
        <AssetJournalModal
          modal={journalModel}
          setSuccess={setSeccesRollBack}
          success={successRollBack}
          setModal={setJournalModel}
        />
      )}
    </>
  );
};
export default FixedAssets;
