import {
  Badge,
  Card,
  Col,
  DatePicker,
  Progress,
  Row,
  Segmented,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import Meta from "antd/es/card/Meta";
import { IncomeExpensesGraphs, SalesBarChart } from "./DashboardGraphs";
import { AiFillBank } from "react-icons/ai";
import "../../Common/Dashboardlayout.css";
import { BsCurrencyRupee } from "react-icons/bs";
import { useEffect, useState } from "react";
import DashboardService from "../../Services/DashboardService";
import { useClient } from "../../Context/ClientContext";
import { Utils } from "../../../Utilities/Utils";
import { percentToRs } from "../../Common/CAPComponents";
import { dayjs } from "../../../Utilities/dayjs";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const MainDashboard: React.FC = () => {
  const { RangePicker } = DatePicker;
  const { currentClient, companySettings } = useClient();
  const [data, setData] = useState<any>({});
  const [cashOrAccrual, setCashOrAccrual] = useState<any>("Accrual");
  const [hideDasBackground, sethideDasBackground] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filterDate, setFilterDate] = useState<any>({
    fromDate: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    toDate: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  console.log(width, "this is sjfjasdkfjaskdjfwitdh");
  useEffect(() => {
    // DashboardService.getDashboardData(
    //   currentClient?.id!,
    //   filterDate.fromDate,
    //   filterDate.toDate
    // ).then((res) => {
    //   setData(res?.result);
    // });
  }, [currentClient?.id, filterDate]);
  console.log("dataaaaaaa", data);

  const RoundPercent = (due: any, total: any) => {
    const per = percentToRs(due, total);
    const math = Math.round(per * 100) / 100;
    return math;
  };

  const handleChange = (checked: boolean) => {
    sethideDasBackground(checked);
  };

  console.log("check", hideDasBackground);

  React?.useEffect(() => {
    if (currentClient?.id) {
      getList(currentClient.id, filterDate.fromDate, filterDate.toDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, filterDate.fromDate, filterDate.toDate]);

  const getList = async (
    clientId: string,
    fromDate: string = "",
    toDate: string = ""
  ) => {
    setLoading(true);
    await DashboardService?.getDashboardData(clientId, fromDate, toDate).then(
      (res: any) => {
        if (res) {
          setData(res?.result);
          setLoading(false);
        }
      }
    );
  };

  return (
    <div
      style={{
        height: `calc(100vh - 70px)`,
        backgroundColor: "white",
        // margin: "-11px -20px 0px -11px",
        padding: "12px",
        overflowY: "auto",
      }}
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
        style={{
          justifyContent: "center",
          marginTop: "180px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "15px",
            backgroundColor: "rgb(247, 248, 250)",
            borderRadius: "15px",
            marginTop: "5px",
          }}
        >
          <Row justify={"space-between"}>
            <Col style={hideDasBackground ? { filter: "blur(5px)" } : {}}>
              <Typography.Title
                className="customtext"
                level={3}
                style={{
                  color: "rgb(11, 48, 142)",
                  // fontFamily: "REM,sans-serif",
                  fontWeight: 400,
                  padding: "2px 0px 0px 22px",
                }}
              >
                Dashboard
              </Typography.Title>
            </Col>

            <Col
              style={{
                marginRight: "23px",
              }}
            >
              <span style={{ paddingRight: "10px" }}>Privacy</span>
              <Switch
                style={{ margin: "5px 20px 5px 5px" }}
                checked={hideDasBackground}
                onChange={handleChange}
              />
              <RangePicker
                allowClear={false}
                defaultValue={[
                  dayjs(
                    dayjs(companySettings?.period?.from, {
                      format: "YYYY-MM-DDTHH:mm:ssZ",
                    }).format("DD/MM/YYYY"),
                    "DD/MM/YYYY"
                  ),
                  dayjs(
                    dayjs(companySettings?.period?.to, {
                      format: "YYYY-MM-DDTHH:mm:ssZ",
                    }).format("DD/MM/YYYY"),
                    "DD/MM/YYYY"
                  ),
                ]}
                format="DD/MM/YYYY"
                style={{
                  width: "220px",
                  border: "1px solid #f1f3ff",
                  filter: hideDasBackground ? "blur(5px)" : "none",
                }}
                onChange={(e: any) => {
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        fromDate: dayjs(e[0]).format("DD/MM/YYYY"),
                        toDate: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
          </Row>

          <Row
            gutter={[width <= 1024 ? 35 : 43, width <= 1024 ? 35 : 35]}
            style={{
              padding: "22px",
              filter: hideDasBackground ? "blur(5px)" : "none",
            }}
          >
            <Col lg={12} xs={24} md={12} xl={6}>
              <Card
                className="set-pt"
                actions={[
                  <>
                    <p style={{ color: "#52c41a" }}>Due</p>
                    <p>{Utils.getFormattedNumber(data?.receivables?.due)}</p>
                  </>,
                  <>
                    <p style={{ color: "#1677ff" }}>Overdue </p>
                    <p>
                      {Utils.getFormattedNumber(data?.receivables?.overdue)}
                    </p>
                  </>,
                ]}
              >
                <Meta
                  title={
                    <Typography.Title level={5} style={{ fontWeight: 500 }}>
                      Total Receivables
                    </Typography.Title>
                  }
                  description={`Total Unpaid Invoices ${Utils.getFormattedNumber(
                    data?.receivables?.totalDue
                  )}`}
                />

                <Tooltip
                  title={`${
                    isNaN(
                      (data?.receivables?.due * 100) /
                        data?.receivables?.totalDue
                    )
                      ? 0
                      : RoundPercent(
                          data?.receivables?.due,
                          data?.receivables?.totalDue
                        )
                  } % due  /  ${
                    isNaN(
                      (data?.receivables?.overdue * 100) /
                        data?.receivables?.totalDue
                    )
                      ? 0
                      : RoundPercent(
                          data?.receivables?.overdue,
                          data?.receivables?.totalDue
                        )
                  } % Overdue`}
                >
                  <Progress
                    showInfo={false}
                    percent={100}
                    success={{
                      percent: RoundPercent(
                        data?.receivables?.due,
                        data?.receivables?.totalDue
                      ),
                    }}
                  />
                </Tooltip>
              </Card>
            </Col>
            <Col lg={12} xs={24} md={12} xl={6}>
              <Card
                className="set-pt"
                actions={[
                  <>
                    <p style={{ color: "#52c41a" }}>Due</p>
                    <p>{Utils.getFormattedNumber(data?.payables?.due)}</p>
                  </>,
                  <>
                    <p style={{ color: "#1677ff" }}>Overdue </p>
                    <p>{Utils.getFormattedNumber(data?.payables?.overdue)}</p>
                  </>,
                ]}
              >
                <Meta
                  title={
                    <Typography.Title
                      level={5}
                      style={{
                        // fontFamily: "REM,sans-serif",
                        fontWeight: 500,

                        // color: "rgb(68, 84, 111)",
                      }}
                    >
                      Total Payables
                    </Typography.Title>
                  }
                  description={`Total Unpaid Bills ${Utils.getFormattedNumber(
                    data?.payables?.totalDue
                  )}`}
                />
                <Tooltip
                  title={`${
                    isNaN(
                      (data?.payables?.due * 100) / data?.payables?.totalDue
                    )
                      ? 0
                      : RoundPercent(
                          data?.payables?.due,
                          data?.payables?.totalDue
                        )
                  } % due  /  ${
                    isNaN(
                      (data?.payables?.overdue * 100) / data?.payables?.totalDue
                    )
                      ? 0
                      : RoundPercent(
                          data?.payables?.overdue,
                          data?.payables?.totalDue
                        )
                  } % Overdue`}
                >
                  <Progress
                    showInfo={false}
                    percent={100}
                    success={{
                      percent: RoundPercent(
                        data?.payables?.due,
                        data?.payables?.totalDue
                      ),
                    }}
                  />
                </Tooltip>
              </Card>
            </Col>

            <Col lg={12} xs={24} md={12} xl={6}>
              <Card className="cashflow set-pt">
                <Typography.Title
                  level={5}
                  style={{
                    // fontFamily: "REM,sans-serif",
                    fontWeight: 500,

                    // color: "rgb(68, 84, 111)",
                  }}
                >
                  <BsCurrencyRupee size={20} /> {data?.bankSummary?.title}
                </Typography.Title>

                <Row gutter={[0, width <= 1440 ? 41 : 41]}>
                  <Col lg={12} xl={14} md={12}>
                    <p
                      style={{
                        fontSize: "13px",
                        color: "lightslategray",
                        fontWeight: 500,
                      }}
                    >
                      Bank (
                      {dayjs(companySettings?.period?.from).format(
                        "DD/MM/YYYY"
                      )}
                      )
                      <span>
                        {Utils?.getFormattedNumber(
                          data?.bankSummary?.openingBalance
                        )}
                      </span>
                    </p>
                  </Col>
                  <Col
                    lg={12}
                    md={12}
                    xl={8}
                    xs={12}
                    style={{ fontSize: "12px", textAlign: "end" }}
                  >
                    <p
                      style={{
                        color: "green",
                        fontWeight: 500,
                        marginLeft: "-12px",
                      }}
                    >
                      Incoming
                    </p>{" "}
                    <p style={{ fontSize: "12px" }}>
                      {Utils?.getFormattedNumber(data?.bankSummary?.incoming)}
                    </p>
                  </Col>
                  <Col lg={12} md={12} xl={14} style={{ fontSize: "12px" }}>
                    <p
                      style={{
                        color: "#268ddd",
                        fontWeight: 500,
                      }}
                    >
                      Bank (
                      {dayjs(companySettings?.period?.to).format("DD/MM/YYYY")})
                    </p>

                    <p>
                      {" "}
                      {Utils?.getFormattedNumber(
                        data?.bankSummary?.closingBalance
                      )}
                    </p>
                  </Col>
                  <Col
                    lg={12}
                    xl={8}
                    md={12}
                    xs={12}
                    style={{ fontSize: "12px", textAlign: "end" }}
                  >
                    <p
                      style={{
                        color: "red",
                        marginLeft: "-12px",
                        fontWeight: 500,
                      }}
                    >
                      Outgoing
                    </p>
                    <p>
                      {Utils?.getFormattedNumber(
                        Math.abs(data?.bankSummary?.outgoing)
                      )}
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* <Col lg={12} xs={24} md={12} xl={6}>
            <Badge.Ribbon text="Reconciled" color="green">
              <Card className="set-pt">
                <Space>
                  <AiFillBank size={20} style={{ color: "rgb(68, 84, 111)" }} />
                  <Typography.Title
                    level={5}
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Bank Balance
                  </Typography.Title>
                </Space>

                <Row style={{ paddingTop: "77px" }}>
                  <Col lg={10} md={12} xl={12} xs={12}>
                    <p>&#8377; 100,000</p>
                    <p style={{ fontSize: "13px" }}>Bank Balance</p>
                  </Col>
                  <Col lg={12} md={12} xl={12} xs={12}>
                    <p>&#8377; 100,000</p>
                    <p style={{ fontSize: "13px" }}>Accounting Bal.</p>
                  </Col>
                </Row>
              </Card>
            </Badge.Ribbon>
          </Col> */}
            <Col lg={12} xs={24} md={12} xl={6}>
              <Card className="cashflow set-pt">
                <Typography.Title
                  level={5}
                  style={{
                    // fontFamily: "REM,sans-serif",
                    fontWeight: 500,

                    // color: "rgb(68, 84, 111)",
                  }}
                >
                  <BsCurrencyRupee size={20} /> {data?.cashSummary?.title}
                </Typography.Title>

                <Row gutter={[0, width <= 1440 ? 41 : 41]}>
                  <Col lg={12} xl={14} md={12}>
                    <p
                      style={{
                        fontSize: "13px",
                        color: "lightslategray",
                        fontWeight: 500,
                      }}
                    >
                      Cash(
                      {dayjs(companySettings?.period?.from).format(
                        "DD/MM/YYYY"
                      )}
                      )
                      <span>
                        {" "}
                        {Utils.getFormattedNumber(
                          data?.cashSummary?.openingBalance
                        )}
                      </span>
                    </p>
                  </Col>
                  <Col
                    lg={12}
                    md={12}
                    xl={8}
                    xs={12}
                    style={{ fontSize: "12px", textAlign: "end" }}
                  >
                    <p
                      style={{
                        color: "green",
                        fontWeight: 500,
                        marginLeft: "-12px",
                      }}
                    >
                      Incoming
                    </p>{" "}
                    <p style={{ fontSize: "12px" }}>
                      {" "}
                      {Utils.getFormattedNumber(data?.cashSummary?.incoming)}
                    </p>
                  </Col>
                  <Col lg={12} md={12} xl={14} style={{ fontSize: "12px" }}>
                    <p
                      style={{
                        color: "#268ddd",
                        fontWeight: 500,
                      }}
                    >
                      Cash(
                      {dayjs(companySettings?.period?.to).format("DD/MM/YYYY")})
                    </p>

                    <p>
                      {" "}
                      {Utils.getFormattedNumber(
                        data?.cashSummary?.closingBalance
                      )}
                    </p>
                  </Col>
                  <Col
                    lg={12}
                    xl={8}
                    md={12}
                    xs={12}
                    style={{ fontSize: "12px", textAlign: "end" }}
                  >
                    <p
                      style={{
                        color: "red",
                        marginLeft: "-12px",
                        fontWeight: 500,
                      }}
                    >
                      Outgoing
                    </p>
                    <p>
                      {" "}
                      {Utils?.getFormattedNumber(
                        Math.abs(data?.cashSummary?.outgoing)
                      )}{" "}
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row
            style={{
              padding: "22px",
              filter: hideDasBackground ? "blur(5px)" : "none",
            }}
          >
            <Col lg={24} xs={24} md={24}>
              <Card>
                <Row style={{ paddingTop: "5px" }}>
                  <Col xl={15} xs={24} lg={24} md={24}>
                    {window.innerWidth <= 465 ? (
                      <>
                        <h1>Total Sales</h1>
                        <Tooltip title="3 done / 3 in progress / 4 to do">
                          <Progress
                            size={100}
                            strokeWidth={10}
                            percent={60}
                            success={{ percent: 30 }}
                            type="circle"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <SalesBarChart data={data} />
                    )}
                  </Col>

                  {/* <Col xl={9} lg={24} xs={24} md={24}>
                  <Card
                    style={{
                      borderRadius: "15px",
                      marginTop: "12px",
                      // marginRight: "12px",
                    }}
                  >
                    <Typography.Title
                      level={4}
                      style={{
                        // fontFamily: "REM,sans-serif",
                        fontWeight: 500,
                        // color: "rgb(68, 84, 111)",
                      }}
                    >
                      Income & Expenses
                    </Typography.Title>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginTop: "30px",
                      }}
                    >
                      <Segmented
                        style={{ fontWeight: 500 }}
                        options={["Accrual", "Cash"]}
                        onChange={(val) => setCashOrAccrual(val)}
                      />
                    </div>
                    <div
                      className="incomeExpensesGraph"
                      style={{ margin: "28px 0px 26px 0px" }}
                    >
                      <IncomeExpensesGraphs
                        value={data?.incomeExpence}
                        cashOrAccrual={cashOrAccrual}
                      />
                    </div>
                  </Card>
                </Col> */}
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
};

export default MainDashboard;
