import { CloseOutlined, FormOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import React, { useContext } from "react";
import { useClient } from "../../Context/ClientContext";
import { SettingsSections } from "../../Services/SettingService";
import { notificationContext } from "../../Common/PageRoute";
import AdditionalNotes from "../../Common/AdditionalNotes";
import TextArea from "antd/es/input/TextArea";

const SettingSecCustomization = () => {
  const [form] = Form.useForm();
  const { companySettings, updateCompanySettings } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [select, setSelect] = React?.useState<{
    value: SettingsSections;
    label: string;
    key: string;
  }>({
    value: SettingsSections.Invoice,
    label: "Sales Invoice",
    key: "invoice",
  });
  const [openNotes, setOpenNotes] = React.useState<boolean>(false);
  const [note, setNote] = React.useState<string>();
  const [notesInitial, setNotesInitial] = React.useState<string>();
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [editorContent, setEditorContent] = React?.useState("");

  const handleChange = (content: any) => {
    setEditorContent(content);
  };
  // console.log(editorContent, "Contenttttt");
  const filterOption = (
    input: string,
    option?: { label: string; value: SettingsSections }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  React?.useEffect(() => {
    console.log(
      "companySettings?.setting?.invoice",
      companySettings?.setting?.invoice
    );

    form.setFieldsValue({
      invoice: companySettings?.setting?.invoice,
      crNote: companySettings?.setting?.crNote,
      receipt: companySettings?.setting?.receipt,
      estimate: companySettings?.setting?.estimate,
      sOrder: companySettings?.setting?.sOrder,
      dChallan: companySettings?.setting?.dChallan,
      pInvoice: companySettings?.setting?.pInvoice,
      drNote: companySettings?.setting?.drNote,
      payment: companySettings?.setting?.payment,
      pOrder: companySettings?.setting?.pOrder,
      item: companySettings?.setting?.item,
      expenses: companySettings?.setting?.expenses,
      income: companySettings?.setting?.income,
      asset: companySettings?.setting?.asset,
      journal: companySettings?.setting?.journal,
      dividend: companySettings?.setting?.dividend,
      contra: companySettings?.setting?.contra,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettings]);

  return (
    <>
      <div style={{ padding: "0px 0px 3px 0px", height: "80vh" }}>
        <Row>
          <Col span={18}>
            <Select
              style={{ width: "20%" }}
              labelInValue
              showSearch
              placeholder="Select..."
              optionFilterProp="children"
              filterOption={filterOption}
              defaultValue={SettingsSections.Invoice}
              options={[
                {
                  value: SettingsSections.Invoice,
                  label: "Sales Invoice",
                  key: "invoice",
                },
                {
                  value: SettingsSections.CrNote,
                  label: "Credit Note",
                  key: "crNote",
                },
                {
                  value: SettingsSections.Receipt,
                  label: "Receipt",
                  key: "receipt",
                },
                {
                  value: SettingsSections.Quotation,
                  label: "Quotation",
                  key: "estimate",
                },
                {
                  value: SettingsSections.SOrder,
                  label: "Sales Order",
                  key: "sOrder",
                },
                {
                  value: SettingsSections.DChallan,
                  label: "Delivery Challan",
                  key: "dChallan",
                },
                {
                  value: SettingsSections.PInvoice,
                  label: "Purchase Invoice",
                  key: "pInvoice",
                },
                {
                  value: SettingsSections.DrNote,
                  label: "Debit Note",
                  key: "drNote",
                },
                {
                  value: SettingsSections.Payment,
                  label: "Payment",
                  key: "payment",
                },
                {
                  value: SettingsSections.POrder,
                  label: "Purchase Order",
                  key: "pOrder",
                },
                {
                  value: SettingsSections.Item,
                  label: "Items",
                  key: "item",
                },
                {
                  value: SettingsSections.Asset,
                  label: "Fixed Assets",
                  key: "asset",
                },
                {
                  value: SettingsSections.Journal,
                  label: "Journal",
                  key: "journal",
                },
                {
                  value: SettingsSections.Contra,
                  label: "Contra",
                  key: "contra",
                },
                {
                  value: SettingsSections.Dividend,
                  label: "Dividend",
                  key: "dividend",
                },
              ]}
              onChange={(e: any) =>
                setSelect({
                  value: e.value,
                  label: e.label,
                  key: e.key,
                })
              }
            />
          </Col>

          <Col style={{ marginLeft: "15px" }}>
            {isEdit ? (
              <></>
            ) : (
              <>
                <Tooltip title="Edit">
                  <FormOutlined
                    style={{ color: "rgb(106 119 227)", paddingTop: "15px" }}
                    // className="ms-40"
                    className="ca-edit-btn"
                    type="default"
                    onClick={() => {
                      setNote(undefined);
                      setNotesInitial(companySettings?.setting?.invoice?.notes);
                      setIsEdit(true);
                    }}
                  />
                  {/* <Button
                        className="ms-40"
                        type="default"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setNote(undefined);
                          setNotesInitial(
                            companySettings?.setting?.invoice?.notes
                          );
                          setIsEdit({
                            edit: true,
                            key:
                              isEdit.key !== "salesInv"
                                ? "salesInv"
                                : undefined,
                          });
                        }}
                      /> */}
                </Tooltip>
              </>
            )}
          </Col>
        </Row>

        <>
          <div>
            {/* --------- Sales Invoice --------- */}

            <Divider className="my-7" />

            <Row>
              <Col span={4}>
                <Typography.Title level={5} style={{ color: "blue" }}>
                  {select.label}
                </Typography.Title>
              </Col>
              <Col span={20}>
                <Form
                  form={form}
                  colon={false}
                  name="CustomizeSalesInvoice"
                  disabled={!isEdit}
                  labelAlign="left"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 12 }}
                  // layout="horizontal"
                >
                  <Row>
                    <Col lg={10} sm={12}>
                      <Form.Item
                        name={[select.key, "isSuffix"]}
                        className="m-0"
                        label="Prefix / Suffix"
                      >
                        <Select
                          className="custom-select small-font-select"
                          placeholder="select"
                          options={[
                            { value: false, label: "Prefix" },
                            { value: true, label: "Suffix" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={10} sm={12}>
                      <Form.Item
                        name={[select.key, "preSuf"]}
                        className="m-0"
                        label="PreSuf Text"
                      >
                        <Input className="custom-input small-font-input" />
                      </Form.Item>
                    </Col>
                    {/* <Col lg={2}>
             
                    </Col> */}
                  </Row>

                  <Row>
                    <Col lg={10} sm={12}>
                      <Form.Item
                        name={[select.key, "start"]}
                        className="m-0 textCenter"
                        label="Inv No."
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 12 }}
                      >
                        <Input
                          className="custom-input small-font-input"
                          onKeyPress={(e) =>
                            !/[0-9]/.test(e.key) && e.preventDefault()
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={10} sm={12}>
                      <Form.Item
                        name={[select.key, "title"]}
                        className="m-0"
                        label="Title"
                      >
                        <Input className="custom-input small-font-input" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={10} sm={12}>
                      <Form.Item
                        label="Year Include"
                        valuePropName="checked"
                        name={[select.key, "yearInclude"]}
                        className="m-0"
                      >
                        <Checkbox
                          className="custom-select"
                          style={{ marginTop: "6px" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={10} sm={12}>
                      <Form.Item
                        label="Month Include"
                        valuePropName="checked"
                        name={[select.key, "monthInclude"]}
                        className="m-0"
                      >
                        <Checkbox
                          className="custom-select"
                          style={{ marginTop: "6px" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={10} sm={12}>
                      <Form.Item
                        label="Month Format"
                        name={[select.key, "monthNumFormat"]}
                        className="m-0"
                      >
                        <Select
                          className="custom-select small-font-select"
                          placeholder="Select Format"
                          options={[
                            { value: 0, label: "Number" },
                            { value: 1, label: "Text" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={10} sm={12}>
                      <Form.Item
                        label="Frequency No."
                        name={[select.key, "startNumberFrequency"]}
                        className="m-0"
                      >
                        <Select
                          className="custom-select small-font-select"
                          placeholder="No. Frequency"
                          options={[
                            { value: 0, label: "Yearly" },
                            { value: 1, label: "Monthly" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={10} sm={12}>
                      <Form.Item
                        label="Separator"
                        name={[select.key, "separator"]}
                        className="m-0"
                      >
                        <Select
                          className="custom-select small-font-select"
                          placeholder="Separator"
                          options={[
                            { value: "-", label: "-" },
                            { value: "#", label: "#" },
                            { value: "/", label: "/" },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col lg={20} sm={12}>
                      {!isEdit ? (
                        <Form.Item
                          className="m-0"
                          label="Note"
                          labelCol={{ span: 3 }}
                          wrapperCol={{ span: 18 }}
                          name={[select.key, "notes"]}
                        >
                          <div
                            style={{
                              background: "rgba(0, 0, 0, 0.04)",
                              padding: 10,
                              height: "130px",
                              overflowX: "scroll",
                              border: "1px solid #d9d9d9",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: companySettings?.setting?.invoice?.notes!,
                            }}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          className="m-0"
                          label="Note"
                          labelCol={{ span: 3 }}
                          wrapperCol={{ span: 18 }}
                          name={[select.key, "notes"]}
                        >
                          <SunEditor
                            setContents={
                              companySettings?.setting?.invoice?.notes
                            }
                            setOptions={{
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize"],
                                ["bold", "underline", "italic", "strike"],
                                ["fontColor", "hiliteColor"],
                                ["align", "list", "lineHeight"],
                                ["outdent", "indent"],
                                ["removeFormat"],
                              ],
                              defaultTag: "div",
                              showPathLabel: false,
                            }}
                            /*  ["table", "horizontalRule", "link", "image", "video"], */
                            height="150px"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row justify={"end"} style={{ marginTop: "20px" }}>
              <Col span={8} style={{ marginRight: "38px" }}>
                {isEdit ? (
                  <Space>
                    <Button
                      icon={<CloseOutlined />}
                      onClick={() => {
                        setNote(undefined);
                        setIsEdit(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      // className="ms-40"
                      icon={<SaveOutlined />}
                      loading={saveButtonLoading}
                      onClick={() => {
                        if (isEdit) {
                          setSaveButtonLoading(true);
                          form.validateFields().then((val: any) => {
                            updateCompanySettings!(
                              select.value!,
                              {
                                ...companySettings,
                                setting: {
                                  ...companySettings?.setting!,
                                  [select.key!]: val[select?.key],
                                },
                              },
                              val
                            ).then((result: any) => {
                              if (result?.status) {
                                setIsEdit(false);
                                setSaveButtonLoading(false);
                                openNotification(
                                  "success",
                                  "Setting Updated successfully"
                                );
                              } else {
                                setSaveButtonLoading(false);
                                openNotification(
                                  "error",
                                  "there is some error"
                                );
                              }
                            });
                          });
                        }
                      }}
                    >
                      Save
                    </Button>

                    <Button
                      type="primary"
                      // className="ms-40"
                      icon={<SaveOutlined />}
                      loading={saveButtonLoading}
                      onClick={() => {
                        // if (isEdit) {
                        //   setSaveButtonLoading(true);
                        //   form.validateFields().then((val: any) => {
                        //     updateCompanySettings!(
                        //       select.value!,
                        //       {
                        //         ...companySettings,
                        //         setting: {
                        //           ...companySettings?.setting!,
                        //           [select.key!]: val[select?.key],
                        //         },
                        //       },
                        //       val
                        //     ).then((result: any) => {
                        //       if (result?.status) {
                        //         setIsEdit(false);
                        //         setSaveButtonLoading(false);
                        //         openNotification(
                        //           "success",
                        //           "Setting Updated successfully"
                        //         );
                        //       } else {
                        //         setSaveButtonLoading(false);
                        //         openNotification(
                        //           "error",
                        //           "there is some error"
                        //         );
                        //       }
                        //     });
                        //   });
                        // }
                        
                      }}
                    >
                      Only Save All Number  
                    </Button>
                  </Space>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </>
      </div>

      {openNotes && (
        <AdditionalNotes
          value={note ? note : notesInitial ? notesInitial : ""}
          open={openNotes}
          save={(a: string) => {
            setOpenNotes(false);
            setNote(a);
          }}
          cancel={() => setOpenNotes(false)}
        />
      )}
    </>
  );
};

export default SettingSecCustomization;
